<template>
  <div>
    <div
      class="
        flex flex-col
        items-center
        justify-center
        w-screen
        h--[70vh]
        text-center
      "
    >
      <Vue2Image
        v-if="bgImage && bgImage.filename"
        class="object-cover w-64 h-64 mb-4"
        :src="bgImage.filename"
        alt="404 page image"
        :widths="[400, 800]"
        :width="800"
      />
      <template v-if="error.statusCode === 404">
        <Headline
          class="mb-4 text-4xl font-semibold"
          headline-type="h1"
          headline-size="display--huge"
          :headline="{ html: '404' }"
          mobile-headline-size="text-5xl"
          desktop-headline-size="text-5xl"
          :lead="{ html: $t('Page_Not_Found') }"
        />
        <mp-link :to="$u('')" class="flex justify-center">
          <Button class="w-full" color="blue-dark" button-style="solid">
            {{ $t('Global_Continue_Shopping') }}
          </Button>
        </mp-link>
      </template>
      <template v-else>
        <div class="error-page__hero__content">
          <Headline
            class="error-page__hero__headline"
            headline-type="h1"
            headline-size="display--huge"
            :headline="{ html: error.statusCode }"
            :lead="{ html: error.message }"
          />
          <mp-link :to="$u('')">
            <Button class="w-full" color="blue-dark" button-style="solid">
              {{ $t('Global_Continue_Shopping') }}
            </Button>
          </mp-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { isLanguageCode } from '@made-people/iso-639-1'

export default {
  name: 'Error',
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  head() {
    const language = this.$store.getters['frontend/currentLanguage']
    const isoLanguageCode = language?.iso639
    if (isLanguageCode(isoLanguageCode)) {
      return {
        htmlAttrs: { lang: isoLanguageCode },
      }
    } else {
      console.log(
        'Failed to set html tag lang attribute because languageCode is not valid ISO-639-1. lang code is: ',
        languageCode
      )
    }
  },
  computed: {
    bgImage: ({ $store }) =>
      $store.getters['storyblok/settings']?.pageNotFoundImage ?? {},
  },
  mounted() {
    this.$store.dispatch('ui/header/setHeaderColors', { bg: 'white' })
  },
}
</script>
