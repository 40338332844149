<template>
  <!-- lg:[&:nth-child(4n+1)]:delay-75 -->
  <!-- lg:[&:nth-child(4n+2)]:delay-150 -->
  <div
    ref="productCardRef"
    :class="[
      contextSearch ? 'context-search' : '',
      { 'product-card': true },
      { 'out-of-stock': !product.inStock },
      { loading: loading },
    ]"
    class="flex flex-col justify-between w-full group lg:[&:nth-child(4n+1)]:delay-75 lg:[&:nth-child(4n+2)]:delay-150 lg:[&:nth-child(4n+3)]:delay-300 lg:[&:nth-child(4n+4)]:delay-[450ms]"
    @mouseenter="mouseOver"
    @mouseleave="mouseOut"
  >
    <SlideInTransitionTop duration="duration-1000" delay="delay-[inherit]">
      <div v-if="animated" class="relative">
        <div
          class="relative mb-2 overflow-hidden scrollbar-hide aspect-portrait bg-lightGray"
        >
          <Vue2Image
            v-if="imageUrl"
            :src="imageUrl"
            :alt="product.name"
            :width="600"
            :widths="[320, 600]"
            height="100%"
            :quality="70"
            background="f0f0f0"
            :loading="
              position === 1 ||
              position === 2 ||
              position === 3 ||
              position === 4
                ? 'eager'
                : 'lazy'
            "
            class="object-contain transition-all duration-300 aspect-portrait bg-lightGray"
            :class="{
              'scale-105': hovering,
            }"
            @imageLoaded="imageLoaded"
          />
          <div
            v-if="!imageUrl"
            class="flex items-center justify-center w-full h-full"
          >
            <LogoIcon
              class="w-24 h-auto transition-colors lg:w-32 fill-black"
            />
          </div>

          <ProductCardBadgesContainer class="flex flex-col gap-1">
            <ProductBadge
              v-for="(badge, badgeIndex) in badges"
              :key="'badge-' + badgeIndex"
              :label="badge.label"
              :class="[badge.bg]"
              class="px-2 py-1 text-xs tracking-widest text-white w-fit"
              :style="`background-color: ${badge.bg}; color: ${badge.color}`"
            />
          </ProductCardBadgesContainer>
          <mp-link
            :to="$u(product.url)"
            class="absolute top-0 bottom-0 left-0 right-0"
            :aria-label="`View Product: ${product.name}`"
            @click.native="trackClick"
          />
          <SlideInTransitionTop duration="duration-300">
            <ProductCardQuickShop
              v-if="
                (showQuickShop || alwaysShowQuickShop || contextWishlist) &&
                product.inStock &&
                !isBundle
              "
              :class="{ 'hidden lg:flex': contextWishlist }"
              :context-wishlist="contextWishlist"
              :quick-shop-product="product"
              :product="product"
            />
          </SlideInTransitionTop>
        </div>

        <!-- Actions -->
        <div
          v-if="showActions && !isBundle"
          actions
          class="absolute z-20 top-2 right-2 lg:right-4 lg:top-4"
        >
          <WishlistIcon
            v-if="showHeartIcon"
            class="w-6 h-6 transition add-to-wishlist hover:fill-brandRed"
            :product-id="product.product"
            :show-trash-icon="contextWishlist"
          />
        </div>
        <ProductCardDetails
          class="mb-5 lg:px-0"
          :class="{
            'px-0': contextWishlist,
            'px-4': !contextWishlist,
          }"
          :product="product"
          :show-product-type="showProductType"
          :is-search-page="isSearchPage"
          :context-wishlist="contextWishlist"
          :gtm-list-name="gtmListName"
        />
        <ProductCardSizeSelector
          v-if="contextWishlist"
          :quick-shop-product="product"
        />
      </div>
    </SlideInTransitionTop>
  </div>
</template>

<script>
import Vue2Image from '@made-people/vue2-image'
import ViewportMixin from '@made-people/centra-storyblok-nuxt-shared/lib/mixins/viewport'
import ElevateMixin from '../../mixins/ElevateMixin'
import ProductCardDetails from './ProductCardDetails.vue'
import ProductCardBadgesContainer from './ProductCardBadgesContainer.vue'
import ProductCardSizeSelector from './ProductCardSizeSelector.vue'
import ProductCardQuickShop from '~/components/product-card-quick-shop/ProductCardQuickShop'
import WishlistIcon from '~/components/wishlist/WishlistIcon'
import LogoIcon from '~/static/images/logo.svg'
import SlideInTransitionTop from '~/components/transitions/SlideInTransitionTop'

export default {
  name: 'ProductCard',
  components: {
    ProductCardQuickShop,
    WishlistIcon,
    SlideInTransitionTop,
    ProductCardDetails,
    ProductCardBadgesContainer,
    Vue2Image,
    ProductCardSizeSelector,
    LogoIcon,
  },
  mixins: [ViewportMixin, ElevateMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },

    gtmListName: {
      type: String,
      default() {
        return this.$route.path.replace(/(^\/[^/]+\/?)|(\/$)/g, '')
      },
    },
    position: {
      type: Number,
      required: true,
    },
    /**
     * Show the heart icon
     */
    showHeartIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * Show the cart icon
     */
    showCartIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * Show Product Type
     */
    showProductType: {
      type: Boolean,
      default: false,
    },
    /**
     * Show the trashcan icon
     */
    showTrashIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * If the product is shown in search results
     */
    contextSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    animations: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * If the product is shown in the wishlist
     */
    contextWishlist: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Image display sizes for optimized images
     * Can be sent from a parent, ie the grid component
     */
    mobileDisplaySize: {
      type: String,
      default: '',
    },
    desktopDisplaySize: {
      type: String,
      default: '',
    },
    /**
     * Rounded Corners
     */
    mobileRoundedCorners: {
      type: Boolean,
      default: false,
    },
    desktopRoundedCorners: {
      type: Boolean,
      default: false,
    },
    displaySwatches: {
      type: Boolean,
      required: false,
      default: true,
    },
    isSearchPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    alwaysShowQuickShop: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      sizeSelectorOpen: false,
      hovering: false,
      loaded: false,
      loading: false,
      canHover: false,
      animated: !this.animations,
      observer: null,
    }
  },
  computed: {
    productCardElement: ({ $refs }) => $refs.productCardRef,
    showActions: ({ showHeartIcon, showCartIcon, showTrashIcon }) =>
      showHeartIcon || showCartIcon || showTrashIcon,
    showQuickShop: ({ showCartIcon, hovering, contextWishlist }) =>
      showCartIcon && hovering && !contextWishlist,
    imageUrl() {
      return this.product.mainImage
    },
    isBundle: ({ product }) => {
      if (Array.isArray(product.isBundle) && product.isBundle.length > 0) {
        return product.isBundle[0].id === 'true'
      }
      return false
    },
    badges() {
      const badges = []
      this.product.sticker.map((sticker) =>
        badges.push({
          label: sticker.name,
          bg: sticker.bg,
          color: sticker.textColor,
        })
      )
      if (this.product.discounted) {
        badges.push({
          bg: 'bg-brandRed',
          label: `${this.$t('Sale')} ${this.product.discountPercent}%`,
          color: '#FFFFFF',
        })
      }
      if (!this.product.inStock && !this.product.backInStockDisabled) {
        badges.push({
          bg: 'bg-darkGray',
          label: this.$t('Global_Sold_Out'),
          color: '#FFFFFF',
        })
      }
      return badges
    },
  },
  mounted() {
    const testFreaks = window.testFreaks || []
    testFreaks.push(['load', 'items'])
    const { startObserver, animations } = this
    if (animations) startObserver()
  },
  beforeDestroy() {
    const { observer, productCardElement } = this

    observer && observer.unobserve(productCardElement)
  },
  methods: {
    inViewportHandler() {
      if (!this.seenInViewport && this.testInViewport()) {
        const list =
          this.gtmListName ||
          this.$route.path.replace(/(^\/[^/]+\/?)|(\/$)/g, '')
        this.gtm_queueProductImpression(this.product, list, this.position)
      }
    },
    imageLoaded() {
      this.loaded = true
    },
    mouseOver() {
      this.hovering = true
    },
    mouseOut() {
      this.hovering = false
    },
    startObserver() {
      const { createObserver, productCardElement } = this

      createObserver()
      this.observer.observe(productCardElement)
    },
    createObserver() {
      if ('IntersectionObserver' in window) {
        this.observer = new IntersectionObserver(
          (entries) => {
            entries.forEach(({ isIntersecting }) => {
              if (isIntersecting) {
                this.animated = true
              }
            })
          },
          { threshold: 1.0 }
        )
      }
    },
    trackClick() {
      this.gtm_trackProductClick(this.product, this.gtmListName)
      this.clickNotification(this.product.ticket, this.product.product)
    },
    addToCart() {
      if (this.selectedSize) {
        this.loading = true
        this.$addToCart(this.selectedSize.itemId)
          .catch((error) => console.log(error))
          .finally(() => {
            this.loading = false
            this.$store.dispatch('ui/toggleMiniCart')
            this.removeFromWishlist()
            this.addToCartNotification(
              this.product.ticket,
              this.product.product
            )
          })
      }
    },
  },
}
</script>
