<template>
  <div
    header
    class="top-0 left-0 z-30 w-full transition-colors"
    :class="[
      headerTextColor,
      headerBG,
      headerDisplay,
      {
        '-translate-y-full duration-500 transition-transform':
          hideScroll && !searchOpen,
        'translate-y-none duration-500  transition-transform': !hideScroll,
      },
    ]"
    :style="{
      position: position,
    }"
  >
    <div
      class="
        flex
        items-center
        justify-between
        px-6
        py-3
        lg:py-4 lg:px-10
        main-header
      "
    >
      <div class="flex items-center flex-1 gap-1">
        <ToggleMenu class="menu" :class="[headerFillColor]" />
        <ToggleSearch
          v-if="!alwaysShowSearchbar"
          inputid="mobileSiteSearch"
          :class="[headerFillColor]"
          class="flex lg:hidden"
        />
        <DesktopMenu :menu="desktopMenu" />
      </div>
      <mp-link
        :to="$u('')"
        title="EQPE"
        class="z-50 flex justify-center flex-1 cursor-pointer group"
      >
        <LogoIcon
          class="h-6 transition-colors lg:group-hover:fill-brandRed"
          :class="[headerFillColor]"
        />
      </mp-link>
      <div class="z-50 flex items-center justify-end flex-1 gap-4 lg:gap-6">
        <ToggleSearch
          v-if="!alwaysShowSearchbar"
          inputid="desktopSiteSearch"
          class="hidden lg:flex"
          :class="[headerFillColor]"
        />
        <ToggleWishlist
          class="wishlist"
          :class="[headerFillColor]"
          :fill="headerFillColor"
        />
        <ToggleCart
          :cart-qty="cartQty"
          device="mobile"
          :fill="headerFillColor"
        />
      </div>
    </div>
    <SearchInput
      v-if="searchOpen || alwaysShowSearchbar"
      :hide-close="alwaysShowSearchbar"
      inputid="mobileSiteSearch"
    />
    <FadeInTransition
      v-if="
        desktopSubmenu &&
        desktopSubmenu.content &&
        desktopSubmenu.content.length
      "
    >
      <DesktopSubMenu
        v-if="desktopSubmenu.showSubmenu"
        :content="desktopSubmenu.content"
        class=""
      />
    </FadeInTransition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DesktopSubMenu from '~/components/header/DesktopSubMenu'
import DesktopMenu from '~/components/header/DesktopMenu'
import LogoIcon from '~/static/images/logo.svg'
import ToggleCart from '~/components/header/ToggleCart'
import ToggleMenu from '~/components/header/ToggleMenu'
import ToggleSearch from '~/components/header/ToggleSearch'
import ToggleWishlist from '~/components/header/ToggleWishlist'
import SearchInput from '~/components/search/SearchInput'
import FadeInTransition from '~/components/transitions/FadeInTransition'

export default {
  name: 'Header',
  components: {
    DesktopMenu,
    DesktopSubMenu,
    LogoIcon,
    ToggleCart,
    ToggleMenu,
    ToggleSearch,
    ToggleWishlist,
    SearchInput,
    FadeInTransition,
  },
  props: {
    mainMenu: {
      type: Object,
      required: false,
      default: () => {},
    },
    cartQty: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      stuck: false,
      offset: 100,
      scrollpos: 0,
      position: 'sticky',
      hideScroll: false,
      lastScrollPosition: 0,
    }
  },
  computed: {
    ...mapGetters({
      settings: 'storyblok/settings',
      getStoryById: 'storyblok/getStoryById',
      countryCode: 'frontend/currentCountryCode',
    }),
    ...mapState({
      currentRoute: (state) => state.routes.currentRoute,
      searchOpen: (state) => state.ui.search.searchOpen,
      desktopSubmenu: (state) => state.ui['desktop-submenu'],
      headerDisplay: (state) => state.ui.header.headerDisplay,
    }),
    desktopMenu() {
      return this.mainMenu?.content?.topLevel ?? []
    },
    page() {
      if (this.currentRoute?.component?.component === 'storyblok') {
        return this.getStoryById(this.currentRoute.component.id)
      }
      return {}
    },
    alwaysShowSearchbar: ({ page }) =>
      page?.content?.alwaysShowSearchbar || false,
    headerBG: ({ $store, stuck, desktopSubmenu, searchOpen }) => {
      if (stuck || desktopSubmenu.showSubmenu || searchOpen) {
        return 'bg-white'
      } else {
        return $store.state.ui.header.headerBG
      }
    },
    headerTextColor: ({ $store, stuck, desktopSubmenu, searchOpen }) => {
      if (stuck || desktopSubmenu.showSubmenu || searchOpen) {
        return 'text-black'
      } else {
        return $store.state.ui.header.headerTextColor
      }
    },
    headerFillColor: ({ $store, stuck, desktopSubmenu, searchOpen }) => {
      if (stuck || desktopSubmenu.showSubmenu || searchOpen) {
        return 'fill-black'
      } else {
        return $store.state.ui.header.headerFillColor
      }
    },
    onPDP: ({ $store }) =>
      $store.state.routes.currentRoute?.component?.component === 'product',
  },
  mounted() {
    window.addEventListener('scroll', this.stickyHeader, {
      capture: true,
      passive: true,
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.stickyHeader)
  },
  methods: {
    stickyHeader() {
      this.scrollpos = window.scrollY
      this.stuck = this.scrollpos >= this.offset
      this.hideScroll =
        this.onPDP || this.page?.content?.hideNavOnScroll
          ? this.stuck
            ? this.scrollpos > this.lastScrollPosition
            : false
          : false
      this.lastScrollPosition = window.scrollY
    },
  },
}
</script>
