import StoryblokStory from '@made-people/centra-storyblok-nuxt-shared/lib/store/storyblok'

const getCountrySpecificInformationId = (language, marketId) =>
  ['country-information', marketId, language].join('-')

export default {
  ...StoryblokStory,
  mutations: {
    ...StoryblokStory.mutations,
    story: (state, payload) => {
      // removing unused data from stories before comitting to state
      // in order to save computing time for vue as it will not create observables
      const storyblokFieldsToRemove = [
        'created_at',
        'published_at',
        'default_full_slug',
        'sort_by_date',
        'tag_list',
        'meta_data',
        'group_id',
        'first_published_at',
        'release_id',
        'lang',
        'path',
        'translated_slugs',
        'alternates',
      ]

      storyblokFieldsToRemove.forEach((field) => {
        delete payload.data[field]
      })

      delete payload.data?.content?._editable
      state.stories = [payload].concat(
        state.stories.filter((x) => x.id !== payload.id)
      )
    },
  },
  actions: {
    ...StoryblokStory.actions,
    fetchStory({ commit, rootState, getters }, { id, params }) {
      let lookupId = id
      const link = getters.getLinkForSlug(id)
      if (link) {
        lookupId = link.id
      }
      params = params || {}
      params.language =
        params.language || rootState.frontend.currentLanguageCode
      return this.$storyapi
        .get(`cdn/stories/${lookupId}`, { params })
        .then((response) => {
          commit('story', {
            id: lookupId,
            data: response.data.story,
          })
          return response.data.story
        })
        .catch((error) => {
          console.error(
            `Couldn't storyblok/fetchStory(${{ id, params }}): ${JSON.stringify(
              error
            )}`
          )
        })
    },
  },
  getters: {
    ...StoryblokStory.getters,
    getCountryInformation: (state) => (languageCode, marketId) => {
      return (
        state.stories.find(
          (it) =>
            it.id === getCountrySpecificInformationId(languageCode, marketId)
        ) || { id: null, data: [] }
      )
    },
  },
}
