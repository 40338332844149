<template>
  <div class="flex flex-col">
    <ElevateFilterOption
      v-for="{ id, values } in filters"
      :key="id"
      :filter-id="id"
      :filters="values"
      :search-request-id="searchRequestId"
    />
  </div>
</template>

<script>
import ElevateFilterOption from './ElevateFilterOption.vue'

export default {
  name: 'MainFilterMenu',
  components: { ElevateFilterOption },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    searchRequestId: {
      type: String,
      required: true,
    },
  },
}
</script>
