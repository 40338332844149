<template>
  <div
    headline-container
    class="flex flex-col gap-1 lg:gap-2 headline"
    :class="[headlineColor]"
  >
    <div v-if="eyebrow && eyebrow.html" class="eyebrow" v-html="eyebrow.html" />
    <component
      :is="headlineType"
      v-if="headline && headline.html"
      class="font-normal tracking-widest headline-text"
      :class="[headlineColor, mobileHeadlineSize, desktopHeadlineSize]"
      v-html="rmvOl(headline.html)"
    />
    <div
      v-if="lead && lead.html"
      class="font-normal tracking-widest"
      :class="[headlineColor, headlineColor]"
      v-html="lead.html"
    />
  </div>
</template>
<script>
import Headline from '@made-people/centra-storyblok-nuxt-shared/lib/components/headline/Headline'
export default {
  ...Headline,
  props: {
    ...Headline.props,
    mobileHeadlineSize: {
      type: String,
      required: false,
      default: 'text-base',
    },
    desktopHeadlineSize: {
      type: String,
      required: false,
      default: 'text-base',
    },
    headlineColor: {
      type: String,
      required: false,
      default: 'text-black',
    },
  },
  computed: {
    ...Headline.computed,
  },
  methods: {
    rmvOl(str) {
      // Cast to string because it's a number on the error page
      let output = `${str}`
      let num

      // grab starting <ol> index
      const numIndex = output.indexOf('start="')
      if (numIndex > -1) {
        num = output.substring(numIndex + 7)
        const split = num.split('"')
        num = split[0]
      } else if (output.includes('<ol')) num = 1

      output = output.replace(/<li>*>/g, '')
      output = output.replace(/<\/li>*>/g, '')
      output = output.replace(/<ul>*>/g, '')
      output = output.replace(/<\/ul>*>/g, '')
      output = output.replace(/<ol[^>]*>/g, '')
      output = output.replace(/<\/ol>*>/g, '')

      return num ? `${num}. ${output}` : output
    },
  },
}
</script>
