export default {
  state() {
    return {
      headerBG: 'transparent',
      headerTextColor: 'text-white',
      headerFillColor: 'fill-white',
      headerDisplay: '',
    }
  },
  mutations: {
    setHeaderColors(state, { bg }) {
      switch (bg) {
        case 'white':
          state.headerBG = 'bg-white'
          state.headerTextColor = 'text-black'
          state.headerFillColor = 'fill-black'
          state.headerDisplay = ''
          break
        case 'transparent':
          state.headerBG = 'bg-transparent'
          state.headerTextColor = 'text-white'
          state.headerFillColor = 'fill-white'
          state.headerDisplay = ''
          break
        case 'black':
          state.headerBG = 'bg-black'
          state.headerTextColor = 'text-white'
          state.headerFillColor = 'fill-white'
          state.headerDisplay = ''
          break
        case 'hidden':
          state.headerDisplay = 'hidden'
          break
        default:
          state.headerBG = 'bg-white'
          state.headerTextColor = 'text-black'
          state.headerFillColor = 'fill-black'
          state.headerDisplay = ''
      }
    },
  },
  actions: {
    setHeaderColors({ commit }, payload) {
      commit('setHeaderColors', payload)
    },
  },
}
