export default {
  namespaced: true,
  state() {
    return {
      sizeguideModalOpen: false,
    };
  },
  mutations: {
    /**
     * Size Guide Modal
     */
    showSizeGuideModal(state) {
      // Close everything else
      state.miniCartOpen = false;
      state.mobileMenuOpen = false;
      state.mobileProductFilterOpen = false;
      state.hotspotProductsOpen = null;
      state.productRestockModalOpen = false;
      state.sizeSelectorOpen = false;
      state.countrySelectorOpen = false;
      state.sizeSelectorModalOpen = false;

      // Open Size Guide
      state.modalOpen = true;
      state.sizeguideModalOpen = true;
      state.overlayIndex = 11;
    },
    hideSizeGuideModal(state) {
      state.modalOpen = false;
      state.sizeguideModalOpen = false;
      state.overlayIndex = 0;
    },
  },
  actions: {
    showSizeGuideModal({ commit }) {
      commit('showSizeGuideModal');
    },
    hideSizeGuideModal({ commit }) {
      commit('hideSizeGuideModal');
    },
  },
};
