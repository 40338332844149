<template>
  <div
    class="
      flex
      justify-center
      w-full
      transition-opacity
      cursor-pointer
      hover:opacity-60
      lg:justify-start
    "
  >
    <div class="flex items-center gap-2" @click="showCountrySelector">
      <CurrentCountryFlag />
      <CountryName />
      <ChevronIcon class="h-4" />
    </div>
  </div>
</template>

<script>
import ChevronIcon from '~/assets/icons/chevron-down-small.svg'
import CountryName from '~/components/country-name/CountryName.vue'
import CurrentCountryFlag from '~/components/current-country-flag/CurrentCountryFlag.vue'

export default {
  name: 'CountrySelector',
  components: {
    ChevronIcon,
    CountryName,
    CurrentCountryFlag,
  },
  methods: {
    showCountrySelector() {
      this.$store.dispatch('ui/country-selector/showCountrySelector')
    },
  },
}
</script>
