<template>
  <div class="root" tabindex="0" @keyup.esc="closeModal">
    <SlideInTransitionLeft>
      <MobileSidebar
        v-if="mobileSidebarOpen"
        :main-menu="(mainMenu && mainMenu.content.topLevel) || []"
        :secondary-menu="
          (mainMenu && mainMenu.content.secondaryMenus[0].menuItems) || []
        "
        class="sidebar left"
      />
    </SlideInTransitionLeft>

    <!-- Slide in Country Selector from right -->
    <SlideInTransitionRight>
      <CountrySelectorSidebar
        v-if="countrySelectorOpen"
        :countries="countriesSorted"
        :markets="markets"
        :site-lang-slug="currentSiteLangSlug"
        class="sidebar right"
      />
    </SlideInTransitionRight>

    <!-- Slide in Cart from right -->
    <SlideInTransitionRight>
      <CartSidebar v-if="cartSidebarOpen" class="sidebar right" />
    </SlideInTransitionRight>

    <!-- Slide in Back To Stock Signup from right -->
    <SlideInTransitionRight>
      <BackInStockNotificationModal
        v-if="restockModalOpen"
        class="sidebar right"
      />
    </SlideInTransitionRight>

    <Topbar
      v-if="countryInformation !== undefined"
      :topbar-texts="countryInformation.topbarTexts"
      :background-color="countryInformation.topbarBgColor"
      :text-color="countryInformation.topbarTextColor"
    />

    <!-- Header, needs to be below <nuxt /> to recieve settings on page level in Storyblok -->
    <Header :cart-qty="cartQty" :main-menu="mainMenu" />
    <!-- Nuxt -->
    <nuxt />
    <!-- Footer -->
    <Footer>
      <CountrySelector
        slot="country-selector"
        :country-code="currentCountryCode"
      />
      <FooterMenu
        v-if="footerMenu && footerMenu.content.menus.length"
        slot="footer-menu"
        :menu="footerMenu && footerMenu.content.menus"
      />
    </Footer>

    <!-- Tinted Overlay to be used when triggering various popups and sidebars -->
    <FadeInTransition>
      <Overlay v-if="overlayZIndex > 0" :z-index="overlayZIndex" />
    </FadeInTransition>

    <!-- Newsletter Popup -->
    <NewsletterPopup
      v-if="
        countryInformation &&
        countryInformation.klaviyoNewsletterList &&
        showNewsletterPopup
      "
      :klaviyo-list="countryInformation.klaviyoNewsletterList"
      :image="countryInformation.newsletterImage"
      :headline="countryInformation.newsletterHeadline"
      :text="countryInformation.newsletterText"
      :newsletter-popup-enabled="countryInformation.newsletterPopupEnabled"
      :phone-number-enabled="countryInformation.phoneNumberEnabled"
      :icon-color="countryInformation.newsletterIconColor"
    />

    <!-- vue-notification placeholder -->
    <client-only>
      <notifications
        class="
          -translate-x-4 -translate-y-16
          [&>span]:flex [&>span]:flex-col [&>span]:gap-y-4
        "
        classes="p-4 text-white rounded-lg bg-brandRed tracking-wider"
        position="bottom right"
        width="20rem"
      />
    </client-only>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { isLanguageCode } from '@made-people/iso-639-1'
import BackInStockNotificationModal from '../components/back-in-stock/BackInStockNotificationModal.vue'
import Header from '@/components/header/Header'
import MobileSidebar from '@/components/header/MobileSidebar'
import CountrySelector from '~/components/country-selector/CountrySelector'
import CountrySelectorSidebar from '~/components/country-selector-sidebar/CountrySelectorSidebar.vue'
import Footer from '~/components/footer/Footer.vue'
import FooterMenu from '~/components/footer-menu/FooterMenu.vue'
import Overlay from '~/components/overlay/Overlay.vue'
import CartSidebar from '~/components/cart-sidebar/CartSidebar'
import SlideInTransitionLeft from '~/components/transitions/SlideInTransitionLeft'
import SlideInTransitionRight from '~/components/transitions/SlideInTransitionRight'
import FadeInTransition from '~/components/transitions/FadeInTransition'
import NewsletterPopup from '~/components/newsletter-popup/NewsletterPopup'

export default {
  name: 'DefaultLayout',
  components: {
    Header,
    CountrySelector,
    CountrySelectorSidebar,
    MobileSidebar,
    Footer,
    FooterMenu,
    Overlay,
    CartSidebar,
    SlideInTransitionLeft,
    SlideInTransitionRight,
    FadeInTransition,
    BackInStockNotificationModal,
    NewsletterPopup,
  },
  head() {
    const isoLanguageCode = this.currentLanguage.iso639

    const testFreaksLanguageCode = {
      sv: 'sv',
      nb: 'no',
      da: 'dk',
      en: 'en',
      de: 'de',
      it: 'it',
      fr: 'fr',
    }[isoLanguageCode]

    const script = []

    if (testFreaksLanguageCode) {
      const stagingString =
        this.$config.baseUrl === 'https://www.eqpestore.com' ? '' : '-staging'
      const testFreaksSrc = `https://js.testfreaks.com/onpage/eqpestore-${testFreaksLanguageCode}${stagingString}/head.js`
      script.push({
        src: testFreaksSrc,
        async: true,
      })
    }

    if (isLanguageCode(isoLanguageCode)) {
      return {
        script,
        htmlAttrs: { lang: isoLanguageCode },
      }
    } else {
      return {
        script,
      }
    }
  },
  computed: {
    ...mapState({
      restockModalOpen: (state) => state.ui['restock-notification'].sidebarOpen,
      countrySelectorOpen: (state) =>
        state.ui['country-selector'].countrySelectorOpen,
      cartSidebarOpen: (state) => state.ui['cart-sidebar'].cartSidebarOpen,
      overlayZIndex: (state) => state.ui.overlay.overlayZIndex,
      markets: (state) => state['centra-market'].markets,
      mobileSidebarOpen: (state) =>
        state.ui['mobile-sidebar'].mobileSidebarOpen,
      countryInformation: (state) =>
        state?.storyblok?.stories?.find(
          (x) => x.data?.content?.component === 'CountryInformation'
        )?.data?.content,
    }),
    ...mapGetters({
      countriesSorted: 'frontend/countriesSorted',
      currentCountryCode: 'frontend/currentCountryCode',
      currentLanguageCode: 'frontend/currentLanguageCode',
      currentSiteLangSlug: 'frontend/currentSiteLangSlug',
      currentLanguage: 'frontend/currentLanguage',
      cart: 'centra-cart/cart',
      getMenuById: 'storyblok/getMenuById',
      settings: 'storyblok/settings',
    }),
    mainMenu: ({ getMenuById }) => getMenuById('main-menu'),
    footerMenu: ({ getMenuById }) => getMenuById('footer-menu'),
    cartQty() {
      if (this.cart) {
        return this.cart.items.reduce((acc, cur) => {
          acc = cur.quantity + acc
          return acc
        }, 0)
      } else {
        return 0
      }
    },
    showNewsletterPopup() {
      return this.$cookies.get('newsletter-popup') !== 1
    },
  },
  watch: {
    $route() {
      this.$store.dispatch('ui/close-all/closeAll')
    },
  },
  beforeDestroy() {
    this.removeCentraEventListener()
  },
  mounted() {
    this.addCentraEventListener()

    document.addEventListener('centra_checkout_payment_callback', () => {
      const selectionId = this.cart.selection
      this.$store.dispatch('last-order/set', selectionId)
    })
  },
  methods: {
    addCentraEventListener() {
      document.addEventListener(
        'centra_checkout_payment_callback',
        this.centraCheckoutCallback.bind(this)
      )
    },
    removeCentraEventListener() {
      document.removeEventListener(
        'centra_checkout_payment_callback',
        this.centraCheckoutCallback.bind(this)
      )
    },
    centraCheckoutCallback(eventData) {
      const postData = eventData.detail
      const responseEventRequired = postData.responseEventRequired
      const addressIncluded = postData.addressIncluded
      this.$store
        .dispatch('centra-cart/checkoutPaymentCallback', {
          paymentMethod: postData.paymentMethod,
          address: addressIncluded
            ? postData.shippingAddress
            : shippingAddressFromCheckout(),
          paymentMethodSpecificFields: postData.paymentMethodSpecificFields,
        })
        .then((data) => {
          // TODO: This should most likely be done in 'centra-cart/checkoutUpdateFields'

          if (responseEventRequired) {
            if (data.action === 'success') {
              // Order was placed directly, redirect to result page with receipt to GET
              this.$router.push(
                this.$u(`checkout/result?receipt=${data.receiptId}`)
              )
              return
            }
            if (data.action === 'javascript') {
              const updateEvent = new CustomEvent(
                'centra_checkout_payment_response',
                { detail: data.formFields }
              )
              document.dispatchEvent(updateEvent)
            }
            if (data.action === 'redirect') {
              window.location.href = data.url
              return
            }
            if (data.errors) {
              // Payment failed for some reason, show error
              this.$notify({
                title: this.$t('Checkout_PlaceOrder_Generic_ErrorTitle'),
                text: this.$t('Checkout_PlaceOrder_Generic_ErrorMsg'),
                type: 'error',
              })
            }
          }
        })
    },
    closeModal() {
      this.$store.dispatch('ui/close-all/closeAll')
    },
  },
}
</script>
