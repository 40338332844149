<template>
  <div
    class="flex gap-4 pb-5 border-b border-solid border-lightGray"
    :class="{ 'animate-pulse': loading }"
  >
    <mp-link
      class="
        p-2
        bg-lightGray
        w-[70px]
        h-[83px]
        flex
        justify-center
        items-center
      "
      :to="$u(productUrl)"
    >
      <Vue2Image
        :src="productImage"
        :alt="productName"
        background="f0f0f0"
        :widths="[50, 100]"
        :quality="60"
      />
    </mp-link>
    <div class="flex flex-col justify-between flex-1">
      <div class="flex justify-between">
        <div class="flex flex-col gap-1">
          <mp-link class="text-base" :to="$u(productUrl)">
            {{ productName }}
          </mp-link>
          <span v-if="!isBundle" class="text-sm uppercase">{{
            productMetaData
          }}</span>
        </div>
        <button
          class="flex items-start"
          :class="{ 'opacity-10 select-none': loading }"
          @click="removeItem"
        >
          <CrossIcon class="w-2 h-2" />
        </button>
      </div>

      <div class="flex items-center justify-between text-sm">
        <div class="flex gap-4">
          <button
            class="minus group disabled:opacity-10"
            :class="{ 'opacity-10 select-none': loading }"
            aria-label="Decrease quantity"
            @click="decreaseQty"
          >
            <MinusIcon class="w-3 h-3 group-hover:fill-brandRed" />
          </button>
          <span class="text-sm font-semibold">{{ item.quantity }}</span>
          <button
            :disabled="!canAddMore || !moreItemsAvailable || isBundle"
            class="plus group disabled:opacity-10"
            :class="{
              'opacity-10 select-none': loading || !canAddMore,
            }"
            aria-label="Increase quantity"
            @click="increaseQty"
          >
            <PlusIcon class="w-3 h-3 group-hover:fill-brandRed" />
          </button>
        </div>
        <div class="flex flex-col items-end">
          <div
            v-if="
              item.priceEachAsNumber !== item.priceEachBeforeDiscountAsNumber
            "
            class="line-through"
          >
            <span>{{ item.priceEachBeforeDiscount }}</span>
          </div>

          <span
            class="text-sm font-semibold"
            :class="{
              'text-brandRed':
                item.priceEachAsNumber !== item.priceEachBeforeDiscountAsNumber,
            }"
            >{{ item.priceEach }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MinusIcon from '~/static/images/minus.svg'
import PlusIcon from '~/static/images/plus.svg'
import CrossIcon from '~/assets/icons/cross.svg'

export default {
  name: 'CartItem',
  components: {
    MinusIcon,
    PlusIcon,
    CrossIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    moreItemsAvailable: ({ item }) => item._product?.moreItemsAvailable,
    productUrl: ({ item }) => item._product?.productUrl,
    productMetaData: ({ item }) => item._product?.productMetaData,
    productImage: ({ item }) => item._product?.productImage,
    productName: ({ item }) => item._product?.productName,
    canAddMore: ({ item }) => item._product?.canAddMore,
    isBundle: ({ item }) => 'bundle' in item,
  },
  methods: {
    updateQty(quantity) {
      const qtyDiff = quantity - this.item.quantity
      this.loading = true

      let payload = {}
      let slug = ''

      if (this.item.bundle) {
        payload = { lineId: this.item.line }
        slug = 'deleteBundle'
      } else {
        payload = {
          item: this.item.item,
          quantity,
        }
        slug = 'updateItem'
      }

      return this.$store
        .dispatch(`centra-cart/${slug}`, {
          ...payload,
        })
        .then((cart) => {
          if (qtyDiff < 0) {
            // Lowering qty
            this.gtm_trackRemoveFromCart(this.item, Math.abs(qtyDiff))
          } else {
            // Increasing qty
            this.gtm_trackAddToCart(this.item, qtyDiff)
          }
          this.loading = false
          this.$sysend.broadcast('cartUpdate', {
            page: document.title,
            method: 'CartItem.vue updateQty',
          })
          return cart
        })
    },
    removeItem() {
      this.updateQty(0)
    },
    decreaseQty() {
      this.updateQty(this.item.quantity - 1)
    },
    increaseQty() {
      if (!this.canAddMore) return
      this.updateQty(this.item.quantity + 1)
    },
  },
}
</script>
