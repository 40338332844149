<template>
  <div class="w-full mb-16">
    <!-- Mobile Menu -->
    <div class="flex flex-col gap-6 lg:hidden">
      <div v-for="(item, index) in menu" :key="index" class="flex flex-col">
        <!-- Accordion Header -->
        <div
          class="flex items-center justify-between mb-2 font-semibold"
          :class="{ open: expandedItem === index }"
          @click="toggleItem(index)"
        >
          {{ item.label }}
          <ChevronDownIcon class="h-4" />
        </div>

        <!-- Open an item -->
        <transition-expand>
          <div v-if="expandedItem === index" class="flex flex-col gap-1">
            <mp-link
              v-for="(link, itemIndex) in item.menuItems"
              :key="itemIndex"
              :to="$u(link.link)"
              :target="link.targetBlank ? '_blank' : '_self'"
              :rel="link.targetBlank ? 'noreferrer nofollow' : false"
            >
              {{ link.label }}
            </mp-link>
          </div>
        </transition-expand>
      </div>
    </div>
    <!-- Desktio Menu -->
    <div class="flex-row hidden gap-24 lg:flex">
      <div
        v-for="(link, index) in menu"
        :key="'menu-column-' + index"
        class="flex flex-col gap-2"
        :style="index !== 2 ? { order: index } : { order: 1 }"
      >
        <div class="mb-2 font-semibold">
          <span class="tracking-wider">{{ link.label }}</span>
        </div>
        <div
          v-for="(child, indexKey) in link.menuItems"
          :key="'child-' + indexKey"
          class="transition-opacity hover:opacity-50"
        >
          <img
            v-if="child.icon.filename"
            :src="child.icon.filename"
            :alt="child.label"
            loading="lazy"
          />
          <mp-link
            :to="$u(child.link)"
            :target="child.targetBlank ? '_blank' : '_self'"
            :rel="child.targetBlank ? 'noreferrer nofollow' : false"
          >
            {{ child.label }}
          </mp-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronDownIcon from '~/assets/icons/chevron-down-small.svg'

export default {
  name: 'FooterMenu',
  components: {
    ChevronDownIcon,
  },
  props: {
    menu: {
      type: Array,
      required: false,
      default() {},
    },
  },
  data() {
    return {
      expandedItem: null,
    }
  },
  methods: {
    toggleItem(item) {
      if (item !== this.expandedItem) {
        this.expandedItem = item
      } else {
        this.expandedItem = null
      }
    },
  },
}
</script>
