import { isE164PhoneNumberString } from '@made-people/types-e164-phone-number-string'

export default {
  computed: {
    verifiedMail() {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.email
      )
    },
    verifiedPhone() {
      return isE164PhoneNumberString(this.phone)
    },
  },
  methods: {
    subscribe(newsletterData) {
      const { email, phone, list } = newsletterData

      return new Promise((resolve, reject) => {
        const successSubscription = () => {
          this.$notify({
            title: this.$t('Newsletter_Signup_SuccessHeader'),
            text: this.$t('Newsletter_Signup_SuccessMsg'),
            type: 'success',
          })
        }
        
        const handleSubscriptionError = (error) => {
          console.error(error)
          this.$notify({
            title: this.$t('Newsletter_Signup_ErrorHeader'),
            text: this.$t('Newsletter_Signup_ErrorMsg'),
            type: 'error',
          })
        }
        
        const register = async (token) => {
          return await this.$backendApi.post(
            `klaviyo/list/${list}/subscribe?recaptchaToken=${token}`,
            {
              email,
              phone
            }
            )
          }
          
          const executeRecaptchaTestAndRegister = async () => {
            try {
              const token = await window.grecaptcha.execute(
                this.$config.recaptchaSiteKey,
                { action: 'submit' }
                )
                await register(token)
                successSubscription()
                resolve()
              } catch (error) {
                handleSubscriptionError(error)
                reject(error)
              }
            }
            
          if (!window.grecaptcha) {
            this.loadRecaptchaScript(() =>
            window.grecaptcha.ready(executeRecaptchaTestAndRegister)
            )
          } else {
            window.grecaptcha.ready(executeRecaptchaTestAndRegister)
          }
      })
    },
    loadRecaptchaScript(onLoadCallback) {
      const recaptchaScript = document.createElement('script')
      recaptchaScript.onload = onLoadCallback
      recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${this.$config.recaptchaSiteKey}`
      document.head.appendChild(recaptchaScript)
    },
  },
}
