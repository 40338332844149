<template>
  <footer
    footer
    class="flex justify-center w-full"
    :class="[$store.state.ui.header.headerDisplay]"
  >
    <div class="flex flex-col w-full max-w-screen-lg px-10 py-16 lg:pt-32">
      <Newsletter
        v-if="globalList && !isCheckoutSuccess"
        class="mb-16"
        :list="globalList"
        foreground-color="white"
        :title="$t('Newsletter_Copy')"
      />
      <div class="flex justify-between">
        <slot name="footer-menu" />
        <SocialIconsFooter :icons="socialMediaLinks" class="hidden lg:flex" />
      </div>
      <div class="">
        <div class="mb-16">
          <slot name="country-selector" />
        </div>
        <div class="flex flex-col"></div>
        <SocialIconsFooter
          :icons="socialMediaLinks"
          class="flex justify-center lg:hidden"
        />
      </div>

      <FooterLogos :logos="footerLogos" />
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import Newsletter from '~/components/newsletter/Newsletter.vue'
import SocialIconsFooter from '~/components/footer/SocialIconsFooter.vue'
import FooterLogos from '~/components/footer/FooterLogos.vue'
export default {
  name: 'Footer',
  components: { Newsletter, SocialIconsFooter, FooterLogos },
  props: {
    footer: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  computed: {
    ...mapGetters({ getMenuById: 'storyblok/getMenuById' }),
    globalList: ({ $store }) =>
      $store.getters['storyblok/settings']?.footerNewsletterList,
    footerLogos: ({ $store }) =>
      $store.state.storyblok.stories.find(
        (x) => x.data?.content?.component === 'CountryInformation'
      )?.data?.content?.footerLogos,
    socialMediaLinks: ({ getMenuById }) =>
      getMenuById('footer-menu')?.content?.socialMediaLinks || [],
    isCheckoutSuccess() {
      return this.$route.path.includes('checkout')
    },
  },
}
</script>
