export const ELEVATE_QUERY_LIMIT_DEFAULT = '20'

export function elevateTouchpoint() {
  const isDesktop =
    typeof window !== 'undefined' ? window.innerWidth > 1024 : false
  return isDesktop ? 'DESKTOP' : 'MOBILE'
}

/**
 * Maps the search response from elevate to match productList component
 *
 * @param {Object} searchResponse - search response from elevate
 * @param {String} currentCurrency - current currency
 * @returns {Object} - Mapped search response
 */
export const mapSearchResponse = (searchResponse, currentCurrency) => {
  const productGroups =
    searchResponse?.primaryList?.productGroups ||
    searchResponse?.recommendationLists[0]?.productGroups
  const facets = searchResponse?.primaryList?.facets
  const productCount = searchResponse?.primaryList?.totalHits
  let preparedFilters = []

  if (Array.isArray(facets)) {
    preparedFilters = facets
      .map((facet) => mapFilter(facet))
      .filter((p) => p !== undefined)
  }

  if (!productGroups?.length || !facets) {
    if (searchResponse?.recommendationLists?.length && productGroups?.length) {
      const mappedRecommendationProducts = productGroups
        .map((productGroup) => mapProduct(productGroup, currentCurrency))
        .filter((p) => p !== undefined)
      return {
        preparedFilters,
        mappedProducts: mappedRecommendationProducts ?? [],
        productCount: mappedRecommendationProducts?.length,
      }
    }
    return {
      preparedFilters,
      mappedProducts: [],
      productCount: 0,
    }
  }
  const mappedProducts = productGroups
    .map((productGroup) => mapProduct(productGroup, currentCurrency))
    .filter((p) => p !== undefined)
  const hasMoreProducts = productCount > mappedProducts.length
  const mappedSearchResponse = {
    preparedFilters,
    mappedProducts,
    hasMoreProducts,
    productCount,
  }
  return mappedSearchResponse
}

/**
 * Maps an Elevate Product Group to the centra Product attributes neccessary for productCard
 *
 * @param {Object} productGroup - The current Product Group from elevate that corresponds to a centra product
 * @param {String} currentCurrency - Current currrency
 * @returns {Object | undefined} - The mapped product or undefined
 */
export const mapProduct = (productGroup, currentCurrency) => {
  const primaryProduct = productGroup?.products[0]

  if (!primaryProduct || !primaryProduct.variants?.length) {
    return undefined
  }

  const {
    inStock,
    imageInfo = {},
    custom = {},
    variants,
    title,
    link = '',
    key,
    ticket,
  } = primaryProduct
  const mainImage = imageInfo.images?.[0]?.sources?.[0]?.url
  const url = transformElevateLinkToULink(link)
  const priceData = variants[0].prices?.[0] || {}
  const { sellingPrice, listPrice } = priceData
  const {
    badges = [],
    gender,
    variantName = [],
    collection = {},
    // eslint-disable-next-line camelcase
    filter__product_type = [],
    sku = [],
    productSku = [],
    isBundle,
    // eslint-disable-next-line camelcase
    back_in_stock_disabled,
  } = custom
  const sizeOptions = mapSizeOptions(variants)

  const product = {
    sizeOptions,
    name: title,
    url,
    inStock,
    mainImage,
    isOneSize: sizeOptions.length === 1,
    price: `${sellingPrice} ${currentCurrency}`,
    priceBeforeDiscount: `${listPrice} ${currentCurrency}`,
    priceAsNumber: sellingPrice,
    discounted: listPrice > sellingPrice,
    discountPercent: Math.round(((listPrice - sellingPrice) * 100) / listPrice),
    productSku: getNestedLabel(productSku) ?? '',
    sku: getNestedLabel(sku) ?? '',
    product: key,
    backInStockDisabled:
      getNestedLabel(back_in_stock_disabled) === '1' ?? false,
    swatchProducts: getSwatchData(productGroup.products),
    sticker: getBadges(badges),
    silkProductName: mapProductName(title, variantName),
    gender: getNestedLabel(gender) ?? '',
    product_type_english: getNestedLabel(filter__product_type) ?? '',
    brandName: collection.label ?? '',
    ticket,
    thumbnailImage: imageInfo.thumbnail,
    isBundle,
  }

  return product
}

/**
 * Maps the sizes for all variants and there stock level
 * @param {Array<Object>} sizeVariants - The size variants
 * @returns {Array<Object>} - Array with mapped size data
 */
const mapSizeOptions = (sizeVariants) => {
  return sizeVariants.map((size) => ({
    label: size.label,
    quantity: size.stockNumber,
    value: size.size,
    inStock: size.stockNumber > 0,
    sku: size.key,
    itemId: size.key,
  }))
}

/**
 * Parses and maps badge data from elevate into correct format
 *
 * @param {Array<Object> | undefined} elevateBadges - array with badges from elevate
 * @returns {Array<Object>} - Badges mapped to correct format
 */
const getBadges = (elevateBadges) => {
  let badges = []
  if (elevateBadges.length) {
    badges = elevateBadges.map(function (badge) {
      const parsedBadge = JSON.parse(badge.label)
      return {
        bg: parsedBadge.bg_hex_code,
        textColor: parsedBadge.text_hex_code,
        name: parsedBadge.name,
      }
    })
  }
  return badges
}

/**
 * Get the related swatch data for the current product
 *
 * @param {Array<Object>} products - All products related to the current product
 * @returns {Array<Object>} - Swatch data for the product
 */
const getSwatchData = (products) => {
  return products.map((productVariant) => ({
    url: transformElevateLinkToULink(productVariant?.link ?? ''),
    swatch: {
      cssColorValue: productVariant.swatch?.colors?.[0],
    },
  }))
}

const transformElevateLinkToULink = (link) => {
  return link.replace(/^\//, '') // Elevate has to send link with slash in the beginning, but the $u function does not support it, therfore it is removed
}

/**
 * Maps the filter data that is returned from the Elevate API to the format that is expected by the frontend
 * @param {Object} elevateFacetData - Facet data that is returned from the Elevate API
 * @returns - Mapped filter data or undefined if the data is not valid
 */
const mapFilter = (elevateFacetData) => {
  if (elevateFacetData.values) {
    return {
      id: elevateFacetData.id,
      values: elevateFacetData.values,
    }
  } else if (elevateFacetData.sizeTypes) {
    return mapSizeFacetToFilter(elevateFacetData)
  } else {
    return undefined
  }
}

/**
 * Maps an elevate sizeFacet to a filter
 * @param {object} facet - size facet from elevate
 * @returns {object} - filter
 */
function mapSizeFacetToFilter(facet) {
  const { id, sizeTypes } = facet
  let values = []
  sizeTypes.forEach(
    (sizeType) => (values = values.concat(mapSizeTypeToFilterValue(sizeType)))
  )
  return {
    id,
    values,
  }
}

/**
 * Maps a sizetypes and returns the values
 * @param {object} sizeType - size type from elevate
 * @returns {Array} - values from the size type
 */
function mapSizeTypeToFilterValue(sizeType) {
  const { formats } = sizeType
  let values = []
  formats.forEach((format) => (values = values.concat(format.values)))
  return values
}

/**
 * Transforms the filter data from the frontend to the format that is expected by the Elevate API
 * @param {Object} filters - Filter data from the frontend
 * @returns - filters in the format that is expected by the Elevate API
 */
const getQueryFilters = (filters) => {
  const queryFilters = {}
  for (const [filterId, filterValues] of Object.entries(filters || {})) {
    queryFilters[`f.${filterId}`] = filterValues.join(' | ')
  }
  return queryFilters
}

/**
 * Maps the product name to remove the variant name from the product name
 * @param {String} name - product name including variant name
 * @param {Array} variantName - variant name Array
 * @returns - product name without variant name (color)
 */
function mapProductName(name, variantName) {
  if (variantName.length) {
    return name.replace(variantName[0]?.id, '')
  }
  return name
}

/**
 * Returns the custom present string for elevate
 * @returns - custom present string for elevate
 */
export const getPresentCustom = () => {
  return 'gender | badges | variantName | collection | filter__product_type | sku | productSku | isBundle | back_in_stock_disabled'
}

/**
 * Maps pricelist and market to the price id that elevate expects
 * @param {*} rootGetters - Root getters from vuex store
 * @returns - Mapped price id
 */
export const getPriceId = (rootGetters) => {
  const currentPricelistId = rootGetters['frontend/pricelist'].pricelist
  const currentMarketId = rootGetters['frontend/currentMarketId']
  return `${currentMarketId}-${currentPricelistId}`
}

/**
 * Gets the market id from the root getters to be used as store id
 * @param {*} rootGetters - Root getters from vuex store
 * @returns - Mapped store id
 */
export const getStoreId = (rootGetters) => {
  return rootGetters['frontend/currentMarketId']
}

/**
 * Gets the current currency from the root getters
 * @param {*} rootGetters - Root getters from vuex store
 * @returns - Current currency
 */
export const getCurrentCurrency = (rootGetters) => {
  return rootGetters['frontend/pricelist'].name || ''
}

/**
 * Gets the params for the elevate search query
 * @param {*} rootGetters - root getters from vuex store
 * @param {*} query - query for elevate search
 */
export const getParams = (rootGetters, query) => {
  const limit = query.limit || ELEVATE_QUERY_LIMIT_DEFAULT
  const skip = limit * (query.page - 1) || 0
  return {
    stores: getStoreId(rootGetters),
    touchpoint: elevateTouchpoint(),
    presentPrices: getPriceId(rootGetters),
    presentCustom: getPresentCustom(),
    ...getQueryFilters(query.filters),
    limit,
    skip,
  }
}

/**
 * Gets a nested label from an object
 * @param {*} object - object to get the label from
 * @returns - label from the object or undefined
 */
export const getNestedLabel = (object) => {
  return object?.[0]?.label
}

/**
 * Utility function to get elevate product keys as a pipe separated list in a string from a selection of items or bundles
 * @param selectionItems - Selection items or bundles from centra selection
 * @returns String with pipe separated product keys to use in elevate cart page request
 */

export const getElevateProductKeysFromCentraSelection = (selectionItems) => {
  const productKeys = selectionItems.map((item) => item.product)

  return productKeys.join('|') // returns a string with pipe separated product keys
}
