export default {
  mutations: {
    disableScroll() {
      document.body.classList.add('overscroll-none', 'overflow-hidden')
    },
    enableScroll() {
      document.body.classList.remove('overscroll-none', 'overflow-hidden')
    },
  },
  actions: {
    disableScroll({ commit }) {
      commit('disableScroll')
    },
    enableScroll({ commit }) {
      commit('enableScroll')
    },
  },
}
