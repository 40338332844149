<template>
  <div class="flex items-start justify-end w-full gap-6 mb-16">
    <mp-link
      v-for="({ link, icon }, i) in icons"
      :key="`social-links__link${i}${_uid}`"
      target="_blank"
      class="transition-opacity hover:opacity-50"
      :to="link && link.cached_url ? $u(link) : ''"
    >
      <Vue2Image
        v-if="icon && icon.filename"
        class="w-5 h-5"
        :src="icon.filename"
        :widths="[100]"
        :width="100"
        :quality="60"
        :alt="`social-links__link${i}${_uid}`"
      />
    </mp-link>
  </div>
</template>
<script>
export default {
  name: 'SocialIconsFooter',
  props: {
    icons: {
      type: Array,
      required: true,
    },
  },
}
</script>
