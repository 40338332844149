<template>
  <mp-link :to="$u('wishlist')">
    <NavItem class="relative">
      <client-only>
        <div
          :title="$t('Toggle_Wishlist_Open')"
          class="w-[22px]"
          @mouseover="hover = true"
          @mouseleave="hover = false"
        >
          <WishlistFullIcon v-if="hover" />
          <WishlistEmptyIcon v-else :class="[fill]" />
        </div>
        <div
          v-if="productIDs.length > 0"
          class="
            absolute
            right-0
            flex
            items-center
            justify-center
            w-4
            h-4
            text-[10px] text-white
            rounded-full
            top-1
            bg-brandRed
          "
          :class="{ 'animate-bounce': animationActive }"
        >
          <span>{{ productIDs.length }}</span>
        </div>
      </client-only>
    </NavItem>
  </mp-link>
</template>

<script>
import WishlistEmptyIcon from '~/static/images/wishlist-empty.svg'
import WishlistFullIcon from '~/static/images/wishlist-full.svg'
import NavItem from '~/components/header/NavItem.vue'

export default {
  name: 'ToggleWishlist',
  components: {
    WishlistEmptyIcon,
    WishlistFullIcon,
    NavItem,
  },
  props: {
    fill: {
      type: String,
      default: 'fill-black',
    },
  },
  data() {
    return {
      hover: false,
      animationActive: false,
    }
  },
  computed: {
    productIDs: ({ $store }) => $store.state.wishlist?.productIDs ?? [],
  },
  watch: {
    productIDs() {
      this.animationActive = true
      setTimeout(() => {
        this.animationActive = false
      }, 500)
    },
  },
}
</script>
