import Vue from 'vue'

import { mapGetters, mapState } from 'vuex'
import { getElevateMarketData } from './elevate-client'

Vue.mixin({
  computed: {
    ...mapGetters({
      market: 'frontend/market',
      marketId: 'frontend/currentMarketId',
      pricelist: 'frontend/pricelist',
      currentLanguageCode: 'frontend/currentLanguageCode',
      currentCountryCode: 'frontend/currentCountryCode',
      gtmCart: 'centra-cart/cart',
    }),
    ...mapState({
      elevateCustomerKey: (state) => state.elevate.customerKey,
      elevateSessionKey: (state) => state.elevate.sessionKey,
    }),
  },
  mounted() {
    // Lazy init of the dataLayer that the GTM initialize will use
    window.dataLayer = window.dataLayer || []

    // Don't hate me for using window here
    window.queuedProductImpressions = window.queuedProductImpressions || []
  },
  methods: {
    gtm_trackPage(pageType, pageName) {
      pageName = pageName || pageType

      const payload = {
        event: 'pageView',
        pageType,
        pageName,
      }

      if (this.$store.state?.account?.account?.isLoggedIn) {
        // How to make this work on initial page load since this fires before
        // the account xhr has finished loading?
        payload.userId = this.$store.state.account.account.customer
      }

      payload.language = this.currentLanguageCode
      payload.country = this.currentCountryCode

      window.dataLayer.push(payload)
      window.dataLayer.push({
        event: 'dynx_rem',
        google_tag_params: {
          ecomm_pagetype: pageType,
        },
      })
    },

    gtm_trackProductClick(product, list) {
      const productId = product.sku
      const name = product.name
      if (!name) {
        console.error(
          `gtm_trackProductClick: Product id=${productId} name not found`
        )
        return
      }
      const currencyCode = this.pricelist?.currency?.uri
      if (!currencyCode) {
        console.error(
          `gtm_trackProductClick: Product id=${productId} currency not found`
        )
        return
      }
      const payload = {
        event: 'productClick',
        ecommerce: {
          currencyCode,
          click: {
            actionField: {
              list,
            },
            products: [
              {
                id: productId,
                name,
                price: product.priceAsNumber,
                category: product.product_type_english,
                url: this.$config.baseUrl + this.$u(product.url),
                sku: product.sku,
                imageUrl: product.mainImage,
                brand: product.brandName,
              },
            ],
          },
        },
      }
      window.dataLayer.push(payload)
      this.$store.dispatch('gtm/lastPushedClick', payload)
    },

    async gtm_trackProductDetail(product) {
      const payload = {
        event: 'productDetail',
        ecommerce: {
          currencyCode: this.pricelist.currency.currency,
          detail: {
            products: [
              {
                name: product.name,
                id: product.sku,
                brand: product.brandName,
                category: product.product_type_english,
                price: parseFloat(product.priceAsNumber),
                url: `${this.$config.baseUrl}${this.$u(product.url)}`,
                imageUrl: this.$imgproxy.transform(
                  product.mainImage,
                  'extend:1/resize:fit/w:1200/h:630/q:70'
                ),
                productNumber: product.productSku
              },
            ],
          },
        },
      }

      if (this.$store.state.gtm) {
        const pushedClick = await this.$store.dispatch('gtm/popLastPushedClick')
        if (pushedClick) {
          const list = pushedClick?.ecommerce?.click?.actionField?.list
          if (list) {
            payload.ecommerce.detail.actionField = { list }
          }
        }
      }

      window.dataLayer.push(payload)
      window.dataLayer.push({
        event: 'dynx_rem',
        google_tag_params: {
          ecomm_pagetype: 'product',
          ecomm_prodid: [product.sku],
        },
      })
    },

    gtm_trackProductImpression(impressions) {
      window.dataLayer.push({
        event: 'productImpressions',
        ecommerce: {
          currencyCode: this.pricelist.currency.currency,
          impressions,
        },
      })
    },

    /**
     * We queue impressions to 20 and flush them either when number 20 is
     * pushed or after the queue has existed for 0.5 seconds. The reason for
     * this is that you can push too much data by mistake into GTM and we
     * don't want to do that, while we also want to push the separate product
     * as they appear in the browser window
     */
    gtm_queueProductImpression(product, list, position) {
      try {
        window.queuedProductImpressions.push({
          id: product.sku,
          name: product.name,
          price: product.priceAsNumber,
          category: product.product_type_english,
          list,
          position,
        })
        const flushImpressionQueue = () => {
          this.gtm_trackProductImpression(window.queuedProductImpressions)
          window.queuedProductImpressions = []
        }
        clearTimeout(window.impressionPushTimer)
        if (window.queuedProductImpressions.length >= 20) {
          flushImpressionQueue()
        } else {
          window.impressionPushTimer = setTimeout(flushImpressionQueue, 500)
        }
      } catch (e) {
        // Failing quietly
        // console.error('gtm_queueProductImpression failed')
      }
    },

    gtm_trackAddToCart(item, quantity) {
      const {
        grandTotalPriceAsNumber,
        itemsTotalPriceAsNumber,
        totalDiscountPriceAsNumber,
      } = this.gtmCart.totals
      const totalWithShipping = grandTotalPriceAsNumber ?? 0
      const totalWithoutShipping =
        (itemsTotalPriceAsNumber ?? 0) + (totalDiscountPriceAsNumber ?? 0)

      const payload = {
        event: 'addToCart',
        eventLabel: item.productName,
        ecommerce: {
          currencyCode: this.pricelist.currency.currency,
          add: {
            products: [
              {
                name: item.productName,
                id: item._product.sku,
                price: parseFloat(item.priceEachAsNumber),
                category: item._product.product_type_english,
                brand: item.brandName,
                quantity: quantity || item.quantity,
                image: this.$imgproxy.transform(
                  item._product.mainImage,
                  'extend:1/resize:fit/w:1200/h:630/q:70'
                ),
                title: item._product.name,
                url: this.$config.baseUrl + this.$u(item._product.url),
                sku: item.ean,
              },
            ],
          },
          cartItems: {
            products: this.gtmCart.items.map((item) => ({
              name: item.productName,
              id: item._product.sku,
              price: parseFloat(item.priceEachAsNumber),
              category: item._product.product_type_english,
              brand: item.brandName,
              quantity: item.quantity,
              image: this.$imgproxy.transform(
                item._product.mainImage,
                'extend:1/resize:fit/w:1200/h:630/q:70'
              ),
              title: item._product.name,
              url: this.$config.baseUrl + this.$u(item._product.url),
              sku: item.ean,
            })),
          },
          order: {
            totalWithShipping,
            totalWithoutShipping,
            checkoutUrl: this.$config.baseUrl + this.$u('checkout'),
          },
        },
      }
      window.dataLayer.push(payload)
    },

    gtm_trackRemoveFromCart(item, quantity) {
      window.dataLayer.push({
        event: 'removeFromCart',
        eventLabel: item.productName,
        ecommerce: {
          currencyCode: this.pricelist.currency.currency,
          remove: {
            products: [
              {
                name: item.productName,
                id: item._product.sku,
                price: parseFloat(item.priceEachAsNumber),
                category: item._product.product_type_english,
                brand: item.brandName,
                quantity,
              },
            ],
          },
        },
      })
    },

    gtm_trackCheckoutStart(cart) {
      if (cart?.items) {
        window.dataLayer.push({
          event: 'dynx_rem',
          google_tag_params: {
            ecomm_pagetype: 'cart',
            ecomm_prodid: cart.items.map((item) => item._product.sku),
            ecomm_totalvalue: parseFloat(cart.totals.grandTotalPrice),
          },
        })
        return this.gtm_trackCheckoutStep(cart, 'start', 'checkout')
      }
    },

    gtm_trackCheckoutStep(cart, step, event) {
      if (!cart) {
        return
      }
      event = event || 'checkoutOption'

      const option = {
        1: cart.shippingMethod, // shipping
        2: cart.paymentMethodsAvailable?.[cart.paymentMethod]
          ?.paymentMethodType, // payment
      }[step]

      window.dataLayer.push({
        event,
        ecommerce: {
          cartGrandTotal: cart.totals.grandTotalPrice,
          currencyCode: this.pricelist.currency.currency,
          checkout: {
            actionField: { step, option },
            products: cart.items.map((item) => ({
              name: item._product.name,
              id: item._product.sku,
              price: parseFloat(item.priceEachAsNumber),
              category: item._product.product_type_english,
              brand: item.brandName,
              quantity: item.quantity,
            })),
          },
        },
      })
    },

    gtm_trackSearch(searchQuery) {
      window.dataLayer.push({
        event: 'search',
        searchQuery,
      })
    },

    gtm_trackPurchase(order) {
      if (this.$cookies.get(order.order)) {
        console.warn(`Order ${order.order} purchase already tracked`)
        return
      }

      this.$cookies.set(order.order, 1, {
        maxAge: 3600,
      })

      let coupon = ''
      if (order?.discounts?.vouchers) {
        coupon = Object.values(order.discounts.vouchers)
          .map((voucher) => voucher.voucher)
          .join(', ')
      }

      const payload = {
        event: 'purchase',
        ecommerce: {
          currencyCode: order.currency,
          purchase: {
            actionField: {
              md5HashedEmail: order.md5HashedEmail,
              hashedEmail: order.hashedEmail,
              hashedFirstname: order.hashedFirstname,
              hashedLastname: order.hashedLastname,
              id: order.order, // Transaction ID. Required for purchases and refunds.
              affiliation: this.market.name,
              revenue: parseFloat(order.totals.grandTotalPriceAsNumber), // Total transaction value (incl. tax and shipping)
              tax: parseFloat(order.totals.grandTotalPriceTaxAsNumber),
              shipping: parseFloat(order.totals.shippingPriceAsNumber),
              revenueSubtotal: parseFloat(
                order.totals.grandTotalPriceAsNumber -
                  order.totals.grandTotalPriceTaxAsNumber -
                  order.totals.shippingPriceAsNumber
              ),
              coupon,
            },
            products: order.items.map((item) => ({
              id: item._product.sku,
              name: item._product.name,
              sku: item.ean,
              price: parseFloat(item.priceEachAsNumber),
              brand: item.brandName,
              quantity: item.quantity,
              category: item._product.product_type_english,
            })),
          },
        },
      }
      window.dataLayer.push(payload)
      window.dataLayer.push({
        event: 'dynx_rem',
        google_tag_params: {
          ecomm_pagetype: 'purchase',
          ecomm_prodid: order.items.map((item) => item._product.sku),
          ecomm_totalvalue: parseFloat(order.totals.grandTotalPriceAsNumber),
        },
      })

      this.elevateTrackPaymentNotification(
        order.items.map((item) => ({
          productKey: item.product,
          variantKey: item.item,
          quantity: item.quantity,
          sellingPrice: item.priceEachAsNumber,
        })),
        order.currency
      )
    },
    async elevateTrackPaymentNotification(lines, currency) {
      try {
        const marketData = await getElevateMarketData(
          (this.$config.elevateServiceUrl ?? '').trim(),
          this.marketId,
          this.currentLanguageCode
        )
        if (!marketData) {
          console.warn(
            "Elevate error: Couldn't fetch market data. Could not add payment notification."
          )
          return
        }
        const notificationData = {
          currency,
          lines,
          customerKey: this.elevateCustomerKey,
          sessionKey: this.elevateSessionKey,
          market: marketData.elevate_market_name,
        }
        await this.$backendApi.post(
          '/elevate/payment-notification',
          notificationData
        )
      } catch (e) {
        console.error(
          `Elevate error: An error occured when adding payment notifaction for order with lines: ${JSON.stringify(
            lines
          )}}.`,
          e
        )
      }
    },
  },
})
