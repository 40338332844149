export default {
  state () {
    return {
      newsletterPopupShown: false
    }
  },
  mutations: {
    showNewsletterPopup (state) {
      state.newsletterPopupShown = true
    },
    hideNewsletterPopup (state) {
      state.newsletterPopupShown  = false
    }
  },
  actions: {
    showNewsletterPopup ({ commit }) {
      commit('showNewsletterPopup')
    },
    hideNewsletterPopup ({ commit }) {
      commit('hideNewsletterPopup')
    }
  }
}
