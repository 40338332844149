<template>
  <div
    class="absolute top-0 left-0 w-full text-black bg-white pt-14"
    @mouseleave="closeMenu"
  >
    <div class="grid grid-cols-4 gap-10 px-10 my-10">
      <div class="flex flex-col col-span-1 gap-3">
        <mp-link
          v-for="({ label, link, bold }, index) in normalLinks"
          :key="`normallink=${index}`"
          class="
            flex
            gap-2
            overflow-hidden
            text-base
            tracking-wider
            transition-all
            cursor-pointer
            scrollbar-hide
            group
            hover:text-brandRed
          "
          :class="{ 'font-semibold': bold }"
          :to="$u(link)"
          @click.native="closeMenu"
        >
          <ArrowRight
            class="w-4 -ml-4 transition-all fill-brandRed group-hover:ml-0"
          />
          {{ label }}
        </mp-link>
      </div>
      <mp-link
        v-for="(
          { backgroundImage, tagLabel, label, link }, index
        ) in spotlightedLinks"
        :key="`spotlighted=${index}`"
        :to="$u(link)"
        class="
          relative
          flex
          items-center
          aspect-[2/3]
          justify-center
          h-full
          group
          col-span-1
          cursor-pointer
        "
        @click="closeMenu"
      >
        <Vue2Image
          :src="backgroundImage.filename"
          :width="400"
          class="
            absolute
            object-cover
            w-full
            h-full
            transition-all
            group-hover:brightness-75
          "
          :alt="backgroundImage.alt"
          :quality="60"
        />
        <div
          class="z-20 flex flex-col items-center justify-center font-semibold"
        >
          <span class="text-base font-normal text-white">{{
            tagLabel.length > 0 ? tagLabel : 'Shop'
          }}</span>
          <span class="text-3xl font-normal text-white uppercase text-center">{{
            label
          }}</span>
        </div>
      </mp-link>
    </div>
  </div>
</template>

<script>
import ArrowRight from '~/assets/icons/arrow-right.svg'

export default {
  name: 'DesktopSubMenu',
  components: {
    ArrowRight,
  },
  props: {
    content: {
      type: Array,
      default: null,
    },
  },
  computed: {
    spotlightedLinks: ({ content }) =>
      content.filter((item) => item.backgroundImage?.filename?.length > 0),
    normalLinks: ({ content }) =>
      content.filter(
        (item) =>
          item.backgroundImage?.filename === undefined ||
          item.backgroundImage?.filename?.length === 0 ||
          item.backgroundImage?.filename === null
      ),
  },
  methods: {
    closeMenu() {
      this.$store.dispatch('ui/desktop-submenu/hideDesktopSubmenu')
    },
  },
}
</script>
