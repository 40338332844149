<template>
  <button
    class="
      flex
      items-center
      justify-between
      px-6
      py-4
      hover:bg-lightGray
      lg:px-10
    "
    @click="$store.dispatch('ui/filter/openFilterSubmenu', { id: filterId })"
  >
    <span class="tracking-wider">{{ $t('Filter_Label_' + filterId) }}</span>
    <div class="flex items-center gap-4">
      <Button
        v-if="selectedFiltersLength > 0"
        class="z-20 text-sm tracking-wider underline underline-offset-4"
        bg="bg-transparent"
        font-weight="font-normal"
        px="px-0"
        py="py-0"
        @click="clearFilter"
        >{{ $t('Clear') }}</Button
      >
      <div
        v-if="selectedFiltersLength > 0"
        class="
          flex
          items-center
          justify-center
          w-6
          h-6
          text-sm
          font-semibold
          text-white
          bg-black
          rounded-full
        "
      >
        {{ selectedFiltersLength }}
      </div>
      <ChevronIcon class="rotate-[270deg] w-4 h-4" />
    </div>
  </button>
</template>
<script>
import ChevronIcon from '~/assets/icons/chevron-down-small.svg'

export default {
  name: 'ElevateFilterOption',
  components: {
    ChevronIcon,
  },
  props: {
    filterId: {
      type: String,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
    searchRequestId: {
      type: String,
      required: true,
    },
  },
  computed: {
    selectedFiltersLength: ({ filters }) =>
      filters.filter(({ selected }) => selected).length,
  },
  methods: {
    clearFilter(event) {
      this.$store.dispatch('elevate/clearFilter', {
        filterId: this.filterId,
        searchRequestId: this.searchRequestId,
      })
      event.stopPropagation()
    },
  },
}
</script>
