<template>
  <div>
    <div
      class="
        flex
        items-center
        justify-between
        gap-4
        px-4
        py-2
        lg:py-3 lg:px-10
        bg-lightGray
      "
    >
      <div class="flex items-center w-full gap-4">
        <SearchIcon
          class="w-4 h-4 cursor-pointer hover:opacity-60"
          @click="searchSubmit"
        />
        <form action class="w-full" @submit="searchSubmit">
          <input
            :id="inputid"
            ref="searchInput"
            v-model="searchQuery"
            type="search"
            class="
              w-full
              p-0
              text-base
              border-none
              outline-none
              appearance-none
              placeholder:text-darkGray
              bg-lightGray
              focus:ring-0
            "
            :placeholder="$t('Global_Search_Placeholder')"
            @change="queryAutoComplete"
          />
        </form>
      </div>
      <CloseIcon
        v-if="!hideClose"
        class="w-4 h-4 cursor-pointer hover:opacity-60"
        @click="hideSearch"
      />
    </div>
    <AutoComplete
      v-if="showAutoComplete"
      :search-query="searchQuery"
      @search="search"
    />
  </div>
</template>

<script>
import AutoComplete from './autocomplete/AutoComplete.vue'
import SearchIcon from '~/static/images/search.svg'
import CloseIcon from '~/static/images/cross.svg'

export default {
  name: 'SearchInput',
  components: {
    SearchIcon,
    CloseIcon,
    AutoComplete,
  },
  props: {
    inputid: {
      type: String,
      default: '',
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchQuery: this.$route.query.q || '',
    }
  },
  computed: {
    autoCompleteResponse: ({ $store }) =>
      $store.state.elevate.autoCompleteResponse,
    showAutoComplete: ({ autoCompleteResponse }) =>
      autoCompleteResponse?.phraseSuggestions?.length ||
      autoCompleteResponse?.productSuggestions?.length,
  },
  watch: {
    searchQuery(newQuery) {
      this.queryAutoComplete(newQuery)
    },
  },
  mounted() {
    if (this.$refs.searchInput && !this.hideClose) {
      this.$refs.searchInput.focus()
    }
    this.initialQuery()
  },
  methods: {
    initialQuery() {
      this.queryAutoComplete(this.searchQuery)
    },
    hideSearch() {
      this.$store.dispatch('ui/search/hideSearch')
    },
    getAction() {
      return this.$u('search')
    },
    async queryAutoComplete(query) {
      if (typeof query === 'string')
        await this.$store.dispatch('elevate/autoComplete', query)
    },
    searchSubmit(e) {
      e.preventDefault()
      if (this.searchQuery) {
        this.search(this.searchQuery)
      }
    },
    search(query) {
      const newPath = `${this.getAction()}?q=${query}`
      this.$router.push(newPath)
      this.$store.dispatch('elevate/clearAutoComplete')
      this.hideSearch()
    },
  },
}
</script>
