<template>
  <header class="relative flex flex-col mb-6">
    <h3
      class="mt-12 text-3xl font-semibold tracking-widest text-center uppercase"
    >
      {{ heading }}
    </h3>
    <button
      class="
        absolute
        top-0
        right-0
        pb-[1px]
        text-sm
        border-b border-black border-solid
        tracking-wider
        hover:opacity-60
        transition-opacity
      "
      type="button"
      @click="$emit('close')"
    >
      {{ $t('Global_Close') }}
    </button>
  </header>
</template>

<script>
export default {
  name: 'ModalHeader',
  props: {
    heading: {
      type: String,
      default: '',
    },
  },
}
</script>
