<template>
  <div
    v-if="phraseSuggestions && phraseSuggestions.length"
    class="flex flex-col gap-4 lg:gap-8 w-full"
  >
    <h1 v-if="!searchQuery.length" class="lg:text-2xl text-xl font-semibold">
      {{ $t('Popular_Searches') }}
    </h1>
    <div
      class="flex flex-col overflow-x-scroll lg:overflow-hidden scrollbar-hide"
    >
      <a
        v-for="(phrase, phraseIndex) in phraseSuggestions"
        :key="`phraseSuggestion-${phraseIndex}`"
        class="
          cursor-pointer
          text-medium
          w-full
          font-normal
          p-2
          text-black text-center
          whitespace-nowrap
          lg:whitespace-normal
          flex
        "
        @click="$emit('search', phrase.q)"
      >
        <span
          v-for="(part, partIndex) in getDisplayPhraseParts(phrase.highlighted)"
          :key="`phrasePart-${part.text}-${partIndex}`"
          :class="{ 'font-bold': part.isBold }"
          v-html="part.text"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhraseSuggestions',
  props: {
    searchQuery: {
      type: String,
      default: '',
    },
  },
  computed: {
    phraseSuggestions: ({ $store }) =>
      $store.state.elevate.autoCompleteResponse?.phraseSuggestions,
  },
  methods: {
    // This method splits up the phrase into parts that should be bold or not based on how elevate send the phrase-suggestions, preparing the phrase to be displayed.
    // For example, one phrase suggestion could be "{gi}da pant", "gi" is what the user already has written (which should be bold) and the reset is the autocomplete-suggestions, which should not be bold.
    getDisplayPhraseParts(phrase) {
      const parts = phrase
        .split(/(\{[^}]+\})/)
        .filter((s) => s !== '')
        .map((part) => {
          if (part.startsWith('{') && part.endsWith('}')) {
            return {
              text: part.slice(1, -1).replace(/ /g, '&nbsp;'),
              isBold: true,
            }
          } else {
            return {
              text: part.replace(/ /g, '&nbsp;'),
              isBold: false,
            }
          }
        })

      // Capitalize first letter
      if (parts[0] && parts[0].text)
        parts[0].text =
          parts[0].text.charAt(0).toUpperCase() + parts[0].text.slice(1)

      return parts
    },
  },
}
</script>
