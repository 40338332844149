import {
  elevateTouchpoint,
  getCurrentCurrency,
  getParams,
  getPresentCustom,
  getPriceId,
  getStoreId,
  mapProduct,
  mapSearchResponse,
} from '../utils/elevate'

export default {
  namespaced: true,
  state() {
    return {
      searchResponses: {},
      autoCompleteResponse: undefined,
      customerKey: undefined,
      sessionKey: undefined,
      cartPageRecommendations: undefined,
      productPageRecommendations: undefined,
    }
  },
  mutations: {
    setCustomerKey(state, customerKey) {
      state.customerKey = customerKey
    },
    setSessionKey(state, sessionKey) {
      state.sessionKey = sessionKey
    },
    setCartPageRecommendations(state, cartPageResponse) {
      state.cartPageRecommendations = cartPageResponse?.cartRecommendations
    },
    setProductPageRecommendations(state, productPageResponse) {
      state.productPageRecommendations =
        productPageResponse?.productRecommendations
    },
    setAutoCompleteResponse(state, autoCompleteResponse) {
      state.autoCompleteResponse = autoCompleteResponse
    },
    clearAutoComplete(state) {
      state.autoCompleteResponse = undefined
    },
    setSearchResponse(state, data) {
      const { searchResponseData, searchRequestId } = data
      state.searchResponses = {
        ...state.searchResponses,
        [searchRequestId]: searchResponseData,
      }
    },
    addFilter(state, data) {
      const { searchRequestId, id, value } = data
      const filters = state.searchResponses[searchRequestId].query.filters || {}
      filters[id] = (filters[id] || []).concat(value)
      state.searchResponses[searchRequestId].query.filters = filters
    },
    removeFilter(state, data) {
      const { searchRequestId, id, value } = data
      const filters = state.searchResponses[searchRequestId].query.filters
      filters[id] = filters[id].filter((v) => v !== value)
      state.searchResponses[searchRequestId].query.filters = filters
    },
    clearFilter(state, data) {
      const { searchRequestId, filterId } = data
      const filters = state.searchResponses[searchRequestId].query.filters
      filters[filterId] = []
      state.searchResponses[searchRequestId].query.filters = filters
    },
    resetAllFilters(state, searchRequestId) {
      state.searchResponses[searchRequestId].query.filters = {}
    },
    resetSkipValue(state, searchRequestId) {
      state.searchResponses[searchRequestId].query.page = 1
    },
    updateSearchResponseQueryloadMore(state, searchRequestId) {
      const searchResponse = state.searchResponses[searchRequestId]
      if (searchResponse) {
        const query = searchResponse.query
        query.page = query.page + 1
      }
    },
    addMoreProducts(state, data) {
      const { searchRequestId, newSearchResponse } = data
      const searchResponse = state.searchResponses[searchRequestId]
      const mappedProducts = searchResponse.mappedProducts.concat(
        newSearchResponse.mappedProducts
      )

      state.searchResponses[searchRequestId] = {
        ...searchResponse,
        mappedProducts,
        preparedFilters: newSearchResponse.preparedFilters,
        hasMoreProducts: searchResponse.productCount > mappedProducts.length,
      }
    },
  },
  actions: {
    async productPage({ commit, rootGetters }, input) {
      const { params, limit, recommendationAlgorithm } = input
      const queryParams = {
        touchpoint: elevateTouchpoint(),
        presentCustom: getPresentCustom(),
        presentPrices: getPriceId(rootGetters),
        stores: getStoreId(rootGetters),
        ...params,
      }
      const productRules = `rule incl custom.centra_markets { "${getStoreId(
        rootGetters
      )}" }`
      const requestBody = {
        recommendationLists: [
          {
            id: 'product-recs',
            algorithm: recommendationAlgorithm || 'TOP_PRODUCTS',
            limit: limit || 10,
            productRules,
          },
        ],
      }

      try {
        const elevateClient = await this.$elevateApi()
        const productPageResult = await queryElevateProductPage(
          queryParams,
          requestBody,
          elevateClient
        )
        commit('setProductPageRecommendations', {
          productRecommendations:
            productPageResult?.data?.recommendationLists[0]?.productGroups.map(
              (productGroup) =>
                mapProduct(productGroup, getCurrentCurrency(rootGetters))
            ),
        })
      } catch (e) {
        console.error(e)
      }
    },
    async cartPage({ commit, rootGetters }, input) {
      const { params, limit } = input
      const queryParams = {
        touchpoint: elevateTouchpoint(),
        presentCustom: getPresentCustom(),
        presentPrices: getPriceId(rootGetters),
        stores: getStoreId(rootGetters),
        ...params,
      }
      const productRules = `rule incl custom.centra_markets { "${getStoreId(
        rootGetters
      )}" }`
      const requestBody = {
        recommendationLists: [
          {
            id: 'cart-recs',
            algorithm: 'CART',
            limit: limit || 10,
            productRules,
          },
        ],
      }
      try {
        const elevateClient = await this.$elevateApi()
        const cartPageResult = await queryElevateCartPage(
          queryParams,
          requestBody,
          elevateClient
        )
        commit('setCartPageRecommendations', {
          cartRecommendations:
            cartPageResult?.data?.recommendationLists[0]?.productGroups.map(
              (productGroup) =>
                mapProduct(productGroup, getCurrentCurrency(rootGetters))
            ),
        })
      } catch (e) {
        console.error(e)
      }
    },
    async autoComplete({ commit, rootGetters }, query) {
      const params = {
        q: query,
        touchpoint: elevateTouchpoint(),
        presentCustom: getPresentCustom(),
        presentPrices: getPriceId(rootGetters),
        stores: getStoreId(rootGetters),
      }
      try {
        const elevateClient = await this.$elevateApi()
        const autoCompleteResponse = await elevateClient.request({
          url: 'storefront/v3/queries/autocomplete',
          method: 'GET',
          params,
        })
        commit('setAutoCompleteResponse', {
          phraseSuggestions: autoCompleteResponse?.data?.phraseSuggestions,
          productSuggestions: autoCompleteResponse?.data?.productSuggestions
            ?.map((product) =>
              mapProduct(product, getCurrentCurrency(rootGetters))
            )
            .filter((p) => p !== undefined),
        })
      } catch (e) {
        commit('clearAutoComplete')
      }
    },
    clearAutoComplete({ commit }) {
      commit('clearAutoComplete')
    },
    loadMore({ commit, dispatch, state }, { searchRequestId }) {
      commit('updateSearchResponseQueryloadMore', searchRequestId)
      const searchResponse = state.searchResponses[searchRequestId]
      if (!searchResponse) {
        console.warn(`No search response found for ${searchRequestId}`)
        return
      }
      const query = searchResponse.query
      return dispatch('addMoreProducts', {
        searchRequestId,
        query,
      })
    },
    async addMoreProducts({ commit, rootGetters }, { query, searchRequestId }) {
      try {
        const params = getParams(rootGetters, query)
        const elevateClient = await this.$elevateApi()
        const newSearchResponse = await queryElevate(
          elevateClient,
          query,
          params
        )
        if (newSearchResponse?.data) {
          const newMappedSearchResponse = mapSearchResponse(
            newSearchResponse.data,
            getCurrentCurrency(rootGetters)
          )
          commit('addMoreProducts', {
            searchRequestId,
            newSearchResponse: newMappedSearchResponse,
          })
        }
      } catch (e) {
        console.warn(
          `Could not load more products for Elevate. Query: ${query}`
        )
      }
    },
    toggleFilter({ commit, dispatch, state }, { searchRequestId, filter }) {
      const searchResponse = state.searchResponses[searchRequestId]
      if (!searchResponse) {
        console.warn(`No search response found for ${searchRequestId}`)
        return
      }

      const query = searchResponse.query
      const isFilterActive = !!query.filters?.[filter.id]?.find(
        (v) => v === filter.value
      )

      commit('resetSkipValue', searchRequestId)

      if (isFilterActive) {
        commit('removeFilter', {
          searchRequestId,
          id: filter.id,
          value: filter.value,
        })
      } else {
        commit('addFilter', {
          searchRequestId,
          id: filter.id,
          value: filter.value,
        })
      }

      return dispatch('search', {
        searchRequestId,
        query,
      })
    },
    clearFilter({ commit, dispatch, state }, { searchRequestId, filterId }) {
      const searchResponse = state.searchResponses[searchRequestId]
      if (!searchResponse) {
        console.warn(`No search response found for ${searchRequestId}`)
        return
      }
      commit('resetSkipValue', searchRequestId)
      commit('clearFilter', { searchRequestId, filterId })
      const query = searchResponse.query
      return dispatch('search', {
        searchRequestId,
        query,
      })
    },
    resetAllFilters({ commit, dispatch, state }, { searchRequestId }) {
      const searchResponse = state.searchResponses[searchRequestId]
      if (!searchResponse) {
        console.warn(`No search response found for ${searchRequestId}`)
        return
      }
      commit('resetSkipValue', searchRequestId)
      commit('resetAllFilters', searchRequestId)
      const query = searchResponse.query
      return dispatch('search', {
        searchRequestId,
        query,
      })
    },
    async search({ commit, rootGetters }, { query, searchRequestId }) {
      const params = getParams(rootGetters, query)
      try {
        const elevateClient = await this.$elevateApi()
        const searchResponse = await queryElevate(elevateClient, query, params)
        if (searchResponse)
          commit('setSearchResponse', {
            searchResponseData: {
              ...mapSearchResponse(
                searchResponse.data,
                getCurrentCurrency(rootGetters)
              ),
              query,
              isElevate: true,
            },
            searchRequestId,
          })
      } catch (e) {
        console.error(e)
      }
    },
  },
}
/**
 * Returns the elevate searchresponse for a given query
 * @param {*} elevateClient - Elevate client
 * @param {object} query - Query object for elevate
 * @param {object} params - Params object for elevate
 * @returns
 */
const queryElevate = async (elevateClient, query, params) => {
  let searchResponse
  if (query.products) {
    searchResponse = await queryElevateProducts(
      { ...params, pageReference: 'product-search' },
      query.products,
      elevateClient
    )
  } else if (query.search) {
    searchResponse = await queryElevateSearchPage(
      { ...params, q: query.search },
      elevateClient
    )
  } else if (query.elevatePageId) {
    searchResponse = await queryElevatePage(
      { ...params, pageReference: query.elevatePageId },
      elevateClient
    )
  } else {
    console.warn('Not a valid Elevate query')
  }
  return searchResponse
}

/**
 * Sends a GET request for an Elevate search page for the specific query that are requested
 *
 * @param {Object} params - params for the request
 * @param {*} $elevateApi - Elevate api
 * @returns
 */
const queryElevateSearchPage = async (params, elevateClient) => {
  return await elevateClient.request({
    url: 'storefront/v3/queries/search-page',
    method: 'GET',
    params,
  })
}

/**
 * Sends a POST request for an Elevate landing page for the specific products that are requested
 *
 * @param {Object} params - params for the request
 * @param {Array<string>} productIds - product ids that are to be fetched
 * @param {*} $elevateApi - Elevate Api
 * @returns
 */
const queryElevateProducts = async (params, productIds, elevateClient) => {
  const productKeys = productIds.map((id) => `"${id}"`).join(' ')
  const resultString = `rule incl product_key { ${productKeys} }`
  const data = {
    primaryList: {
      include: true,
      productRules: resultString,
    },
  }

  return await elevateClient.request({
    url: 'storefront/v3/queries/landing-page',
    method: 'POST',
    params,
    data,
  })
}

/**
 * Sends a GET request for an Elevate landing page for the specific page that is requested
 *
 * @param {Object} params - params for the request
 * @param {*} $elevateApi - Elevate Api
 * @returns
 */
const queryElevatePage = async (params, elevateClient) => {
  return await elevateClient.request({
    url: 'storefront/v3/queries/landing-page',
    method: 'GET',
    params,
  })
}

/**
 * Sends a POST request for an Elevate cart page for the current cart selection
 *
 * @param {Object} params - params for the request
 * @param {Object} body - body for the request
 * @param {*} $elevateClient - Elevate Api client
 * @returns
 */
const queryElevateCartPage = async (params, body, elevateClient) => {
  return await elevateClient.request({
    url: 'storefront/v3/queries/cart-page',
    method: 'POST',
    params,
    data: body,
  })
}

/**
 * Sends a POST request for an Elevate product page for bases on the current PDP-product
 *
 * @param {Object} params - params for the request
 * @param {Object} body - body for the request
 * @param {*} $elevateClient - Elevate Api client
 * @returns
 */
const queryElevateProductPage = async (params, body, elevateClient) => {
  return await elevateClient.request({
    url: 'storefront/v3/queries/product-page',
    method: 'POST',
    params,
    data: body,
  })
}
