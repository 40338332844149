export default {
  actions: {
    closeAll({ dispatch }) {
      dispatch('ui/overlay/hideOverlay', null, { root: true })
      dispatch('ui/country-selector/hideCountrySelector', null, { root: true })
      dispatch('ui/cart-sidebar/hideCartSidebar', null, { root: true })
      dispatch('ui/mobile-sidebar/hideMobileSidebar', null, { root: true })
      dispatch('ui/search/hideSearch', null, { root: true })
      dispatch('ui/restock-notification/hide', null, { root: true })
      dispatch('ui/filter/closeFilterSubmenu', null, { root: true })
      dispatch('ui/product-bundle-modal/hideProductBundleModal', null, {
        root: true,
      })
    },
  },
}
