export default {
  namespaced: true,
  state() {
    return {
      sizeSelectorModalOpen: false,
      productId: null,
      selectorOrigin: null,
    }
  },
  mutations: {
    show(state, productId) {
      state.productId = productId
      state.miniCartOpen = false
      state.mobileMenuOpen = false
      state.mobileProductFilterOpen = false
      state.hotspotProductsOpen = null
      state.productRestockModalOpen = false
      state.sizeSelectorOpen = false
      state.countrySelectorOpen = false
      state.modalOpen = true
      state.sizeSelectorModalOpen = true
      state.overlayIndex = 11
    },
    hide(state) {
      state.modalOpen = false
      state.sizeSelectorModalOpen = false
      state.overlayIndex = 0
    },
    setOriginToButton(state) {
      state.selectorOrigin = 'button'
    },
    setOriginToNull(state) {
      state.selectorOrigin = null
    },
  },
  actions: {
    showSizeSelectorMobile({ commit }, productId) {
      commit('show', productId)
    },
    hideSizeSelectorMobile({ commit }) {
      commit('hide')
    },
    setOriginToButton({ commit }) {
      commit('setOriginToButton')
    },
    setOriginToNull({ commit }) {
      commit('setOriginToNull')
    },
  },
}
