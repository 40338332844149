<template>
  <CentraSearchController
    v-if="swipeable"
    :search-query="searchQuery"
    v-bind="$attrs"
  />
  <div v-else class="min-h-screen">
    <CentraSearchController :search-query="searchQuery" v-bind="$attrs" />
  </div>
</template>

<script>
/**
 * This is the component that we expose in storyblok
 */
export default {
  name: 'EQPECentraSearchController',
  props: {
    centraCategoryId: {
      type: Array,
      default: () => [],
    },
    centraMpGender: {
      type: Array,
      default: () => [],
    },
    swipeable: {
      type: Boolean,
      default: false,
    },
    centraMpSortOrder: {
      type: String,
      default: 'desc',
    },
    centraMpSortBy: {
      type: String,
      default: '',
    },
  },
  computed: {
    searchQuery() {
      const query = {}
      if (this.centraCategoryId) {
        query.categories = this.centraCategoryId
      }
      if (
        Array.isArray(this.centraMpGender) &&
        this.centraMpGender.length > 0
      ) {
        query.mp_gender = this.centraMpGender
        query.__translateFieldsFrom = [
          {
            fromLanguageCode: 'en-US', // storyblok's gender selector is in english
            fieldName: 'mp_gender',
          },
        ]
      }
      if (this.centraMpSortBy && this.centraMpSortOrder) {
        query.sortOrder = [
          {
            field: this.centraMpSortBy,
            order: this.centraMpSortOrder,
          },
        ]
      }
      return query
    },
  },
}
</script>
