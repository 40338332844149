<template>
  <div ref="viewMore" class="flex flex-col items-center w-full col-span-12">
    <span class="mb-2">{{
      $t('You have viewed {maxViewedProducts} of {totalProducts} products', {
        maxViewedProducts,
        totalProducts,
      })
    }}</span>
    <ProgressBar class="mb-10" :progress="ifTotalProductsIsBiggerThanViewed
        ? 100
        : (totalProcessedProducts / totalProducts) * 100
      " />
    <a v-if="!ifTotalProductsIsBiggerThanViewed" ref="viewMoreLink" :href="generateNextPageLink()"
      @click.prevent="viewMoreHelper">
      <Button v-if="!ifTotalProductsIsBiggerThanViewed && !useObserver" bg="bg-black" color="text-white" py="py-4"
        class="tracking-widest uppercase min-w-[295px]">
        {{ $t('View_More_Button') }}
      </Button>
    </a>
  </div>
</template>
<script>
export default {
  name: 'ViewMore',
  props: {
    viewedProducts: {
      type: Number,
      required: true,
    },
    totalProducts: {
      type: Number,
      required: true,
    },
    useObserver: {
      type: Boolean,
      required: false,
      default: false,
    },
    limit: {
      type: String,
      default: '20',
    },
  },
  data() {
    return {
      observer: null,
      nextPage: parseInt(this.$route.query.page) + 1 || 2,
      currentPage: parseInt(this.$route.query.page) || 1,
    }
  },
  computed: {
    ifTotalProductsIsBiggerThanViewed: ({
      totalProducts,
      totalProcessedProducts,
    }) => totalProcessedProducts >= totalProducts,
    // Processed products are the products are already viewed, both through load more and pagination
    // Pagination is used for google crawling of all products and load more is the user interaction
    totalProcessedProducts: ({ viewedProducts, limit, currentPage }) =>
      viewedProducts + parseInt(limit) * (currentPage - 1),
    viewMoreElement: ({ $refs }) => $refs.viewMore,
    maxViewedProducts: ({
      ifTotalProductsIsBiggerThanViewed,
      totalProducts,
      totalProcessedProducts,
    }) =>
      ifTotalProductsIsBiggerThanViewed
        ? totalProducts
        : totalProcessedProducts,
  },
  mounted() {
    const { useObserver, startObserver } = this

    if (useObserver) {
      startObserver()
    }
  },
  methods: {
    generateNextPageLink() {
      const queryParams = { ...this.$route.query, page: this.nextPage }
      return `?${new URLSearchParams(queryParams).toString()}`
    },
    startObserver() {
      const { createObserver, viewMoreElement } = this

      createObserver()
      this.observer.observe(viewMoreElement)
    },
    createObserver() {
      if ('IntersectionObserver' in window) {
        this.observer = new IntersectionObserver(
          (entries) => {
            entries.forEach(({ isIntersecting }) => {
              if (isIntersecting) {
                this.$emit('view-more')
              }
            })
          },
          { threshold: 1.0 }
        )
      }
    },
    viewMoreHelper(e) {
      e.preventDefault()
      this.nextPage = parseInt(this.nextPage) + 1
      this.$emit('view-more')
    },
  },
}
</script>
