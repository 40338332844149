<template>
  <div
    class="
      fixed
      top-0
      right-0
      z-[110]
      flex flex-col
      w-screen
      h-screen
      p-6
      px-6
      py-10
      bg-white
      lg:max-w-md lg:p-10
    "
  >
    <DialogHeader slot="header" class="flex justify-between mb-10">
      <h3 class="text-xl font-semibold tracking-wider">
        {{ $t('CountrySelector') }}
      </h3>
      <Button
        class="text-sm tracking-wider underline underline-offset-4"
        font-weight="font-normal"
        px="px-0"
        py="py-0"
        bg="bg-white"
        @click="$store.dispatch('ui/country-selector/hideCountrySelector')"
        >{{ $t('Global_Close') }}</Button
      >
    </DialogHeader>

    <div class="flex flex-col gap-8" :class="{ loading: loading }">
      <div class="flex flex-col gap-6">
        <CountrySelectorSidebarInput :label="$t('Global_Country')">
          <select
            v-model="countryCode"
            class="
              w-full
              px-4
              py-3
              transition-all
              border border-black border-solid
              cursor-pointer
            "
            @change="countryChanged"
          >
            <option
              v-for="(country, index) in countries"
              :key="index"
              :value="country.country"
              :selected="country.country === countryCode"
            >
              {{ country.name }}
            </option>
          </select>
        </CountrySelectorSidebarInput>

        <CountrySelectorSidebarInput :label="$t('Global_Language')">
          <select
            v-model="languageCode"
            class="
              w-full
              px-4
              py-3
              transition-all
              border border-black border-solid
              cursor-pointer
            "
            @change="countryLanguageChanged"
          >
            <option
              v-for="(language, index) in languages"
              :key="index"
              :value="language.language"
            >
              {{ language.name }}
            </option>
          </select>
        </CountrySelectorSidebarInput>
      </div>
      <div class="flex flex-col gap-4">
        <Button
          class="tracking-widest uppercase"
          bg="bg-black"
          color="text-white"
          @click="changeSettings"
        >
          {{ $t('Country_Selector_Sidebar_Change') }}
        </Button>
        <Button
          class="tracking-wider text-black underline bg-white"
          font-weight="font-normal"
          @click="closeSidebar"
        >
          {{ $t('Country_Selector_Sidebar_Keep_Settings') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import CountrySelectorSidebarInput from '~/components/country-selector-sidebar/CountrySelectorSidebarInput.vue'

export default {
  name: 'CountrySelectorSidebar',
  components: {
    CountrySelectorSidebarInput,
  },
  props: {
    markets: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },

    siteLangSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      countryCode: this.$store.getters['frontend/currentCountryCode'],
      languageCode: this.$store.getters['frontend/currentLanguageCode'],
      loading: false,
    }
  },
  computed: {
    currentCountry() {
      return this.$store.state.frontend.countries.find(
        (x) => x.country === this.countryCode.toUpperCase()
      )
    },
    languages() {
      return this.$store.state.frontend.languages
    },
  },
  methods: {
    countryChanged() {
      this.languageCode =
        this.currentCountry.language ?? this.$config.defaultLocale
      return this.countryLanguageChanged()
    },

    async countryLanguageChanged() {
      await this.$store.dispatch('storyblok/fetchCartUSPs', {
        countryCode: this.countryCode,
        languageCode: this.languageCode,
      })
    },

    /**
     * Triggered when 'close' event is emitted by the ModalHeader
     */
    closeSidebar() {
      this.$store.dispatch('ui/country-selector/hideCountrySelector')
    },

    getCurrentSlug(prefix) {
      const slugParts = this.$route.path.split('/')
      slugParts[1] = prefix
      return slugParts.join('/')
    },

    changeSettings() {
      const countryObject = this.$store.getters[
        'frontend/countriesSorted'
      ].find((x) => x.country === this.countryCode)
      const slugParts = [this.countryCode]
      if (this.languageCode !== countryObject.language) {
        slugParts.push(this.languageCode)
      }
      const slug = slugParts.join('-').toLowerCase()
      window.location.href = this.getCurrentSlug(slug)
    },
  },
}
</script>
