<template>
  <transition
    :enter-active-class="`transition ${duration} ${delay}`"
    enter-class="translate-y-full"
    enter-to-class="translate-y-0"
    leave-active-class="transition duration-300"
    leave-class="translate-y-0"
    leave-to-class="translate-y-full"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'SlideInTransitionTop',
  props: {
    duration: {
      type: String,
      default: 'duration-500',
    },
    delay: {
      type: String,
      default: '',
    },
  },
}
</script>
