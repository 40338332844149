<template>
  <div
    v-if="logos.length"
    data-footer-logos
    class="flex justify-center gap-3 lg:gap-16"
  >
    <Vue2Image
      v-for="({ image, alt }, index) in logos"
      :key="`footer_logo-${index}`"
      class="h-4"
      :src="image.filename"
      :alt="alt"
      :quality="60"
    />
  </div>
</template>
<script>
export default {
  name: 'FooterLogos',
  props: {
    logos: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
