export default {
  state() {
    return {
      searchOpen: false,
      searchAutocompleteResult: null,
    }
  },
  mutations: {
    searchAutocompleteResult(state, result) {
      state.searchAutocompleteResult = result
    },
    showSearch(state) {
      state.searchOpen = true
    },
    hideSearch(state) {
      state.searchOpen = false
    },
  },
  actions: {
    setSearchAutocompleteResult({ commit }, result) {
      commit('searchAutocompleteResult', result)
    },
    showSearch({ dispatch, commit }) {
      dispatch('ui/close-all/closeAll', null, { root: true })
      dispatch('ui/overlay/showOverlay', 10, { root: true })
      commit('showSearch')
    },
    hideSearch({ dispatch, commit }) {
      dispatch('ui/overlay/hideOverlay', null, { root: true })
      commit('hideSearch')
    },
  },
}
