<template>
  <component
    :is="productListComponent"
    v-if="searchResponse"
    :search-request-id="searchRequestId"
    :search-response="searchResponse"
    :limit-products="pageSize"
    v-bind="$attrs"
    @reset-page="resetPage"
  />
</template>

<script>
import { crc32 } from '@made-people/functions-crc32'
import { ELEVATE_QUERY_LIMIT_DEFAULT } from '../../utils/elevate'

export default {
  name: 'ElevateSearchController',
  props: {
    productListComponent: {
      type: String,
      default: 'ProductList',
    },
    searchQuery: {
      type: Object,
      default: () => {},
    },
    pageSize: {
      type: String,
      default: '20',
    },
  },
  computed: {
    initialQuery() {
      const query = {
        ...this.searchQuery,
        limit: this.pageSize || ELEVATE_QUERY_LIMIT_DEFAULT,
        page: parseInt(this.$route.query.page) || 1,
      }
      if (this.elevateCategoryId) {
        query.categories = [this.elevateCategoryId]
      }
      return query
    },
    searchRequestId() {
      return crc32(JSON.stringify(this.initialQuery))
    },
    searchResponse() {
      return this.$store.state.elevate.searchResponses[this.searchRequestId]
    },
  },
  watch: {
    elevateCategoryId() {
      this.search()
    },
    searchQuery() {
      this.search()
    },
    $route() {
      this.search()
    },
  },
  serverPrefetch() {
    return this.search()
  },

  mounted() {
    this.search()
  },
  methods: {
    async resetPage() {
      await this.$router.push({ path: this.$route.path })
    },
    search() {
      if (this.searchResponse?.mappedProducts?.length) {
        return
      }
      return this.$store
        .dispatch('elevate/search', {
          query: this.initialQuery,
          searchRequestId: this.searchRequestId,
        })
        .then(() =>
          this.$emit('elevate-search-loading-success', {
            searchRequestId: this.searchRequestId,
          })
        )
        .catch(() =>
          this.$emit('elevate-search-loading-failed', {
            searchRequestId: this.searchRequestId,
          })
        )
    },
  },
}
</script>
