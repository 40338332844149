<template>
  <div class="lg:hidden">
    <!-- Mobile -->
    <SizeSelectorMobileController
      v-if="!quickShopProduct.isOnesize"
      :product="quickShopProduct"
      :sizeguide="false"
      @updateSize="setSizeMobile"
    />
    <Button
      v-if="quickShopProduct.isOnesize || selectedSize"
      class="
        w-full
        py-3
        tracking-widest
        uppercase
        duration-300
        h-max
        lg:py-4
        disabled:bg-lightGray disabled:text-black
      "
      :class="{
        'animate-pulse disabled:bg-black disabled:text-white': loading,
        '-translate-y-full opacity-0 select-none': !selectedSize,
        'translate-y-2 opacity-100 select-all': selectedSize,
      }"
      bg="bg-black"
      color="text-white"
      :disabled="loading"
      @click="selectedSize && addToCart()"
    >
      {{
        selectedSize
          ? selectedSize.quantity > 0
            ? loading
              ? $t('Related_Onesize_Adding')
              : $t('Product_Details_Add_To_Cart')
            : $t('Global_Out_Of_Stock')
          : $t('Global_Choose_Size')
      }}
    </Button>
  </div>
</template>

<script>
import ElevateMixin from '../../mixins/ElevateMixin'
import SizeSelectorMobileController from '../product-details/SizeSelectorMobileController.vue'

export default {
  name: 'ProductCardSizeSelector',
  components: { SizeSelectorMobileController },
  mixins: [ElevateMixin],
  props: {
    quickShopProduct: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedSize: false,
    completed: false,
    loading: false,
  }),
  mounted() {
    this.selectedSize = this.quickShopProduct.isOnesize
      ? this.quickShopProduct.sizeOptions[0]
      : false
  },
  methods: {
    setSizeMobile(size) {
      this.selectedSize = size
    },
    addToCart() {
      if (this.selectedSize) {
        this.loading = true
        this.$addToCart(this.selectedSize.itemId)
          .catch((error) => console.log(error))
          .finally(() => {
            this.loading = false
            this.$store.dispatch('ui/toggleMiniCart')
            this.addToCartNotification(
              this.quickShopProduct.ticket,
              this.quickShopProduct.product
            )
          })
      }
    },
  },
}
</script>
