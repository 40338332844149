<template>
  <button
    class="flex items-center justify-between px-6 py-4 hover:bg-lightGray lg:px-10"
    @click="$store.dispatch('ui/filter/openFilterSubmenu', { id: field })"
  >
    <span class="tracking-wider">{{ label }}</span>
    <div v-if="!submenuIsOpen" class="flex items-center gap-4">
      <Button
        v-if="activeFilters > 0"
        class="z-20 text-sm tracking-wider underline underline-offset-4"
        bg="bg-transparent"
        font-weight="font-normal"
        px="px-0"
        py="py-0"
        >Clear</Button
      >
      <div
        v-if="activeFilters > 0"
        class="flex items-center justify-center w-6 h-6 text-sm font-semibold text-white bg-black rounded-full "
      >
        {{ activeFilters }}
      </div>
      <ChevronIcon class="rotate-[270deg] w-4 h-4" />
    </div>
  </button>
</template>
<script>
import ChevronIcon from '~/assets/icons/chevron-down-small.svg'

export default {
  name: 'FilterOption',
  components: {
    ChevronIcon,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    selected: {
      type: Array,
      required: false,
      default: () => [],
    },
    options: {
      type: Array,
      required: true,
    },
    submenuIsOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    position: {
      type: Number,
      required: true,
    },
  },
  computed: {
    activeFilters: ({ options }) =>
      options.filter(({ active }) => active).length,
  },
}
</script>
