<template>
  <div>
    <div
      v-if="isUpsellBlock"
      ref="wrapper"
      class="relative flex overflow-x-scroll scrollbar-hide snap-x snap-mandatory"
      :class="classObject"
      @scroll="scrolling"
    >
      <slot></slot>
    </div>
    <section v-else class="relative grid max-w-full grid-cols-12">
      <div
        ref="wrapper"
        :class="{
          'flex col-span-12 overflow-x-scroll scrollbar-hide snap-x gap-x-2 lg:gap-x-6': true,
          'justify-center': items.length < 3,
          'justify-start lg:justify-center': items.length === 3,
          'justify-start': items.length > 3,
        }"
        @scroll="scrolling"
      >
        <slot></slot>
      </div>
      <client-only>
        <div
          v-if="swipeableItemsLength > 4 && !hideProgressBar"
          class="h-[2px] col-start-3 col-end-11 lg:col-start-4 lg:col-end-10 mt-4 lg:mt-16 rounded bg-lightGray overflow-hidden scrollbar-hide"
        >
          <div
            class="h-full bg-brandRed"
            :style="`width:${(currentX / wrapperW) * 100}%`"
          />
        </div>
      </client-only>
    </section>
    <template v-if="isUpsellBlock">
      <client-only>
        <div
          class="block lg:hidden h-[2px] mt-4 w-7/12 self-center rounded bg-lightGray overflow-hidden scrollbar-hide absolute m-auto left-0 right-0"
        >
          <div
            class="h-full bg-brandRed"
            :style="`width:${(currentX / wrapperW) * 100}%`"
          />
        </div>
      </client-only>
    </template>
  </div>
</template>
<script>
export default {
  name: 'SwipeableWrapper',
  props: {
    items: {
      type: Array,
      required: true,
    },
    hideProgressBar: {
      type: Boolean,
      required: false,
      default: false,
    },
    variant: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      currentX: 0,
      wrapperW: 0,
      containerWidth: 0,
    }
  },
  computed: {
    swipeableItemsLength() {
      return this.items?.length
    },
    classObject() {
      return {
        'w-full gap-4 lg:w-auto lg:max-w-[700px] xl:max-w-none':
          this.variant === 'cart',
        'gap-x-4 lg:w-[calc(100%+5rem)] transition-all ease-in-out':
          this.variant === 'pdp',
      }
    },
    isUpsellBlock() {
      return this.variant === 'pdp' || this.variant === 'cart'
    },
    scrollingTo() {
      if (this.isUpsellBlock) {
        return 364
      }
      return this.containerWidth
    },
  },
  mounted() {
    if (this.$refs?.wrapper) {
      this.wrapperW =
        this.$refs.wrapper.scrollWidth - this.$refs.wrapper.offsetWidth
      this.containerWidth = this.$refs.wrapper.offsetWidth
    }
  },
  methods: {
    scrollRight() {
      this.$refs.wrapper.scrollBy({
        left: this.scrollingTo,
        top: 0,
        behavior: 'smooth',
      })
    },
    scrollLeft() {
      this.$refs.wrapper.scrollBy({
        left: -this.scrollingTo,
        top: 0,
        behavior: 'smooth',
      })
    },
    scrolling(event) {
      this.currentX = event.target.scrollLeft
    },
  },
}
</script>
