<template>
  <div class="min-h-screen">
    <ElevateSearchController
      :search-query="searchQuery"
      :page-size="limit"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import ElevateSearchController from '../elevate-search-controller/ElevateSearchController.vue'

export default {
  name: 'StoryblokElevateSearchController',
  components: { ElevateSearchController },
  props: {
    elevatePageId: {
      type: String,
      default: '',
    },
    limit: {
      type: String,
      default: '20',
    },
  },
  computed: {
    searchQuery() {
      return {
        elevatePageId: this.elevatePageId,
      }
    },
  },
}
</script>
