<template>
  <section
    v-if="searchResponse.mappedProducts.length > 0"
    data-product-list-swipeable
    class="relative flex flex-col w-full"
  >
    <header class="flex items-end justify-between px-6 mb-6 lg:mb-10 lg:px-0">
      <h2 class="text-xl font-semibold tracking-wider lg:text-2xl">
        {{ title }}
      </h2>
      <div class="flex items-end gap-12">
        <Link
          v-if="link.linktype"
          :label="$t('Product_List_Shop_All')"
          :link="link"
          :underline="true"
        />
        <div
          v-if="searchResponse.mappedProducts.length > 4"
          class="hidden gap-4 lg:flex"
        >
          <ArrowButton
            direction="left"
            class="h-full p-4"
            @click="scrollLeft"
          />
          <ArrowButton
            direction="right"
            class="h-full p-4"
            @click="scrollRight"
          />
        </div>
      </div>
    </header>
    <div class="col-span-12">
      <SwipeableWrapper
        ref="swiper"
        :items="searchResponse.mappedProducts"
        :hide-progress-bar="hideProductCount"
        :variant="'plp'"
      >
        <ProductCard
          v-for="(product, index) in searchResponse.mappedProducts"
          :key="`product-card-${index}`"
          :position="index + 1"
          class="max-w-[10rem] min-w-[10rem] lg:min-w-[calc(25%-18px)] lg:max-w-[calc(25%-18px)] snap-start"
          :gtm-list-name="searchRequestId"
          :product="product"
          :show-trash-icon="false"
          :show-cart-icon="true"
          :show-heart-icon="true"
          :search-request-id="searchRequestId"
        />
      </SwipeableWrapper>
    </div>
  </section>
</template>
<script>
import ProductCard from '~/components/product-card/ProductCard'
import ArrowButton from '~/components/arrow-button/ArrowButton'
import SwipeableWrapper from '~/components/swipeable-wrapper/SwipeableWrapper'

export default {
  name: 'ProductListSwipeable',
  components: {
    ProductCard,
    ArrowButton,
    SwipeableWrapper,
  },
  props: {
    searchResponse: {
      type: Object,
      required: true,
    },
    link: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    searchRequestId: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    hideProductCount: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    const testFreaks = window.testFreaks || []
    testFreaks.push(['load', 'items'])
  },
  methods: {
    scrollLeft() {
      this.$refs.swiper.scrollLeft()
    },
    scrollRight() {
      this.$refs.swiper.scrollRight()
    },
  },
}
</script>
