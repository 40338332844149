<template>
  <button
    class="flex items-center w-full px-8 py-4 hover:bg-lightGray"
    @click="$emit('click')"
  >
    <ChevronIcon class="rotate-[90deg] w-4 h-4 absolute" />
    <h5 class="pl-8 pr-8 font-semibold"><slot /></h5>
  </button>
</template>
<script>
import ChevronIcon from '~/assets/icons/chevron-down-small.svg'
export default {
  name: 'FilterSubmenuHeader',
  components: {
    ChevronIcon,
  },
}
</script>
