<template>
  <div
    class="fixed top-0 right-0 flex flex-col w-screen max-h-[100dvh] h-full px-6 pt-10 pb-20 bg-white z-[110] lg:max-w-md"
  >
    <header class="flex items-center justify-between mb-6">
      <h3 class="text-xl font-semibold tracking-wider h-fit">
        {{ $t('Cart_Sidebar_Shopping_Bag') }}
      </h3>
      <button
        class="pb-[1px] text-sm border-b border-black border-solid tracking-wider hover:opacity-60 transition-opacity"
        type="button"
        @click="hideSidebar()"
      >
        {{ $t('Global_Close') }}
      </button>
    </header>
    <div v-if="!cart || !cart.items.length" class="empty-cart">
      <p>{{ $t('Checkout_Cart_Empty') }}</p>
    </div>
    <div v-else class="flex flex-col justify-between h-full max-h-[100vh-7rem]">
      <div class="flex flex-col overflow-auto scrollbar-hide">
        <span
          class="pb-4 mb-4 text-sm border-b border-solid text-darkGray border-lightGray"
          >{{ cart.items.length }} {{ $t('Products') }}</span
        >
        <div class="flex flex-col gap-5">
          <CartItem
            v-for="(item, itemIndex) in cart.items"
            :key="'item-' + itemIndex"
            :item="item"
            :cart="cart"
          />
        </div>
      </div>
      <div>
        <div
          class="flex flex-col gap-2 pt-6 border-t border-solid border-darkGray"
        >
          <div class="flex items-center justify-between">
            <span class="text-sm text-left">{{ $t('Global_Shipping') }}</span>
            <span class="text-sm text-right uppercase">{{
              cart.totals.shippingPriceAsNumber > 0
                ? cart.totals.shippingPrice
                : $t('Free_Shipping_Minicart')
            }}</span>
          </div>
          <div class="flex items-center justify-between mb-6">
            <span class="text-xl font-semibold text-left">{{
              $t('Global_Total')
            }}</span>
            <span class="text-xl font-semibold text-right">{{
              cart.totals.grandTotalPrice
            }}</span>
          </div>
        </div>
        <div class="flex flex-col w-full gap-3">
          <Button
            class="uppercase"
            bg="bg-black"
            color="text-white"
            py="py-4"
            font-size="text-xl"
            font-weight="font-normal"
            @click="goToCheckout()"
          >
            {{ $t('Go_To_Checkout') }}
          </Button>
          <Button
            class="uppercase"
            border="border border-solid border-black"
            py="py-4"
            font-size="text-xl"
            font-weight="font-normal"
            bg="bg-white"
            @click="$store.dispatch('ui/close-all/closeAll')"
          >
            {{ $t('Checkout_Continue_Browsing') }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CartItem from '~/components/cart-item/CartItem'

export default {
  name: 'CartSidebar',
  components: {
    CartItem,
  },
  computed: {
    ...mapGetters({
      cart: 'centra-cart/cart',
    }),
  },
  mounted() {
    document.body.classList.add('body-fixed')
  },
  beforeDestroy() {
    document.body.classList.remove('body-fixed')
  },
  methods: {
    hideSidebar() {
      this.$store.dispatch('ui/cart-sidebar/hideCartSidebar')
    },
    goToCheckout() {
      this.hideSidebar()
      this.$router.push(this.$u('checkout'))
    },
  },
}
</script>
