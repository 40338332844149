<template>
  <button
    class="wishlist-icon group"
    :class="{ active: inWishList }"
    aria-label="Add to wishlist"
    @click="toggleWishList"
  >
    <template v-if="inWishList">
      <TrashIcon v-if="showTrashIcon" class="w-full h-full" />
      <WishlistFullIcon v-else class="w-6 h-full" />
    </template>
    <WishlistEmptyIcon v-else class="w-6 h-full fill-black" />
  </button>
</template>

<script>
import ElevateMixin from '../../mixins/ElevateMixin'
import WishlistEmptyIcon from '~/static/images/wishlist-empty.svg'
import WishlistFullIcon from '~/static/images/wishlist-full.svg'
import TrashIcon from '~/static/images/trash.svg'
export default {
  name: 'WishlistIcon',
  components: {
    WishlistEmptyIcon,
    WishlistFullIcon,
    TrashIcon,
  },
  mixins: [ElevateMixin],
  props: {
    productId: {
      type: String,
      required: false,
      default: '',
    },
    showTrashIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      clicked: false,
    }
  },
  computed: {
    inWishList: ({ $store, productId }) =>
      $store.getters['wishlist/hasProductID'](productId),
  },
  methods: {
    toggleWishList() {
      this.inWishList ? this.removeFromWishlist() : this.addToWishlist()
    },
    addToWishlist() {
      this.$store.dispatch('wishlist/addProductID', this.productId)
      this.addFavoriteNotification(this.productId)
    },
    removeFromWishlist() {
      this.$store.dispatch('wishlist/removeProductID', this.productId)
      this.removeFavoriteNotification(this.productId)
    },
  },
}
</script>
