<template>
  <button
    v-if="size.quantity && moreItemsAvailable"
    class="
      flex
      items-center
      justify-center
      flex-grow
      px-2
      py-3
      overflow-hidden
      text-sm text-center
      transition-colors
      hover:bg-gray hover:text-white
    "
    :class="{ 'animate-pulse': loading }"
    @click.stop="addToCart(size)"
  >
    <span>
      <template v-if="loading">
        {{ $t('Related_Onesize_Adding') }}
      </template>
      <template v-else-if="size.label !== ''">
        {{ size.label }}
      </template>
      <template v-else>
        {{ $t('Product_Size_Onesize') }}
      </template>
    </span>
  </button>
  <!-- Out of stock, implement when we got notify me -->
  <!-- <div
    v-else
    class="
      flex
      justify-center
      flex-grow
      px-2
      py-3
      text-sm text-center
      label
      line-through
      out-of-stock
    "
  >
    <template v-if="size.label !== ''">
      {{ size.label }}
    </template>
    <template v-else>
      {{ $t('Product_Size_Onesize') }}
    </template>
  </div> -->
</template>

<script>
import { mapGetters } from 'vuex'
import ElevateMixin from '../../mixins/ElevateMixin'

export default {
  name: 'ProductCardSizeItem',
  mixins: [ElevateMixin],
  props: {
    size: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      cart: 'centra-cart/cart',
    }),
    moreItemsAvailable() {
      const itemInCartQuantity = this.cart?.items.find(
        ({ item }) => item === this.size.value
      )?.quantity
        ? this.cart?.items.find(({ item }) => item === this.size.value)
            ?.quantity
        : 0
      return this.size.quantity > itemInCartQuantity
    },
  },
  methods: {
    addToCart(size) {
      this.loading = true
      this.$addToCart(size.itemId)
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false
          this.$store.dispatch('ui/cart-sidebar/showCartSidebar')
          this.addToCartNotification(this.product.ticket, this.product.product)
        })
    },
  },
}
</script>
