<template>
  <div
    class="
      fixed
      top-0
      right-0
      w-screen
      h-screen
      px-6
      py-10
      bg-white
      z-[110]
      lg:max-w-md
    "
  >
    <div class="relative w-full">
      <ModalHeader :heading="heading" @close="hideRestockModal" />
    </div>
    <div class="flex flex-col gap-6 items-center">
      <p v-if="!success" class="text-center">
        {{ $t('Notify_Me_Description', { productName: product.name }) }}
      </p>

      <!-- Size selector -->
      <div v-if="!product.isOnesize" class="justify-between flex w-full">
        <button
          v-for="(size, index) in product.sizeOptions"
          :key="'size-' + index"
          class="
            w-full
            py-3
            transition-all
            border-r border-solid border-y
            first:border-l
            border-gray
            disabled:text-gray disabled:bg-lightGray
          "
          :class="{
            'bg-black text-white md:hover:text-black':
              size.label === product.size,
            'md:hover:bg-lightGray md:hover:border-x-0 md:hover:first:border-l md:hover:last:border-r':
              size.quantity === 0,
            'animate-turnBgBlack': blinkingSizeSelector,
          }"
          :disabled="size.quantity !== 0"
          @click="setSize(size)"
        >
          <span class="h-6">
            {{ size.label }}
          </span>
        </button>
      </div>

      <CheckIcon v-if="success" class="w-8 h-8" />
      <p v-if="success">
        {{ $t('Restock_Modal_Description_Saved', { email }) }}
      </p>
      <div class="w-full">
        <input
          v-if="!success"
          v-model="email"
          class="
            w-full
            p-3
            text-base
            leading-tight
            text-black
            bg-opacity-0
            border
            rounded-none
            appearance-none
            border-b-darkGray
            focus:border-b-black focus:ring-0
            focus-visible:ring-0 focus-visible:outline-0
          "
          type="email"
          :placeholder="$t('Newsletter_Your_Email')"
          @keyup.enter="notifyMe"
        />
        <span
          v-if="incorrectEmail"
          class="
            flex
            items-center
            gap-1
            font-medium
            tracking-wide
            text-brandRed text-xs
            mt-1
            ml-1
          "
        >
          <ErrorIcon class="w-4 h-4 fill-brandRed" />
          {{ $t('Invalid_Email_Input') }}
        </span>
      </div>

      <div v-if="!success" class="flex gap-2 justify-start w-full">
        <input
          id="default-checkbox"
          v-model="newsletterSignup"
          type="checkbox"
          class="w-4 h-4 rounded appearence-none accent-brandRed"
        />
        <label
          for="default-checkbox"
          class="text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {{ $t('Newsletter_Signup_Notify_Me') }}</label
        >
      </div>
      <Button
        class="w-full py-3 tracking-widest h-max uppercase lg:py-4"
        bg="bg-black"
        color="text-white"
        @click="handleClick"
      >
        {{ success ? $t('Notify_Me_Button_Saved') : $t('Notify_Me_Button') }}
      </Button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Button from '../button/Button.vue'
import ModalHeader from '../size-guide-modal/ModalHeader.vue'
import CheckIcon from '~/assets/icons/check-usp.svg'
import ErrorIcon from '~/assets/icons/error.svg'

export default {
  name: 'BackInStockNotificationModal',
  components: { Button, ModalHeader, CheckIcon, ErrorIcon },
  data() {
    return {
      email: '',
      newsletterSignup: false,
      success: false,
      incorrectEmail: false,
      blinkingSizeSelector: false,
    }
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'frontend/currentLanguageCode',
      currentCountryCode: 'frontend/currentCountryCode',
    }),
    newsletterList: ({ $store }) =>
      $store.getters['storyblok/settings']?.footerNewsletterList,
    verifiedMail: ({ email }) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      ),
    product: ({ $store }) => $store.state.ui['restock-notification'].product,
    itemId: ({ $store }) => $store.state.ui['restock-notification'].itemId,
    heading: ({ success, $t }) =>
      success ? $t('Restock_Modal_Heading_Saved') : $t('Restock_Modal_Heading'),
  },
  watch: {
    email() {
      if (this.verifiedMail) {
        this.incorrectEmail = false
      }
    },
  },
  methods: {
    hideRestockModal() {
      this.$store.dispatch('ui/restock-notification/hide')
    },
    async handleClick() {
      if (this.success) {
        this.hideRestockModal()
        return
      }

      if (this.verifiedMail && this.itemId !== undefined) {
        await this.notifyMe()
        return
      }

      if (!this.verifiedMail) {
        this.incorrectEmail = true
      }

      if (this.itemId === undefined) {
        this.signalNeedToPickSize()
      }
    },
    setSize(size) {
      this.$store.dispatch('ui/restock-notification/updateProduct', {
        itemId: size.itemId,
        product: { ...this.product, size: size.label },
      })
    },
    signalNeedToPickSize() {
      if (this.blinkingSizeSelector) return
      this.blinkingSizeSelector = true
      setTimeout(() => {
        this.blinkingSizeSelector = false
      }, 1000)
    },
    async notifyMe() {
      const data = {
        email: this.email,
        shipTo: {
          country: this.currentCountryCode,
        },
        item: this.itemId,
        language: this.currentLanguageCode,
      }
      const baseURL = this.$config.CENTRA_CHECKOUT_BASE_URL
      try {
        const response = await axios.get(`${baseURL}/selection`)
        if (response.data?.selection) {
          await axios.request({
            baseURL,
            url: 'back-in-stock-subscription',
            method: 'POST',
            data,
            headers: {
              'api-token': response.data.token,
            },
          })
        }

        if (this.newsletterSignup) {
          this.subscribe()
        }
        this.success = true
      } catch (error) {
        this.$notify({
          title: this.$t('Back_To_Stock_Notification_Error'),
          text: this.$t('Back_To_Stock_Notification_ErrorMsg'),
          type: 'error',
        })
        this.hideRestockModal()
      }
    },
    subscribe() {
      const register = async (token) => {
        return await this.$backendApi.post(
          `klaviyo/list/${this.newsletterList}/subscribe?recaptchaToken=${token}`,
          {
            email: this.email,
          }
        )
      }

      const executeRecaptchaTestAndRegister = async () => {
        try {
          const token = await window.grecaptcha.execute(
            this.$config.recaptchaSiteKey,
            { action: 'submit' }
          )
          await register(token)
        } catch (error) {
          console.error(error)
        }
      }

      if (!window.grecaptcha) {
        this.loadRecaptchaScript(() =>
          window.grecaptcha.ready(executeRecaptchaTestAndRegister)
        )
      } else {
        window.grecaptcha.ready(executeRecaptchaTestAndRegister)
      }
    },
    loadRecaptchaScript(onLoadCallback) {
      const recaptchaScript = document.createElement('script')
      recaptchaScript.onload = onLoadCallback
      recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${this.$config.recaptchaSiteKey}`
      document.head.appendChild(recaptchaScript)
    },
  },
}
</script>
