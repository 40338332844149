<template>
  <div class="flex flex-col gap-10">
    <!-- Header -->
    <div class="">
      <h3
        v-if="title"
        class="max-w-2xl text-xl font-semibold lg:text-3xl tracking-wider"
      >
        {{ title }}
      </h3>
      <div v-if="text" class="" v-html="text.html" />
    </div>

    <div class="relative flex items-center">
      <input
        v-model="email"
        class="
          w-full
          px-0
          py-3
          text-base
          leading-tight
          text-black
          bg-opacity-0
          border-t-0 border-b-2 border-l-0 border-r-0
          rounded-none
          appearance-none
          border-b-darkGray
          focus:border-b-black focus:ring-0
          focus-visible:ring-0 focus-visible:outline-0
        "
        type="email"
        :placeholder="getPlaceholder"
        @keyup.enter="handleSubscribe"
      />
      <button
        class="
          absolute
          right-0
          overflow-hidden
          hover:overflow-visible
          scrollbar-hide
          group
        "
        :class="!verifiedMail && 'select-none'"
        :aria-label="getButtonLabel"
        :disabled="loading"
        @click="handleSubscribe"
      >
        <ArrowLongIcon
          class="
            h-5
            w-5
            transition-all
            fill-black
            duration-[300ms]
            group-hover:translate-x-4 group-hover:fill-brandRed
          "
          :class="verifiedMail ? 'translate-x-0' : '-translate-x-6'"
        />
      </button>
    </div>
  </div>
</template>

<script>
import newsletter from '~/mixins/newsletter'
import ArrowLongIcon from '~/static/images/arrow-long.svg'

export default {
  name: 'Newsletter',
  components: {
    ArrowLongIcon,
  },
  mixins: [newsletter],
  props: {
    headline: {
      type: Object,
      required: false,
      default: null,
    },
    headlineSize: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: Object,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'beige',
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: '',
    },
    buttonLabel: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    list: {
      type: String,
      required: true,
    },
    foregroundColor: {
      type: String,
      default: null,
    },
    mobilePadding: {
      type: String,
      default: null,
    },
    mobileMargin: {
      type: String,
      default: null,
    },
    desktopPadding: {
      type: String,
      default: null,
    },
    desktopMargin: {
      type: String,
      default: null,
    },
    newsletterIndent: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      email: '',
      loading: false,
    }
  },
  computed: {
    getButtonLabel() {
      return this.buttonLabel
        ? this.buttonLabel
        : this.$t('Newsletter_Subscribe')
    },
    getPlaceholder() {
      return this.placeholder
        ? this.placeholder
        : this.$t('Newsletter_Your_Email')
    },
  },
  methods: {
    handleSubscribe() {
      const payload = { email: this.email, list: this.list }
      this.loading = true

      this.subscribe(payload)
      .finally(() => {
        this.loading = false
      })
    }
  },
}
</script>
