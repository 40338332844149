import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

function elevateClientInit({
  app,
  marketIdGetter,
  localeGetter,
  customerKey,
  sessionKey,
}) {
  let elevateMarketData
  let elevateClient

  return async function () {
    const elevateBaseUrl = (app.$config.elevateBaseUrl ?? '').trim()

    if (!elevateBaseUrl) {
      console.error(
        "elevateBaseUrl isn't set in nuxt.config.js publicRuntimeConfig"
      )
      return
    }

    if (!elevateMarketData) {
      elevateMarketData = await getElevateMarketData(
        (app.$config.elevateServiceUrl ?? '').trim(),
        marketIdGetter(),
        localeGetter()
      )

      if (
        !elevateMarketData ||
        !elevateMarketData.elevate_market_name ||
        !elevateMarketData.language
      ) {
        console.error(
          "Error when getting market data from elevate service, can't initialize elevate client"
        )
        return
      }
    }

    if (!elevateClient) {
      elevateClient = axios.create({
        timeout: 5000,
        baseURL: elevateBaseUrl,
      })

      elevateClient.interceptors.request.use(
        (request) => {
          request.params = request.params || {}
          request.params.market = elevateMarketData.elevate_market_name
          request.params.locale = elevateMarketData.language
          request.params.customerKey = customerKey
          request.params.sessionKey = sessionKey
          return request
        },
        (error) => Promise.reject(error)
      )
    }
    return elevateClient
  }
}

function getSetCookie(app, id, maxAge) {
  const cookieValue = app.$cookies.get(id) || uuidv4()
  app.$cookies.set(id, cookieValue, {
    path: '/',
    maxAge,
  })
  return cookieValue
}

export default (context, inject) => {
  const { app, store } = context

  let customerKey, sessionKey
  if (process.client) {
    customerKey = getSetCookie(app, 'elevate_ckey', 30 * 24 * 60 * 60 * 6) // 6 months
    sessionKey = getSetCookie(app, 'elevate_skey', 1 * 24 * 60 * 60) // 1 day
  } else {
    customerKey = uuidv4()
    sessionKey = uuidv4()
  }

  store.commit('elevate/setCustomerKey', customerKey)
  store.commit('elevate/setSessionKey', sessionKey)

  const elevateApi = elevateClientInit({
    app,
    marketIdGetter: () => store.getters['frontend/currentMarketId'],
    localeGetter: () => store.getters['frontend/currentLanguageCode'],
    customerKey,
    sessionKey,
  })
  if (elevateApi) {
    inject('elevateApi', elevateApi)
  }
}

/**
 * Fetches market data from elevate service to be used in the initailize of the elevate client
 * @param {Object} app - Nuxt app data
 * @param {string} centraMarket - current centra market id
 * @param {string} languageCode - current language code
 * @returns {Object} - market data from elevate service or undefined if error
 */
export async function getElevateMarketData(
  baseURL,
  centraMarket,
  languageCode
) {
  if (!baseURL) {
    console.error(
      "elevate.elevateServiceUrl isn't set in nuxt.config.js publicRuntimeConfig"
    )
    return
  }

  try {
    const elevateMarketResponse = await axios.request({
      baseURL,
      url: `/context/market/${centraMarket}/${languageCode}`,
      method: 'GET',
    })
    return elevateMarketResponse.data
  } catch (error) {
    return undefined
  }
}
