<template>
  <NavItem @click="toggleSearch">
    <label class="w-4 cursor-pointer" :for="inputid" :title="$t('Toggle_Search_Open')">
      <SearchIcon />
    </label>
  </NavItem>
</template>

<script>
import { mapState } from 'vuex'
import SearchIcon from '~/static/images/search.svg'
import NavItem from '~/components/header/NavItem.vue'

export default {
  name: 'ToggleSearch',
  components: {
    SearchIcon,
    NavItem
  },
  props: {
    inputid: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      searchOpen: (state) => state.ui.search.searchOpen,
    }),
  },
  methods: {
    toggleSearch() {
      if(!this.searchOpen) {
        this.$store.dispatch('ui/search/showSearch')
      } else {
        this.$store.dispatch('ui/search/hideSearch')
      } 
    },
  },
}
</script>
