<template>
  <div class="z-50 hidden gap-10 lg:flex desktop-menu">
    <DesktopMenuItem
      v-for="(menuItem, menuItemIndex) in menu"
      :key="'menu-item-' + menuItemIndex"
      :menu-item="menuItem"
    />
  </div>
</template>

<script>
import DesktopMenuItem from './DesktopMenuItem.vue'

export default {
  name: 'DesktopMenu',
  components: { DesktopMenuItem },
  props: {
    menu: {
      type: Array,
      required: true,
    },
  },
  watch: {
    $route() {
      // Close menu on route change
      this.$store.dispatch('ui/desktop-submenu/hideDesktopSubmenu')
    },
  },
}
</script>
