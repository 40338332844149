import Vue from 'vue'

Vue.mixin({
  methods: {
    async $addToCart(itemId) {
      const cart = await this.$store.dispatch('centra-cart/addItem', itemId)
      const item = cart.items.find((item) => item.line === cart.line)
      this.gtm_trackAddToCart(item, 1, cart)
    },
  },
})
