var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"productCardRef",staticClass:"flex flex-col justify-between w-full group lg:[&:nth-child(4n+1)]:delay-75 lg:[&:nth-child(4n+2)]:delay-150 lg:[&:nth-child(4n+3)]:delay-300 lg:[&:nth-child(4n+4)]:delay-[450ms]",class:[
    _vm.contextSearch ? 'context-search' : '',
    { 'product-card': true },
    { 'out-of-stock': !_vm.product.inStock },
    { loading: _vm.loading },
  ],on:{"mouseenter":_vm.mouseOver,"mouseleave":_vm.mouseOut}},[_c('SlideInTransitionTop',{attrs:{"duration":"duration-1000","delay":"delay-[inherit]"}},[(_vm.animated)?_c('div',{staticClass:"relative"},[_c('div',{staticClass:"relative mb-2 overflow-hidden scrollbar-hide aspect-portrait bg-lightGray"},[(_vm.imageUrl)?_c('Vue2Image',{staticClass:"object-contain transition-all duration-300 aspect-portrait bg-lightGray",class:{
            'scale-105': _vm.hovering,
          },attrs:{"src":_vm.imageUrl,"alt":_vm.product.name,"width":600,"widths":[320, 600],"height":"100%","quality":70,"background":"f0f0f0","loading":_vm.position === 1 ||
            _vm.position === 2 ||
            _vm.position === 3 ||
            _vm.position === 4
              ? 'eager'
              : 'lazy'},on:{"imageLoaded":_vm.imageLoaded}}):_vm._e(),_vm._v(" "),(!_vm.imageUrl)?_c('div',{staticClass:"flex items-center justify-center w-full h-full"},[_c('LogoIcon',{staticClass:"w-24 h-auto transition-colors lg:w-32 fill-black"})],1):_vm._e(),_vm._v(" "),_c('ProductCardBadgesContainer',{staticClass:"flex flex-col gap-1"},_vm._l((_vm.badges),function(badge,badgeIndex){return _c('ProductBadge',{key:'badge-' + badgeIndex,staticClass:"px-2 py-1 text-xs tracking-widest text-white w-fit",class:[badge.bg],style:(`background-color: ${badge.bg}; color: ${badge.color}`),attrs:{"label":badge.label}})}),1),_vm._v(" "),_c('mp-link',{staticClass:"absolute top-0 bottom-0 left-0 right-0",attrs:{"to":_vm.$u(_vm.product.url),"aria-label":`View Product: ${_vm.product.name}`},nativeOn:{"click":function($event){return _vm.trackClick.apply(null, arguments)}}}),_vm._v(" "),_c('SlideInTransitionTop',{attrs:{"duration":"duration-300"}},[(
              (_vm.showQuickShop || _vm.alwaysShowQuickShop || _vm.contextWishlist) &&
              _vm.product.inStock &&
              !_vm.isBundle
            )?_c('ProductCardQuickShop',{class:{ 'hidden lg:flex': _vm.contextWishlist },attrs:{"context-wishlist":_vm.contextWishlist,"quick-shop-product":_vm.product,"product":_vm.product}}):_vm._e()],1)],1),_vm._v(" "),(_vm.showActions && !_vm.isBundle)?_c('div',{staticClass:"absolute z-20 top-2 right-2 lg:right-4 lg:top-4",attrs:{"actions":""}},[(_vm.showHeartIcon)?_c('WishlistIcon',{staticClass:"w-6 h-6 transition add-to-wishlist hover:fill-brandRed",attrs:{"product-id":_vm.product.product,"show-trash-icon":_vm.contextWishlist}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('ProductCardDetails',{staticClass:"mb-5 lg:px-0",class:{
          'px-0': _vm.contextWishlist,
          'px-4': !_vm.contextWishlist,
        },attrs:{"product":_vm.product,"show-product-type":_vm.showProductType,"is-search-page":_vm.isSearchPage,"context-wishlist":_vm.contextWishlist,"gtm-list-name":_vm.gtmListName}}),_vm._v(" "),(_vm.contextWishlist)?_c('ProductCardSizeSelector',{attrs:{"quick-shop-product":_vm.product}}):_vm._e()],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }