<template>
  <button
    class="
      pb-[1px]
      border-b border-solid
      text-sm
      border-black
      cursor-pointer
      hover:opacity-60
      transition-opacity
      tracking-wider
    "
    @click="showSizeModal"
  >
    {{ $t('Size_Guide') }}
  </button>
</template>

<script>
export default {
  name: 'Sizeguide',
  methods: {
    showSizeModal() {
      this.$store.dispatch('ui/sizeguide/showSizeGuideModal')
    },
  },
}
</script>

<style lang="scss" scoped></style>
