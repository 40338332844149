import Vue from 'vue'
import { trimSlashes } from '@made-people/types-url-string'

Vue.mixin({
  methods: {
    /**
     * If we always use this method we can actually
     * switch markets in SPA mode
     *
     * @param {string|Object} link
     */
    $u(link) {
      let slug
      if (typeof link === 'object') {
        // We guess it's an SB link and check if it's internal, external or whatever
        let linkObject
        switch (link.linktype) {
          case 'url':
            slug = link.url
            break
          case 'story':
            linkObject = this.$store.getters['storyblok/getLinkForUuid'](
              link.id
            )
            if (linkObject) {
              slug = linkObject.slug
              if (typeof slug === 'string') {
                slug = trimSlashes(slug)
              }
            } else {
              // It's simply not set up in SB, so we cannot link it
              return null
            }
            break
          // Default means nothing so let's not do anythings
        }
      } else {
        slug = link
      }

      if (slug === null || slug === undefined || slug === false) {
        // Just prevent crashes if something is strangely passed
        return null
      }

      if (slug.match(/^https?:\/\//)) {
        return slug
      }

      // Check if anchorlink
      if (slug.match(/^#/)) {
        return slug
      }

      slug = slug.replace(/^site\/?/, '')
      const siteSlug = this.$store.getters['frontend/currentSiteLangSlug']
      if (slug === '') {
        // Don't add trailing slash when slug is empty.
        return `/${siteSlug}`
      }
      const sitePathStart = `/${siteSlug}/`
      if (slug.startsWith(sitePathStart)) {
        // Don't duplicate the site slug in case the slug already starts with the site slug
        // This prevents redirects from '/se/' to '/se/se/' and similar cases.
        return slug
      }
      return `${sitePathStart}${slug}`
    },
  },
})
