<template>
  <div class="flex flex-col w-full gap-2">
    <span class="label">
      {{ label }}
    </span>
    <div class="relative flex items-center justify-between">
      <slot />
      <ChevronIcon class="h-[7px] absolute right-4" />
    </div>
  </div>
</template>

<script>
import ChevronIcon from '~/assets/icons/chevron-down-small.svg'

export default {
  name: 'CountrySelectorSidebarInput',
  components: {
    ChevronIcon,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
