<template>
  <section v-if="searchQuery" elevate-product-list-swipeable>
    <div class="lg:mb-12 mt-0 px-0 lg:px-10 py-2 lg:py-4 grid justify-center">
      <ProductListSwipeable
        v-if="loaded && productList && productList.mappedProducts"
        :search-response="productList"
        :search-request-id="searchRequestId"
        :headline="title"
        :title="title"
        :link="seeMoreLink"
      />
    </div>
  </section>
</template>

<script>
import { crc32 } from '@made-people/functions-crc32'
import ProductListSwipeable from '~/components/product-list-swipeable/ProductListSwipeable.vue'

export default {
  name: 'ElevateProductListSwipeable',
  components: { ProductListSwipeable },
  props: {
    elevatePageId: {
      type: String,
      default: '',
    },
    elevateProductIds: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    limit: {
      type: String,
      default: '10',
    },
    link: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loaded: false,
      productList: undefined,
      searchRequestId: undefined,
    }
  },
  computed: {
    landingPageResponse: ({ $store, searchRequestId }) => {
      return searchRequestId?.length
        ? $store.state.elevate?.searchResponses[searchRequestId]
        : []
    },
    searchQuery() {
      if (this.elevatePageId?.length) {
        return { elevatePageId: this.elevatePageId, limit: this.limit }
      }
      if (this.elevateProductIds?.length) {
        return {
          products: this.elevateProductIds?.split(','),
          limit: this.limit,
        }
      }
      return undefined
    },
    seeMoreLink() {
      // if empty link object, should not show seeMoreLink
      if (!this.link?.id?.length) {
        return {}
      }
      return this.link
    },
  },
  async mounted() {
    await this.queryElevateProducts()
  },
  methods: {
    async queryElevateProducts() {
      await this.queryLandingPage()
    },
    async queryLandingPage() {
      if (typeof this.searchQuery === 'undefined') {
        console.error(
          'Please provide a valid elevate search query in storyblok'
        )
        this.loaded = true
        return
      }
      const landingPageQuery = this.searchQuery
      const queryId = crc32(JSON.stringify(landingPageQuery))
      this.searchRequestId = queryId

      await this.$store
        .dispatch('elevate/search', {
          query: landingPageQuery,
          searchRequestId: queryId,
        })
        .then(() => {
          this.productList = this.landingPageResponse
          this.loaded = true
        })
        .catch((e) => {
          console.error('error getting elevate landing page', e)
          this.loaded = true
        })
    },
  },
}
</script>
