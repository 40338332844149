<template>
  <button
    class="flex items-center justify-center h-full border border-solid border-darkGray hover:opacity-70"
    :aria-label="direction === 'right' ? 'Slide Right' : 'Slide Left'"
    @click="$emit('click')"
  >
    <ArrowLongIcon class="w-4 h-4 fill-black" :class="[getIconDirection]" />
  </button>
</template>
<script>
import ArrowLongIcon from '~/static/images/arrow-long.svg'

export default {
  name: 'ArrowButton',
  components: {
    ArrowLongIcon,
  },
  props: {
    direction: {
      type: String,
      required: false,
      default: 'right',
    },
  },
  computed: {
    getIconDirection: ({ direction }) => {
      if (direction === 'left') {
        return 'rotate-180'
      } else if (direction === 'up') {
        return 'rotate-90'
      } else if (direction === 'down') {
        return 'rotate-270'
      } else {
        return ''
      }
    },
  },
}
</script>
