export default {
  state() {
    return {
      routes: [
        {
          route: '/api/whitepouches/route/search',
          seo: {
            isIndexable: false,
          },
          /**
           * This is returned as 'nuxt' in the response.
           */
          component: {
            /**
             * Nuxt component name
             */
            component: 'search',
            /**
             * Entity id, for example StoryblokStoryId or CentraProductId
             */
            id: null,
            /**
             * I don't know what this is
             */
            url: 'search',
            /**
             * The entity itself
             *
             * Is called 'componentProperties' in the routes response
             */
            data: null,
          },
        },
      ],
      currentRoute: null,
    }
  },
  mutations: {
    addRoute(state, payload) {
      state.routes = [payload].concat(
        state.routes.filter((route) => route.route !== payload.route)
      )
      state.currentRoute = payload
    },
  },
  actions: {
    /**
     * Call the frackend to get a component name which can be renderered depending
     * on an external route table (or simply 404)
     */
    async lookupRoute({ dispatch, commit, rootGetters, state }, routePath) {
      // The _storyblok code is spread all over the place until we
      // have a backend that can take care of it. So for now we mock
      // stuff
      if (this.$router.history.current.query._storyblok) {
        const story = await dispatch(
          'storyblok/fetchStory',
          {
            id: this.$router.history.current.query._storyblok,
            language: this.$router.history.current.query._storyblok_lang,
          },
          { root: true }
        )
        if (!story) {
          return undefined
        }
        const storyblokPreviewRoute = {
          route: routePath,
          seo: {
            isIndexable: false,
          },
          component: {
            component: 'storyblok',
            id: parseInt(this.$router.history.current.query._storyblok),
            url: routePath,
          },
        }
        commit('addRoute', storyblokPreviewRoute)
        return Promise.resolve({
          ...storyblokPreviewRoute,
          component: {
            ...storyblokPreviewRoute.component,
            data: story,
          },
        })
      }

      /**
       * Route path without site locale part _but_ with leading slash
       */
      const urlPathWithoutLocale = routePath.replace(/\/[\w-]+\/?/, '/')
      /**
       * Some kind of route identifier. I don't know why '/api/whitepouches/route' is added.
       */
      const route = '/api/whitepouches/route' + urlPathWithoutLocale

      // If we have the route in state already we don't need a new lookup
      const cachedRoute = state.routes.find((x) => x.route === route)
      if (cachedRoute) {
        let data
        if (cachedRoute.component.component === 'storyblok') {
          data = rootGetters['storyblok/getStoryById'](cachedRoute.component.id)
        } else if (cachedRoute.component.component === 'product') {
          data = rootGetters['centra-product/getProductById'](
            cachedRoute.component.id
          )
        }

        return Promise.resolve({
          ...cachedRoute,
          component: {
            ...cachedRoute.component,
            data,
          },
        })
      }

      return this.$backendApi
        .get(`sitemap-and-route/routes/${routePath}`)
        .then((response) => {
          const routeQueryResult = response.data
          if (response.data.statusCode === 404) {
            return undefined
          }
          commit('addRoute', {
            route,
            seo: routeQueryResult.seo,
            component: {
              component: routeQueryResult.nuxt.componentName,
              id: routeQueryResult.nuxt.entityId,
              data: undefined, // Data is set in their own stores.
            },
          })
          let entityId = routeQueryResult.nuxt.entityId
          let componentProperties = routeQueryResult.nuxt.componentProperties
          if (routeQueryResult.nuxt.componentName === 'storyblok') {
            // Set componentId to number instead of string
            entityId = parseInt(routeQueryResult.nuxt.entityId)
            // Add story
            commit(
              'storyblok/story',
              {
                id: parseInt(routeQueryResult.nuxt.entityId), // TODO: This is a hash. It should be a string even though storyblok uses numbers.
                data: routeQueryResult.nuxt.componentProperties,
              },
              { root: true }
            )
          } else if (routeQueryResult.nuxt.componentName === 'product') {
            // Add product
            commit(
              'centra-product/products',
              [routeQueryResult.nuxt.componentProperties],
              { root: true }
            )
            // Read the product from the store because the store transforms it and the transformed product
            // is what is expected to be returned.
            // TODO: This may perhaps not be needed
            componentProperties =
              rootGetters['centra-product/getProductById'](entityId)
          }
          return {
            route,
            seo: routeQueryResult.seo,
            component: {
              component: routeQueryResult.nuxt.componentName,
              id: entityId,
              data: componentProperties,
            },
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            return undefined
          }
        })
    },
  },
}
