<template>
  <mp-link
    class="
      relative
      flex
      items-center
      justify-between
      w-full
      gap-4
      p-4
      bg-lightGray
      min-h-[80px]
    "
    :class="gotBackgroundImage && 'px-4 py-6'"
    :to="$u(link)"
    @click.native="hideMobileSidebar"
  >
    <div class="z-20 flex flex-col font-semibold">
      <span class="text-base font-normal tracking-widest">{{ tag }}</span>
      <span
        class="tracking-widest"
        :class="gotBackgroundImage && 'uppercase text-2xl font-normal'"
        >{{ label }}</span
      >
    </div>
    <Vue2Image
      v-if="gotBackgroundImage"
      class="absolute top-0 left-0 object-cover w-full h-full"
      :src="backgroundImage.filename"
      :alt="label"
      :width="200"
      :widths="[100, 200]"
      :quality="60"
    />
    <Vue2Image
      v-if="image && image.filename"
      class="w-14 h-14"
      :src="image.filename"
      :alt="image.alt"
      :width="100"
      :widths="[50, 100]"
      :quality="60"
      background="f0f0f0"
    />
  </mp-link>
</template>

<script>
export default {
  name: 'MobileSidebarCard',
  props: {
    label: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      required: false,
      default: () => {},
    },
    backgroundImage: {
      type: Object,
      required: false,
      default: () => {},
    },
    link: {
      type: Object,
      required: false,
      default: () => {},
    },
    tag: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    gotBackgroundImage: ({ backgroundImage }) =>
      backgroundImage && backgroundImage.filename,
  },
  methods: {
    hideMobileSidebar() {
      this.$store.dispatch('ui/mobile-sidebar/hideMobileSidebar')
    },
  },
}
</script>
