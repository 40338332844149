var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"flex flex-col gap-8 lg:gap-10",attrs:{"data-product-list":""}},[_c('client-only',[(!_vm.hideFilter)?[(_vm.searchResponse.isElevate)?_c('ElevateFilterModal',{attrs:{"filters":_vm.searchResponse.preparedFilters,"search-request-id":_vm.searchRequestId}}):_c('FilterModal',{attrs:{"filters":_vm.searchResponse.preparedFilters,"search-request-id":_vm.searchRequestId}})]:_vm._e()],2),_vm._v(" "),(!_vm.hideFilter)?_c('Filterbar',{attrs:{"viewed-products":_vm.searchResponse.mappedProducts.length,"total-products":_vm.searchResponse.productCount,"search-request-id":_vm.searchRequestId,"filters":_vm.searchResponse.preparedFilters}}):_vm._e(),_vm._v(" "),_c('section',{staticClass:"grid min-h-screen grid-cols-12 mb-6 lg:mb-10",class:[
      _vm.gapY,
      _vm.gapX,
      _vm.gapXDesktop,
      _vm.gapYDesktop,
      {
        '-ml-6 w-[calc(100%+48px)] lg:ml-0 lg:w-auto':
          !_vm.mobileGutter && !_vm.contextWishlist,
        'gap-y-8': _vm.contextWishlist,
      },
    ]},_vm._l((_vm.searchResponse.mappedProducts),function(product,index){return _c('ProductCard',{key:`product-card-${index}-${product.product}`,staticClass:"col-span-6 lg:col-span-3",attrs:{"position":index + 1,"gtm-list-name":_vm.searchRequestId,"product":product,"context-wishlist":_vm.contextWishlist,"show-trash-icon":false,"mobile-rounded-corners":false,"desktop-rounded-corners":false,"show-cart-icon":!_vm.hideQuickshop,"show-heart-icon":!_vm.hideAddToWishlist,"search-request-id":_vm.searchRequestId,"animations":_vm.animations}})}),1),_vm._v(" "),(!_vm.hideLoadMore && _vm.searchResponse.hasMoreProducts)?_c('ViewMore',{attrs:{"viewed-products":_vm.searchResponse.mappedProducts.length,"total-products":_vm.searchResponse.productCount,"use-observer":_vm.loadInMoreOnObserve,"search-request-id":_vm.searchRequestId,"limit":_vm.limitProducts},on:{"view-more":_vm.loadMore}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }