<template>
  <div
    v-if="quickShopProduct && quickShopProduct.sizeOptions"
    class="
      absolute
      justify-between
      mx-4
      bg-white
      w-[calc(100%-32px)]
      bottom-4
      flex-wrap
      z-10
    "
    :class="{ 'hidden lg:flex': !contextWishlist, flex: contextWishlist }"
  >
    <div
      v-if="showMoreQuickShop"
      class="relative flex flex-col-reverse w-full"
      @mouseenter="mouseOver"
      @mouseleave="mouseOut"
    >
      <div
        class="bottom-0 flex items-center justify-center flex-grow w-full px-2 py-3 overflow-hidden text-sm text-center transition-colors hover:bg-gray hover:text-white before:w-full before:absolute before:bg-lightGray before:h-full before:-bottom-full before:z-20"
      >
        {{ $t('Product_QuickShop_QuickAdd') }}
      </div>
      <div class="absolute bottom-0 grid w-full h-auto transition-transform duration-300 bg-white" :class="{'grid-cols-2 lg:grid-cols-3': !isBackProtection && !isPoles, 'lg:translate-y-full': !hovering, 'grid translate-y-0': hovering, 'grid-cols-1': isBackProtection, 'grid-cols-3 xl:grid-cols-4': isPoles}">
        <ProductCardSizeItem
          v-for="(size, index) in quickShopProduct.sizeOptions"
          :key="'size-' + index"
          class=""
          :size="
            oneSize
              ? {
                  ...size,
                  label: `${$t('One_Size')} / ${$t(
                    'Product_Details_Add_To_Cart'
                  )}`,
                }
              : size
          "
          :product="quickShopProduct"
          :class="{ nogrow: quickShopProduct.sizeOptions.length > 6 }"
        />
      </div>
    </div>
    <template v-else>
      <ProductCardSizeItem
        v-for="(size, index) in quickShopProduct.sizeOptions"
        :key="'size-' + index"
        class="size"
        :size="
          oneSize
            ? {
                ...size,
                label: `${$t('One_Size')} / ${$t(
                  'Product_Details_Add_To_Cart'
                )}`,
              }
            : size
        "
        :product="quickShopProduct"
        :class="{ nogrow: quickShopProduct.sizeOptions.length > 6 }"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductCardSizeItem from '../product-card-size-item/ProductCardSizeItem.vue'

export default {
  name: 'ProductCardQuickShop',
  components: {
    ProductCardSizeItem,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    contextWishlist: {
      type: Boolean,
      default: false,
    },
    quickShopProduct: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      hovering: false,
    }
  },
  computed: {
    ...mapGetters({
      lineProduct: 'centra-cart/lineProduct',
    }),
    oneSize: ({ quickShopProduct }) =>
      quickShopProduct.sizeOptions.length === 1,
    showMoreQuickShop: ({ quickShopProduct }) =>
      quickShopProduct.sizeOptions.map(({ label }) => label).join().length > 32,
    isBackProtection: ({ quickShopProduct }) =>
      quickShopProduct.sizeOptions[0].label.length > 10,
    isPoles: ({ quickShopProduct }) =>
      quickShopProduct.sizeOptions[0].label.length < 4 
  },
  methods: {
    mouseOver() {
      this.hovering = true
    },
    mouseOut() {
      this.hovering = false
    },
  },
}
</script>
