export default {
  state() {
    return {
      mobileSidebarOpen: false,
      activeMenu: '',
    }
  },
  mutations: {
    showMobileSidebar(state) {
      state.mobileSidebarOpen = true
    },
    hideMobileSidebar(state) {
      state.mobileSidebarOpen = false
    },
    setActiveMenu(state, payload) {
      state.activeMenu = payload
    },
  },
  actions: {
    showMobileSidebar({ dispatch, commit }) {
      dispatch('ui/close-all/closeAll', null, { root: true })
      dispatch('ui/overlay/showOverlay', 90, { root: true })
      commit('showMobileSidebar')
      dispatch('ui/toggle-scroll/disableScroll', null, { root: true })
    },
    hideMobileSidebar({ dispatch, commit }) {
      dispatch('ui/overlay/hideOverlay', null, { root: true })
      commit('hideMobileSidebar')
      dispatch('ui/toggle-scroll/enableScroll', null, { root: true })
    },
    setActiveMenu({ commit }, payload) {
      commit('setActiveMenu', payload)
    },
  },
}
