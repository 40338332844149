<template>
  <div
    class="absolute z-10 left-2 top-2 lg:top-4 lg:left-4"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ProductCardBadgesContainer',
}
</script>
