<template>
  <NavItem
    class="flex items-center justify-center w-8 h-10 cursor-pointer"
    @click="toggleMiniCart"
  >
    <div ref="carticon" class="h-full w-7" :title="$t('Toggle_Cart_Open')">
      <CartIcon class="w-[22px] h-full" :class="[fill]" />
    </div>
    <client-only>
      <div v-if="cartQty > 0" :class="{ 'animate-bounce': animationActive }">
        <span>{{ cartQty }}</span>
      </div>
    </client-only>
  </NavItem>
</template>

<script>
import CartIcon from '~/static/images/cart.svg'
import NavItem from '~/components/header/NavItem.vue'

export default {
  name: 'ToggleCart',
  components: { CartIcon, NavItem },
  props: {
    device: {
      type: String,
      default: 'mobile',
    },
    cartQty: {
      type: Number,
      required: true,
    },
    fill: {
      type: String,
      default: 'fill-black',
    },
  },
  data: () => ({
    animationActive: false,
  }),
  watch: {
    cartQty() {
      this.animationActive = true
      setTimeout(() => {
        this.animationActive = false
      }, 1500)
    },
  },
  methods: {
    toggleMiniCart() {
      this.$store.dispatch('ui/cart-sidebar/showCartSidebar')
    },
  },
}
</script>
