import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

export default (context) => {
  if (context.app.$config.sentry.dsn) {
    Sentry.init({
      Vue,
      dsn: context.app.$config.sentry.dsn,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(
            context.app.router
          ),
          tracePropagationTargets: [
            'localhost',
            'eqpestore.com',
            'www.eqpestore.com',
            'eqpe.frackend.net',
            /^\//,
          ],
        }),
      ],

      tracesSampleRate: context.app.$config.sentry.tracesSampleRate,
      trackComponents: true,

      // This does not disable views error logger
      logErrors: true,
    })
  }
}
