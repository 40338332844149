<template>
  <div
    v-if="showAutocompleteSection"
    class="
      bg-white
      w-full
      lg:grid lg:grid-cols-3
      flex flex-col
      py-10
      px-6
      lg:px-10
      gap-8
      justify-between
      lg:overflow-hidden
      overflow-y-scroll
      hide-scrollbar
      max-h-[100vh]
    "
  >
    <PhraseSuggestions :search-query="searchQuery" @search="search" />
    <ProductSuggestions :search-query="searchQuery" @search="search" />
  </div>
</template>

<script>
import PhraseSuggestions from './PhraseSuggestions.vue'
import ProductSuggestions from './ProductSuggestions.vue'

export default {
  name: 'AutoComplete',
  components: { PhraseSuggestions, ProductSuggestions },
  props: {
    searchQuery: {
      type: String,
      default: '',
    },
  },
  computed: {
    autoCompleteResponse: ({ $store }) =>
      $store.state.elevate.autoCompleteResponse,
    showAutocompleteSection: ({ autoCompleteResponse }) =>
      autoCompleteResponse &&
      (autoCompleteResponse.productSuggestions ||
        autoCompleteResponse.phraseSuggestions),
  },
  methods: {
    search(query) {
      this.$emit('search', query)
    },
  },
}
</script>
