import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6e7c693a = () => interopDefault(import('../src/pages/_context/checkout/index.vue' /* webpackChunkName: "pages/_context/checkout/index" */))
const _577390d4 = () => interopDefault(import('../src/pages/_context/cookie-policy/index.vue' /* webpackChunkName: "pages/_context/cookie-policy/index" */))
const _1a424394 = () => interopDefault(import('../src/pages/_context/tracking/index.vue' /* webpackChunkName: "pages/_context/tracking/index" */))
const _71063dbc = () => interopDefault(import('../src/pages/_context/wishlist/index.vue' /* webpackChunkName: "pages/_context/wishlist/index" */))
const _03799a5c = () => interopDefault(import('../src/pages/_context/checkout/result.vue' /* webpackChunkName: "pages/_context/checkout/result" */))
const _741e738c = () => interopDefault(import('../src/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/:context?/checkout",
    component: _6e7c693a,
    name: "context-checkout"
  }, {
    path: "/:context?/cookie-policy",
    component: _577390d4,
    name: "context-cookie-policy"
  }, {
    path: "/:context?/tracking",
    component: _1a424394,
    name: "context-tracking"
  }, {
    path: "/:context?/wishlist",
    component: _71063dbc,
    name: "context-wishlist"
  }, {
    path: "/:context?/checkout/result",
    component: _03799a5c,
    name: "context-checkout-result"
  }, {
    path: "/*",
    component: _741e738c,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
