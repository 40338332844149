<template>
  <span>
    {{ countryName }}
  </span>
</template>

<script>
import ISOCountries from '@made-people/centra-storyblok-nuxt-shared/lib/iso-countries'
import { mapGetters } from 'vuex'

export default {
  name: 'CountryName',
  computed: {
    ...mapGetters({
      countryCode: 'frontend/currentCountryCode',
    }),
    countryName: ({ countryCode }) => ISOCountries[countryCode.toUpperCase()],
  },
}
</script>
