export default {
  state() {
    return {
      productBundleModalOpen: false,
    }
  },
  mutations: {
    showProductBundleModal(state) {
      state.productBundleModalOpen = true
    },
    hideProductBundleModal(state) {
      state.productBundleModalOpen = false
    },
  },
  actions: {
    showProductBundleModal({ dispatch, commit }) {
      dispatch('ui/close-all/closeAll', null, { root: true })
      dispatch('ui/overlay/showOverlay', 90, { root: true })
      commit('showProductBundleModal')
    },
    hideProductBundleModal({ dispatch, commit }) {
      dispatch('ui/overlay/hideOverlay', null, { root: true })
      commit('hideProductBundleModal')
    },
  },
}
