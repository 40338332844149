export default {
  namespaced: true,
  state() {
    return {
      pdpUspModalOpen: false,
      pdpUspTitle: '',
      pdpUspContent: {},
    }
  },
  mutations: {
    /**
     * Pdp Usp Modal
     */
    showPdpUspModal(state, { text, content }) {
      // Close everything else
      state.miniCartOpen = false
      state.mobileMenuOpen = false
      state.mobileProductFilterOpen = false
      state.hotspotProductsOpen = null
      state.productRestockModalOpen = false
      state.sizeSelectorOpen = false
      state.countrySelectorOpen = false
      state.sizeguideModalOpen = false

      // Open Pdp Usp modal
      state.modalOpen = true
      state.pdpUspModalOpen = true
      state.pdpUspTitel = text
      state.pdpUspContent = content
      state.overlayIndex = 11
    },
    hidePdpUspModal(state) {
      state.modalOpen = false
      state.pdpUspModalOpen = false
      state.overlayIndex = 0
    },
  },
  actions: {
    showPdpUspModal({ commit }, { text, content }) {
      commit('showPdpUspModal', { text, content })
    },
    hidePdpUspModal({ commit }) {
      commit('hidePdpUspModal')
    },
  },
}
