import { transformProduct } from './centra-product'

/**
 * Run something wrapped in suspend/resume
 *
 * @param callback
 * @param resumeParameters
 * @returns {Q.Promise<any> | Promise<void>}
 */
const withSuspendCentraCheckout = (callback, resumeParameters) => {
  const suspendCentraCheckout = () => {
    window.CentraCheckout && window.CentraCheckout.suspend()
  }
  const resumeCentraCheckout = () => {
    window.CentraCheckout && window.CentraCheckout.resume(resumeParameters)
  }
  suspendCentraCheckout()
  return callback().finally(() => resumeCentraCheckout())
}

export default {
  namespaced: true,
  state() {
    return {
      /**
       * The current cart, if any. We store the entire centra object here instead
       * of turning it into something readable, and then we use getters to
       * reduce it as needed.
       *
       * This state should mutate every time any response receives a cart.
       */
      selection: undefined,
      payment: undefined,
      shipping: undefined,
      order: undefined,
    }
  },
  mutations: {
    selection(state, selection) {
      if (selection && selection.items) {
        selection.items = selection.items.map((item) => {
          if (item._product) {
            item._product = transformProduct(this, item._product)
          }
          return item
        })
      }
      state.selection = selection
    },
    payment(state, payment) {
      state.payment = payment
    },
    shipping(state, shipping) {
      state.shipping = shipping
    },
    order(state, order) {
      state.order = order
    },
  },
  actions: {
    /**
     * This is really only allowed to run from nuxtServerInit, will keep it here
     * anyway until further notice
     */
    initialize({ dispatch }) {
      return dispatch('fetchCart')
    },

    /**
     * Add a voucher
     */
    addVoucher({ commit, rootState, rootGetters, dispatch }, voucher) {
      const title = this._vm.$t('Voucher_Title', null, rootGetters)
      const successText = this._vm.$t('Voucher_Add_Success', null, rootGetters)
      const errorText = this._vm.$t('Voucher_Add_Error', null, rootGetters)

      return withSuspendCentraCheckout(() =>
        this.$backendApi
          .post(`cart/voucher/${voucher}`)
          .then(async (response) => {
            if (
              /adyen/.test(response.data.paymentMethod) &&
              rootState.route.name === 'context-checkout'
            ) {
              await dispatch('checkoutUpdate')
            }
            this._vm.$notify({
              title,
              text: successText,
              type: 'success',
            })
            commit('selection', response.data)
            return Promise.resolve(response.data)
          })
          .catch(() => {
            this._vm.$notify({
              title,
              text: errorText,
              type: 'error',
            })
          })
      )
    },

    /**
     * Remove a voucher
     */
    removeVoucher({ commit, rootState, rootGetters, dispatch }, voucher) {
      const title = this._vm.$t('Voucher_Title', null, rootGetters)
      const successText = this._vm.$t(
        'Voucher_Remove_Success',
        null,
        rootGetters
      )
      const errorText = this._vm.$t('Voucher_Remove_Error', null, rootGetters)

      return withSuspendCentraCheckout(() =>
        this.$backendApi
          .delete(`cart/voucher/${voucher}`)
          .then(async (response) => {
            if (
              /adyen/.test(response.data.paymentMethod) &&
              rootState.route.name === 'context-checkout'
            ) {
              await dispatch('checkoutUpdate')
            }
            this._vm.$notify({
              title,
              text: successText,
              type: 'success',
            })
            commit('selection', response.data)
            return Promise.resolve(response.data)
          })
          .catch(() => {
            this._vm.$notify({
              title,
              text: errorText,
              type: 'error',
            })
          })
      )
    },

    /**
     * Add an item to cart
     */
    addItem({ commit, rootState, dispatch }, itemId) {
      return withSuspendCentraCheckout(() =>
        this.$backendApi.post(`cart/add/${itemId}`).then(async (response) => {
          const cart = response.data
          if (
            /adyen/.test(cart.paymentMethod) &&
            rootState.route.name === 'context-checkout'
          ) {
            await dispatch('checkoutUpdate')
          }
          commit('selection', cart)
          return cart
        })
      )
    },

    /**
     * Fetch an existing cart and replace it in the state. Since we always get
     * the complete cart we can always replace it without diffing things
     */
    fetchCart({ commit }) {
      return this.$backendApi
        .get('cart')
        .then((response) => {
          if (response.status === 200) {
            commit('selection', response.data)
          } else {
            commit('selection', undefined)
          }
        })
        .catch((err) => {
          // There is no cart or an error, the error should really be handled
          console.log(err)
        })
    },

    /**
     * Updates the quantity of a cart item, useful in minicarts and checkout
     * overviews
     */
    updateItem({ rootState, dispatch, commit }, { item, quantity }) {
      return withSuspendCentraCheckout(() =>
        this.$backendApi
          .put(`cart/update/${item}/${quantity}`)
          .then(async (response) => {
            if (
              /adyen/.test(response.data.paymentMethod) &&
              rootState.route.name === 'context-checkout'
            ) {
              await dispatch('checkoutUpdate')
            }
            commit('selection', response.data)
            return response.data
          })
      )
    },

    deleteBundle({ rootState, dispatch, commit }, { lineId }) {
      this.loading = true
      return withSuspendCentraCheckout(() =>
        this.$backendApi
          .post(`/cart/bundles/delete/item/${lineId}`)
          .then(async (response) => {
            if (
              /adyen/.test(response.data.paymentMethod) &&
              rootState.route.name === 'context-checkout'
            ) {
              await dispatch('checkoutUpdate')
            }
            commit('selection', response.data)
            return response.data
          })
          .catch((error) => {
            console.log(error)
          })
      )
    },

    checkoutPaymentCallback(_vuex, eventData) {
      return this.$backendApi
        .post('cart/checkout/adyen-post-payment', eventData)
        .then((response) => response.data)
        .catch((error) => {
          console.error(`Error POSTing adyen payment`, error)
          return null
        })
    },

    checkoutUpdate({ dispatch, commit, rootGetters }, checkoutContext) {
      return withSuspendCentraCheckout(() =>
        this.$backendApi
          .post('cart/checkout/update', checkoutContext)
          .then((response) => {
            if (response.data.payment?.action === 'success') {
              // A placed free order
              commit('selection', undefined)
              commit('payment', undefined)
              commit('shipping', undefined)
              return response.data.payment
            } else {
              // eslint-disable-next-line no-lonely-if
              if (response.data.cartChanges) {
                // The cart changed during update

                // Notify user
                // eslint-disable-next-line no-unused-expressions
                response.data.cartChanges?.removedItems?.forEach((item) =>
                  this._vm.$notify({
                    text: this._vm.$t(
                      'Dialog_Add_To_Cart_Product_Removed',
                      { product: item._product.name },
                      rootGetters
                    ),
                    type: 'error',
                  })
                )
                // eslint-disable-next-line no-unused-expressions
                response.data.cartChanges?.quantityChangedItems?.forEach(
                  (item) =>
                    this._vm.$notify({
                      text: this._vm.$t(
                        'Dialog_Add_To_Cart_Only_X_Items_Available',
                        {
                          product: item._product.name,
                          items: item.newItem.quantity,
                        },
                        rootGetters
                      ),
                      type: 'error',
                    })
                )
                // Redo checkout update
                return dispatch('checkoutUpdate', checkoutContext)
              } else {
                commit('selection', response.data.selection)
                commit('payment', response.data.payment)
                commit('shipping', response.data.shipping)
              }
            }
            return Promise.resolve(response.data)
          })
          .catch((error) => {
            console.error(error)
            this._vm.$notify({
              text:
                error.response?.data?.errors.join('\n') ||
                this.$t('Dialog_Generic_Checkout_Error'),
              type: 'error',
            })
            return Promise.reject(error) // this is how you chain rejections
          })
      )
    },

    /**
     * Calls checkout-fields to keep the customer address in sync with
     * the Centra selection address for the current session
     */
    checkoutUpdateFields({ commit }, fields) {
      return withSuspendCentraCheckout(
        () =>
          this.$backendApi
            .put('cart/checkout/update-fields', fields)
            .then((response) => {
              commit('selection', response.data)
              return Promise.resolve(response.data)
            }),
        fields?.detail?.additionalFields?.suspendIgnore
      )
    },

    /**
     * Fetch order by receipt
     */
    paymentResultByReceipt({ commit }, receiptId) {
      return this.$backendApi
        .get(`/cart/checkout/order-by-receipt/${receiptId}`)
        .then((response) => {
          if (response.data) {
            // All good man, clean the cart and stuff
            commit('order', response.data)
            commit('selection', undefined)
            commit('payment', undefined)
            commit('shipping', undefined)
          }
          return response.data
        })
    },

    /**
     * Proxy payment result stuff to centra to see what they say about it
     *
     * @see https://docs.centra.com/guides/shop-api/payment-method-flows#payment-result-types
     */
    paymentResult({ commit, rootGetters }, paymentFields) {
      return this.$backendApi
        .post('cart/checkout/payment-result', {
          ...paymentFields,
          lastSelectionId: rootGetters['last-order/lastSelectionId'],
        })
        .then((response) => {
          if (response.data.order) {
            // All good man, clean the cart and stuff
            commit('order', response.data)
            commit('selection', undefined)
            commit('payment', undefined)
            commit('shipping', undefined)
          }
          return response.data
        })
    },

    /**
     * Sets the country for the selection, might destroy it
     */
    setCountry({ commit }, countryCode) {
      withSuspendCentraCheckout(() => {
        return this.$backendApi
          .put(`cart/country/${countryCode}`)
          .then((response) => {
            commit('selection', response.data)
            return Promise.resolve(response.data)
          })
      })
    },
  },
  getters: {
    cart(state) {
      return state.selection
    },

    payment(state) {
      return state.payment
    },

    shipping(state) {
      return state.shipping
    },

    order(state) {
      return state.order
    },
    lineProduct: (state) =>
      state.selection?.items?.find(
        (item) => item.line === state.selection.line
      ),
    findifyLineItems: (state) => generateFindifyItems(state.selection.items),
  },
}
