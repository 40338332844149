export default {
  namespaced: true,
  state() {
    return {
      filterModalOpen: false,
      filterSubmenuId: null,
      filterSubmenuOpen: false,
    }
  },
  mutations: {
    /**
     * Size Guide Modal
     */
    showFilterModal(state) {
      // Close everything else
      state.miniCartOpen = false
      state.mobileMenuOpen = false
      state.mobileProductFilterOpen = false
      state.hotspotProductsOpen = null
      state.productRestockModalOpen = false
      state.sizeSelectorOpen = false

      // Open Size Guide
      state.modalOpen = true
      state.filterModalOpen = true
      state.overlayIndex = 11
    },
    hideFilterModal(state) {
      state.modalOpen = false
      state.filterModalOpen = false
      state.overlayIndex = 0
    },
    openFilterSubmenu(state, payload) {
      state.filterSubmenuId = payload.id
      state.filterSubmenuOpen = true
    },
    closeFilterSubmenu(state) {
      state.filterSubmenuId = null
      state.filterSubmenuOpen = false
    }

  },
  actions: {
    showFilterModal({ commit }) {
      commit('showFilterModal')
    },
    hideFilterModal({ commit }) {
      commit('hideFilterModal')
    },
    openFilterSubmenu({ commit }, payload) {
      commit('openFilterSubmenu', payload)
    },
    closeFilterSubmenu({ commit }) {
      commit('closeFilterSubmenu')
    }
  },
}
