export const sizeChartDelimiter = 'x'

export const getProductPricelist = (store, product) => {
  return product?.markets?.[store.getters['frontend/currentMarketId']]
    ?.pricesByPricelist?.[store.getters['frontend/currentPricelistId']]
}

export const getProductPrice = (store, product, key) => {
  const pricelist = getProductPricelist(store, product)
  if (pricelist) {
    return pricelist[key]
  } else {
    return ''
  }
}

/**
 * This is where we do all the magic that formats the maximized centra format into something that we can
 * always work with. Add new things that you want to play with in here.
 */
export const transformProduct = (_store, product) => {
  return product
}

/**
 * The store should for now keep a basic structure of the product tree and leave
 * all bigger and heavy lookups to route level lookups
 */
export default {
  namespaced: true,
  state() {
    return {
      products: [],
      categoryProducts: {},
    }
  },
  mutations: {
    products(state, products) {
      const transformedProducts = products.map((product) =>
        transformProduct(this, product)
      )
      state.products = transformedProducts.concat(
        state.products.filter((item) => {
          return !products.find((product) => product.product === item.product)
        })
      )
    },
    categoryProducts(state, { categoryId, productIds }) {
      state.categoryProducts[categoryId] = productIds
    },
  },
  actions: {
    /**
     * Looks up products from centra
     */
    lookupProducts({ commit }, ids) {
      if (!ids && !ids?.length) {
        return null
      }
      if (Array.isArray(ids)) {
        ids = ids.join(',')
      }
      return this.$backendApi
        .get(`/products/${ids}`)
        .then((response) => {
          commit('products', response.data)
          return response.data
        })
        .catch((e) => {
          console.log(`Error in centra-product/lookupProducts - ${ids} - ${e}`)
        })
    },

    lookupProductsByCategoryUri({ commit }, categoryUri) {
      return this.$backendApi
        .get(`/products/by-category-uri/${categoryUri}`)
        .then((response) => {
          commit('products', response.data)
          commit('categoryProducts', {
            categoryId: categoryUri,
            productIds: response.data.map((p) => p.product),
          })
          return response.data
        })
        .catch((e) => {
          console.error(
            `Error in centra-product/lookupProductsByCategoryUri - ${categoryUri} - ${e}`
          )
        })
    },

    lookupProductsBySkus(_, skus) {
      return this.$backendApi
        .get(`/products/ids-by-skus/${skus}`)
        .then((response) => {
          return response.data
        })
        .catch((e) => {
          console.log(
            `Error in centra-product/lookupProductsBySkus - ${skus} - ${e}`
          )
        })
    },
  },
  getters: {
    getProductById: (state) => (id) =>
      state.products.find((x) => x.product === id),
    getProductBySilkId: (state) => (id) =>
      state.products.find((x) => x.silkProduct === id),
    getProductsByCategoryUriOrId: (state) => (categoryUriOrId) => {
      return (
        state.categoryProducts[categoryUriOrId]
          ?.map((productId) =>
            state.products.find((product) => product.product === productId)
          )
          .filter((product) => !!product) ?? []
      )
    },
  },
}
