<template>
  <transition
    :enter-active-class="`transition ${duration}`"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition duration-300"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'FadeInTransition',
  props: {
    duration: {
      type: String,
      default: 'duration-500',
    },
  }
}
</script>
