<template>
  <button
    v-if="swatch.imageUrl"
    class="w-5 h-5 min-h-[24px] min-w-[24px] rounded-full border border-solid"
    :class="{ 'border-darkGray p-[2px] ': active, 'border-lightGray': !active }"
    @click="$router.push($u(url))"
  >
    <Vue2Image
      class="object-cover w-full h-full rounded-full"
      :src="swatch.imageUrl"
      :alt="url"
      :quality="60"
    />
  </button>
  <button
    v-else
    class="w-5 h-5 min-h-[24px] min-w-[24px] rounded-full border border-solid"
    :class="{
      'border-darkGray shadow-outline ': active,
      'border-lightGray': !active,
    }"
    :style="`background-color: ${swatch.cssColorValue}`"
    :aria-label="`swatch-${swatch.cssColorValue}`"
    @click="$router.push($u(url))"
  />
</template>

<script>
export default {
  name: 'ProductCardSwatch',
  props: {
    url: {
      type: String,
      required: false,
      default: '',
    },
    swatch: {
      type: Object,
      required: false,
      default: () => {},
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
