<template>
  <div
    class="font-medium"
    @mouseover="handleMouseOver"
    @mouseout="handleMouseOut"
  >
    <mp-link
      class="
        font-normal
        uppercase
        transition-colors
        cursor-pointer
        hover:text-brandRed
        tracking-wider
      "
      :to="$u(menuItem.link)"
    >
      {{ menuItem.label }}
    </mp-link>
  </div>
</template>

<script>
export default {
  name: 'DesktopMenuItem',
  props: {
    menuItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeoutId: null,
    }
  },
  computed: {
    hoverDelay: ({ $store }) =>
      parseInt(
        $store.getters['storyblok/getMenuById']('main-menu')?.content?.menuDelay
      ),
  },
  methods: {
    handleMouseOver() {
      const { getSubmenu, hideDesktopSubmenu, menuItem, hoverDelay } = this

      this.timeoutId = setTimeout(() => {
        menuItem.subMenu && menuItem.subMenu.length
          ? getSubmenu(menuItem.subMenu)
          : hideDesktopSubmenu()
      }, hoverDelay)
    },
    handleMouseOut() {
      // Cancel the delay if the user moves their mouse away from the header before the delay is over
      clearTimeout(this.timeoutId)
    },
    getSubmenu(content) {
      this.$store.dispatch('ui/desktop-submenu/showDesktopSubmenu', content)
    },
    hideDesktopSubmenu() {
      this.$store.dispatch('ui/desktop-submenu/hideDesktopSubmenu')
    },
  },
}
</script>
