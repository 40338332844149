export default {
  state () {
    return {
      content: null,
      showSubmenu: false
    }
  },
  mutations: {
    showDesktopSubmenu (state, content) {
      state.content = content
      state.showSubmenu = true
    },
    hideDesktopSubmenu (state) {
      state.showSubmenu = false
      state.content = null
    }
  },
  actions: {
    showDesktopSubmenu ({ dispatch, commit }, payload) {
      dispatch('ui/close-all/closeAll', null, { root: true })
      dispatch('ui/overlay/showOverlay', 10, { root: true })
      commit('showDesktopSubmenu', payload)
    },
    hideDesktopSubmenu ({ dispatch, commit }) {
      dispatch('ui/overlay/hideOverlay', null, { root: true })
      commit('hideDesktopSubmenu')
    }
  }
}
