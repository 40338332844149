<template>
  <span product-badge>
    {{ label }}
  </span>
</template>

<script>
export default {
  name: 'ProductBadge',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
}
</script>
