import {
  ContextError,
  routeToLookupParts,
} from '@made-people/centra-storyblok-nuxt-shared/lib/store/frontend'

export default {
  actions: {
    async nuxtServerInit(
      { dispatch, state, rootState, commit },
      { req, route, redirect, $config }
    ) {
      const lookupParts = routeToLookupParts(
        route,
        $config.defaultLocale,
        $config.defaultMarketSlug
      )

      try {
        await dispatch('frontend/setCurrentSite', lookupParts)
        const {
          footerMenu,
          mainMenu,
          settings,
          countrySpecificInformation,
          marketInformation,
          uiElementTranslations,
          links,
        } = (await this.$backendApi.get('content/site')).data

        if (uiElementTranslations) {
          commit('storyblok/elementTranslations', {
            locale: rootState.frontend.currentLanguageCode,
            entries: uiElementTranslations,
          })
        }

        commit('storyblok/links', links)

        if (footerMenu) {
          commit('storyblok/story', {
            id: footerMenu.id,
            data: footerMenu,
          })
        }
        if (mainMenu) {
          commit('storyblok/story', {
            id: mainMenu.id,
            data: mainMenu,
          })
        }
        if (settings) {
          commit('storyblok/story', {
            id: settings.id,
            data: settings,
          })
        }
        if (countrySpecificInformation) {
          commit('storyblok/story', {
            id: countrySpecificInformation.id,
            data: countrySpecificInformation,
          })
        }
        if (marketInformation) {
          commit('storyblok/story', {
            id: marketInformation.id,
            data: marketInformation,
          })
        }
      } catch (error) {
        if (
          error instanceof ContextError ||
          state.frontend.currentCountryCode
        ) {
          throw error // The error is not about setting the market
        }
        const countryCode = req.countryCode || ''
        const market = rootState.frontend.countries.find(
          (x) => x.country === countryCode.toUpperCase()
        )?.market
        const redirectSlug = market
          ? countryCode.toLowerCase()
          : $config.defaultMarketSlug
        const redirectTo = `/${redirectSlug}${
          req.originalUrl === '/' ? '' : req.originalUrl
        }`
        console.log(`Redirecting to ${redirectTo}`)
        return redirect(`${redirectTo}`)
      }
    },
  },
}
