<template>
  <Dialog
    position="left"
    :open="filterIsOpen"
    content-max-width="max-w-sm lg:max-w-md bg-white"
    px="px-0 lg:px-0"
    py="py-6 lg:py-6"
    @close="$store.dispatch('ui/filter/hideFilterModal')"
  >
    <DialogHeader slot="header" class="flex justify-between px-6 mb-6 lg:px-10">
      <h3 class="text-xl font-semibold tracking-wider">{{ $t('Filter') }}</h3>
      <Button
        class="text-sm tracking-wider underline underline-offset-4"
        font-weight="font-normal"
        px="px-0"
        py="py-0"
        bg="bg-white"
        @click="$store.dispatch('ui/filter/hideFilterModal')"
        >{{ $t('Global_Close') }}</Button
      >
    </DialogHeader>

    <DialogContent
      v-if="!filterSubmenuIsOpen"
      slot="content"
      class="flex flex-col justify-between bg-white h-[calc(100%-48px)] lg:h-[calc(100%-32px)] relative"
    >
      <div class="flex flex-col">
        <FilterOption
          v-for="({ field, values }, index) in finalFilters"
          :key="field"
          :field="field"
          :label="$t('Filter_Label_' + field)"
          :class="{ hidden: !checkIfFilterGotSelectableValues(field) }"
          :options="values"
          :position="index"
        />
      </div>
      <div
        class="flex gap-4 mx-6 lg:mx-10 fixed lg:absolute bottom-0 py-6 max-w-[calc(384px-48px)] lg:max-w-none w-[calc(100%-48px)] lg:w-[calc(100%-80px)] bg-white"
      >
        <Button
          class="flex-grow tracking-widest uppercase"
          bg="bg-transparent"
          border="border border-black border-solid"
          @click="
            $store.dispatch('centra-search/resetFilters', { searchRequestId })
          "
          >{{ $t('Filter_Reset') }}</Button
        >
        <Button
          class="flex-grow tracking-widest uppercase"
          bg="bg-black"
          color="text-white"
          @click="$store.dispatch('ui/filter/hideFilterModal')"
          >{{ $t('Filter_Apply') }}</Button
        >
      </div>
    </DialogContent>

    <DialogContent
      v-else
      slot="content"
      class="flex flex-col bg-white justify-between h-[calc(100%-48px)] lg:min-h-[calc(100%-32px)] relative"
    >
      <div v-if="selectedFilter" class="w-full">
        <FilterSubmenuHeader
          @click="$store.dispatch('ui/filter/closeFilterSubmenu')"
          >{{ $t('Filter_Label_' + selectedFilter.field) }}</FilterSubmenuHeader
        >
        <button
          v-for="value in selectedFilter.values"
          :key="value.value"
          :class="{
            'opacity-20 select-none cursor-default': !value.canBeSelected,
            'hover:bg-lightGray': value.canBeSelected,
          }"
          class="flex items-center justify-between w-full py-4 pl-16 pr-8 text-left"
          @click="
            value.canBeSelected && toggleFilter(filterSubmenuId, value.value)
          "
        >
          {{ getFilterLabel(filterSubmenuId, value.value) }}
          <CheckIcon v-if="value.selected" class="w-4" />
        </button>
      </div>
      <div
        class="flex gap-4 mx-6 lg:mx-10 fixed lg:absolute max-w-[calc(384px-48px)] lg:max-w-none bottom-0 py-6 w-[calc(100%-48px)] lg:w-[calc(100%-80px)] bg-white"
      >
        <Button
          class="flex-grow tracking-widest uppercase"
          bg="bg-transparent"
          border="border border-black border-solid"
          @click="
            $store.dispatch('centra-search/resetFilters', { searchRequestId })
          "
          >{{ $t('Filter_Reset') }}</Button
        >
        <Button
          class="flex-grow tracking-widest uppercase"
          bg="bg-black"
          color="text-white"
          @click="$store.dispatch('ui/filter/hideFilterModal')"
          >{{ $t('Filter_Apply') }}</Button
        >
      </div>
    </DialogContent>
  </Dialog>
</template>

<script>
import FilterOption from './FilterOption.vue'
import FilterSubmenuHeader from './FilterSubmenuHeader.vue'
import CheckIcon from '~/assets/icons/check-usp.svg'

export default {
  name: 'FilterModal',
  components: { FilterOption, FilterSubmenuHeader, CheckIcon },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    searchRequestId: {
      type: String,
      required: true,
    },
  },
  computed: {
    filterIsOpen: ({ $store }) => $store.state.ui.filter.filterModalOpen,
    filterSubmenuIsOpen: ({ $store }) =>
      $store.state.ui.filter.filterSubmenuOpen,
    filterSubmenuId: ({ $store }) => $store.state.ui.filter.filterSubmenuId,
    selectedFilter: ({ finalFilters, filterSubmenuId }) =>
      finalFilters.find((ff) => ff.field === filterSubmenuId),
    hasSelectedMpGender: ({ $store, searchRequestId }) =>
      !!$store.state['centra-search']?.searchResponses?.[
        searchRequestId
      ]?.__initialQuery?.__translateFieldsFrom?.find(
        (tff) => tff.fieldName === 'mp_gender'
      ),
    finalFilters: ({ filters, hasSelectedMpGender }) =>
      filters.filter((filter) => {
        // If mp_gender has been selected in SB we can't filter on it
        if (hasSelectedMpGender && filter.field === 'mp_gender') {
          return false
        }
        return true
      }),
  },
  methods: {
    getFilterLabel(field, value) {
      const filter = this.filters.find((f) => f.field === field)
      switch (filter.field) {
        case 'categories':
          return (
            filter.values
              .find((x) => x.value === value)
              ?.data?.name.join(' / ') || `Empty Value: ${value}`
          )
        default:
          return (
            filter.values.find((x) => x.value === value)?.value ||
            `Empty Value: ${value}`
          )
      }
    },
    checkIfFilterGotSelectableValues(field) {
      const filter = this.filters.find((f) => f.field === field)
      return (
        filter?.values?.filter((item) => item.canBeSelected === true).length > 0
      )
    },
    toggleFilter(field, value) {
      const filter = this.filters.find((f) => f.field === field)
      return this.$store.dispatch('centra-search/toggleFilter', {
        searchRequestId: this.searchRequestId,
        filter: {
          field: filter.field,
          value,
        },
      })
    },
  },
}
</script>
