<template>
  <NavItem title="Open menu" class="lg:hidden" @click="showMobileSidebar">
    <HamburgerIcon class="w-4" />
    <span class="hidden ml-6 border-b border-black 2xl:block">
      {{ $t('Menu_ShopAll') }}
    </span>
  </NavItem>
</template>

<script>
import HamburgerIcon from '~/static/images/burger.svg'
import NavItem from '~/components/header/NavItem.vue'

export default {
  name: 'ToggleMenu',
  components: {
    HamburgerIcon,
    NavItem
  },
  methods: {
    showMobileSidebar() {
      this.$store.dispatch('ui/mobile-sidebar/showMobileSidebar')
    },
  },
}
</script>
