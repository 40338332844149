<template>
  <div class="overlay" :style="'z-index:' + zIndex" @click="closeAll" />
</template>

<script>
export default {
  name: 'Overlay',
  props: {
    zIndex: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    closeAll() {
      this.$store.dispatch('ui/close-all/closeAll')
    },
  },
}
</script>

<style lang="scss" scoped>
.overlay {
  background: rgba(#000000, 0.4);
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  //opacity: 1;
}
</style>
