<template>
  <div v-if="selectedFilter" class="w-full overflow-y-scroll">
    <FilterSubmenuHeader
      @click="$store.dispatch('ui/filter/closeFilterSubmenu')"
      >{{ $t('Filter_Label_' + selectedFilter.id) }}</FilterSubmenuHeader
    >
    <button
      v-for="value in selectedFilter.values"
      :key="value.id"
      class="'hover:bg-lightGray flex items-center justify-between w-full py-4 pl-16 pr-8 text-left"
      @click="toggleFilter(value.id)"
    >
      {{ value.label }}
      <CheckIcon v-if="value.selected" class="w-4" />
    </button>
  </div>
</template>

<script>
import FilterSubmenuHeader from '../filter-modal/FilterSubmenuHeader.vue'
import CheckIcon from '~/assets/icons/check-usp.svg'

export default {
  name: 'SubFilterMenu',
  components: { FilterSubmenuHeader, CheckIcon },
  props: {
    searchRequestId: {
      type: String,
      required: true,
    },
    selectedFilter: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filterSubmenuId: ({ $store }) => $store.state.ui.filter.filterSubmenuId,
  },
  methods: {
    toggleFilter(value) {
      return this.$store.dispatch('elevate/toggleFilter', {
        searchRequestId: this.searchRequestId,
        filter: {
          id: this.filterSubmenuId,
          value,
        },
      })
    },
  },
}
</script>
