<template>
  <div v-if="searchResponse" class="flex flex-col col-span-2 gap-4 lg:gap-8">
    <div class="flex justify-between">
      <h1 class="lg:text-2xl text-xl font-semibold">
        {{ $t('Popular_Products') }}
      </h1>
      <button
        v-if="searchQuery.length"
        class="underline text-base lg:text-xl font-normal"
        @click="$emit('search', searchQuery)"
      >
        {{ $t('Show_All') }}
      </button>
    </div>
    <ProductList
      :search-response="searchResponse"
      :search-request-id="searchRequestId"
      :hide-category-slider="true"
      :hide-filter="true"
      :hide-display-product-count="true"
      :hide-load-more="true"
    />
  </div>
</template>

<script>
import ProductList from '../../product-list/ProductList.vue'

export default {
  name: 'ProductSuggestions',
  components: { ProductList },
  props: {
    searchQuery: {
      type: String,
      default: '',
    },
  },

  computed: {
    mappedProducts: ({ $store }) =>
      $store.state.elevate.autoCompleteResponse?.productSuggestions,
    searchResponse: ({ mappedProducts, searchRequestId }) => {
      if (!mappedProducts.length) return undefined
      return {
        mappedProducts,
        productCount: mappedProducts.length,
        searchRequestId,
      }
    },
    searchRequestId: () => 'autocomplete-recommendations',
  },
}
</script>
