<template>
  <div class="sidebar-menu scrollbar-hide z-[110] fixed">
    <div class="sticky top-0 z-50 bg-white pt-6 pb-6">
      <div class="close" @click="hideMobileSidebar">
        <CloseIcon />
      </div>
      <div class="flex justify-between w-full gap-2 px-6 mt-6">
        <button
          v-for="(item, itemIndex) in visibleMainMenuItems"
          :key="'item' + itemIndex"
          class="pb-1"
          :class="
            activeMenu === item.label && 'border-b border-solid border-black'
          "
          @click="menuItemClicked(item)"
        >
          <span v-if="hasSubMenu(item)">{{ item.label }}</span>
          <mp-link v-else :to="$u(item.link)" class="link">
            <span>{{ item.label }}</span>
          </mp-link>
        </button>
      </div>
    </div>
    <div class="px-6 pb-6 pt-0">
      <div
        v-for="(item, index) in visibleMainMenuItems"
        :key="'navigation-' + index"
        class="flex flex-col gap-2"
        :class="[{ hidden: activeMenu !== item.label }]"
      >
        <MobileSidebarCard
          v-for="(
            {
              label,
              image,
              textColor,
              backgroundColor,
              backgroundImage,
              tagLabel,
              link,
            },
            menuItemIndex
          ) in item.subMenu"
          :key="'menu-item-index' + menuItemIndex"
          :label="label"
          :link="link"
          :image="image"
          :tag="tagLabel"
          :background-image="backgroundImage"
          :style="[
            textColor !== '' ? { color: textColor } : '',
            backgroundColor !== '' ? { backgroundColor: backgroundColor } : '',
          ]"
        />
      </div>
    </div>
    <div
      v-if="secondaryMenu.length"
      class="flex flex-col gap-6 px-6 py-8 pb-40 bg-lightGray"
    >
      <mp-link
        v-for="(menuItem, menuItemIndex) in secondaryMenu"
        :key="'menu-item-' + menuItemIndex"
        class="uppercase"
        :to="$u(linkPath(menuItem))"
        >{{ menuItem.label }}</mp-link
      >

      <!-- //Make into new component? CountrySelector input/sidebar -->
      <div
        class="
          flex
          items-center
          justify-between
          w-full
          gap-2
          px-5
          py-3
          text-sm
          transition-opacity
          border border-black border-solid
          cursor-pointer
          hover:opacity-60
        "
        @click="showCountrySelector"
      >
        <span>Country: <CountryName class="text-sm uppercase" /></span>
        <ChevronIcon class="h-[7px]" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CloseIcon from '~/static/images/cross.svg'
import MobileSidebarCard from '~/components/header/MobileSidebarCard.vue'
import CountryName from '~/components/country-name/CountryName.vue'
import ChevronIcon from '~/assets/icons/chevron-down-small.svg'

export default {
  name: 'MobileSidebar',
  components: {
    CloseIcon,
    MobileSidebarCard,
    CountryName,
    ChevronIcon,
  },
  props: {
    mainMenu: {
      type: Array,
      required: false,
      default: () => [],
    },
    secondaryMenu: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      countryCode: 'frontend/currentCountryCode',
    }),
    activeMenuState: ({ $store }) =>
      $store.state.ui['mobile-sidebar'].activeMenu,
    /**
     * Depending on the visibility setting (Mobile, Desktop, All, None),
     * only loop through visible items
     */
    visibleMainMenuItems: ({ mainMenu }) =>
      mainMenu?.filter((it) =>
        it.countries?.length ? it.countries.includes(this.countryCode) : true
      ),
    firstMenuOption: ({ visibleMainMenuItems }) => visibleMainMenuItems[0],
    activeMenu: ({ activeMenuState, firstMenuOption }) =>
      activeMenuState || firstMenuOption?.label,
  },
  methods: {
    linkPath: ({ link, openPage }) => (link.openPage ? openPage : link),
    hideMobileSidebar() {
      this.$store.dispatch('ui/mobile-sidebar/hideMobileSidebar')
    },
    hasSubMenu: (menuItem) => menuItem?.subMenu?.length > 0,
    showCountrySelector() {
      this.$store.dispatch('ui/country-selector/showCountrySelector')
    },
    menuItemClicked(menuItem) {
      if (this.hasSubMenu(menuItem)) {
        this.$store.dispatch('ui/mobile-sidebar/setActiveMenu', menuItem.label)
      } else {
        this.hideMobileSidebar()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebar-menu {
  width: calc(100% - 47px);
  height: calc(var(--vh, 1vh) * 100);
  max-width: 400px;
  background-color: #ffffff;
  color: #000000;
  --side-padding: 24px;
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior: contain;
  // HIDE SCROLLBAR
  .close {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-left: 24px;
  }
  .featured {
    margin-top: 16px;
    padding: 0 24px;
    .selection {
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      .label {
        width: 100%;
        cursor: pointer;
        &.selected {
          box-shadow: 0 4px 0px -2px #000000;
        }
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
  .main-menu-wrapper {
    padding: 16px 24px 32px;
    div {
      &.disabled {
        display: none;
      }
      .item {
        height: 60px;
        padding: 0 16px;
        background-color: #f1f1f1f1;
        margin-bottom: 16px;
        position: relative;
        &:last-child {
          margin-bottom: 0;
        }
        .link {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 1;
          .label {
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            left: 16px;
          }
        }
        .image {
          position: absolute;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }
        .image {
          width: 72px;
          max-height: 44px;
          right: 25px;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
