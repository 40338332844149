<template>
  <section data-product-list class="flex flex-col gap-8 lg:gap-10">
    <client-only>
      <template v-if="!hideFilter">
        <ElevateFilterModal
          v-if="searchResponse.isElevate"
          :filters="searchResponse.preparedFilters"
          :search-request-id="searchRequestId" />
        <FilterModal
          v-else
          :filters="searchResponse.preparedFilters"
          :search-request-id="searchRequestId"
      /></template>
    </client-only>
    <!-- IF Filter -->
    <Filterbar
      v-if="!hideFilter"
      :viewed-products="searchResponse.mappedProducts.length"
      :total-products="searchResponse.productCount"
      :search-request-id="searchRequestId"
      :filters="searchResponse.preparedFilters"
    />
    <!-- Productlist -->
    <section
      class="grid min-h-screen grid-cols-12 mb-6 lg:mb-10"
      :class="[
        gapY,
        gapX,
        gapXDesktop,
        gapYDesktop,
        {
          '-ml-6 w-[calc(100%+48px)] lg:ml-0 lg:w-auto':
            !mobileGutter && !contextWishlist,
          'gap-y-8': contextWishlist,
        },
      ]"
    >
      <ProductCard
        v-for="(product, index) in searchResponse.mappedProducts"
        :key="`product-card-${index}-${product.product}`"
        :position="index + 1"
        class="col-span-6 lg:col-span-3"
        :gtm-list-name="searchRequestId"
        :product="product"
        :context-wishlist="contextWishlist"
        :show-trash-icon="false"
        :mobile-rounded-corners="false"
        :desktop-rounded-corners="false"
        :show-cart-icon="!hideQuickshop"
        :show-heart-icon="!hideAddToWishlist"
        :search-request-id="searchRequestId"
        :animations="animations"
      />
      <!-- View More Product Components -->
    </section>
    <ViewMore
      v-if="!hideLoadMore && searchResponse.hasMoreProducts"
      :viewed-products="searchResponse.mappedProducts.length"
      :total-products="searchResponse.productCount"
      :use-observer="loadInMoreOnObserve"
      :search-request-id="searchRequestId"
      :limit="limitProducts"
      @view-more="loadMore"
    />
    <!-- IF Pagination -->
  </section>
</template>
<script>
import ElevateFilterModal from '../elevate-filter-modal/ElevateFilterModal.vue'
import Filterbar from './Filterbar.vue'
import ViewMore from './ViewMore.vue'
import FilterModal from '~/components/filter-modal/FilterModal.vue'
import ProductCard from '~/components/product-card/ProductCard.vue'

export default {
  name: 'ProductList',
  components: {
    ProductCard,
    ViewMore,
    Filterbar,
    FilterModal,
    ElevateFilterModal,
  },
  props: {
    hideQuickshop: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideAddToWishlist: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideCategorySlider: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideLoadMore: {
      type: Boolean,
      required: false,
      default: false,
    },
    loadMoreAlternative: {
      type: String,
      required: false,
      default: 'observer',
    },
    hideDisplayProductCount: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchResponse: {
      type: Object,
      required: true,
    },
    mobileGutter: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchRequestId: {
      type: String,
      required: true,
    },
    limitProducts: {
      type: String,
      required: false,
      default: '12',
    },
    animations: {
      type: Boolean,
      required: false,
      default: false,
    },
    gapY: {
      type: String,
      required: false,
      default: 'gap-y-2',
    },
    gapYDesktop: {
      type: String,
      required: false,
      default: 'lg:gap-y-10',
    },
    gapX: {
      type: String,
      required: false,
      default: 'gap-x-2',
    },
    contextWishlist: {
      type: Boolean,
      required: false,
      default: false,
    },
    gapXDesktop: {
      type: String,
      required: false,
      default: 'lg:gap-x-6',
    },
  },
  data() {
    return {
      observer: null,
      currentPage: parseInt(this.$route.query.page) ?? 1,
    }
  },
  computed: {
    loadInMoreOnObserve: ({ loadMoreAlternative }) =>
      loadMoreAlternative === 'observer',
  },
  mounted() {
    if (
      this.searchResponse?.mappedProducts?.length === 0 &&
      this.currentPage > 1 &&
      this.searchResponse.isElevate
    ) {
      this.$emit('reset-page')
    }
  },
  methods: {
    loadMore() {
      if (!this.searchResponse.hasMoreProducts) {
        return
      }
      const action = this.searchResponse.isElevate
        ? 'elevate/loadMore'
        : 'centra-search/loadMore'

      return this.$store.dispatch(action, {
        searchRequestId: this.searchRequestId,
      })
    },
  },
}
</script>
