<template>
  <SlideInTransitionTop>
    <div v-if="newsletterPopupEnabled && newsletterPopupShown && animated" class="fixed bottom-0 right-0 flex flex-col h-[40rem] max-h-full md:flex-row z-[91] w-full md:h-[32rem] md:w-[42.5rem] md:max-h-[60vh] bg-white shadow-2xl">
      <button
        type="button"
        class="p-1 absolute right-6 top-7 md:right-10 md:top-10"
        :aria-label="$t('Newsletter_Popup_Close_Button_Aria_Label')"
        @click="close"
        >
        <CloseIcon
          class="w-4 h-4 cursor-pointer hover:opacity-60  md:fill-black"
          :class="iconColor === 'white' ? 'fill-white' : 'fill-black'"
        />
      </button>
      <div class="h-1/3 md:h-full md:w-1/2">
        <Vue2Image v-if="image.id" class="h-full w-full object-cover" :src="image.filename" :alt="image.name"/>
      </div>
      <div class="flex flex-col grow gap-4 md:w-1/2  p-10 md:pt-[5.375rem]">
        <h4
          v-if="headline"
          class="font-semibold tracking-wider text-xl leading-6"
          >
          {{ headline }}
        </h4>
        <div v-if="text" class="mb-4 text-sm md:text-base md:leading-7">{{ text }}</div>
        <form class="flex flex-col justify-between h-full" :class="!phoneNumberEnabled && 'mb-12 md:mb-0'">
          <div class="relative flex flex-col gap-6">
            <input
              v-model="email"
              class="
              email
              w-full
              h-10
              px-0
              pb-2
              text-base
              leading-tight
              tracking-wide
              text-black
              bg-opacity-0
              border-t-0 border-b-2 border-l-0 border-r-0
              rounded-none
              appearance-none
              border-b-darkGray
              focus:border-b-black focus:ring-0
              focus-visible:ring-0 focus-visible:outline-0
              "
              type="email"
              :placeholder="$t('Newsletter_Popup_Placeholder_Email')"
            />
            <span v-if="!email" class="absolute left-12 top-0 text-systemRed">*</span>
          <input
            v-if="phoneNumberEnabled"
            v-model="phone"
            class="
            w-full
            h-10
            px-0
            pb-3
            text-base
            leading-tight
            text-black
            bg-opacity-0
            border-t-0 border-b-2 border-l-0 border-r-0
            rounded-none
            appearance-none
            border-b-darkGray
            focus:border-b-black focus:ring-0
            focus-visible:ring-0 focus-visible:outline-0
            "
            type="tel"
            :placeholder="$t('Newsletter_Popup_Placeholder_Phone')"
          />
          </div>
          <Button
            class="md:mt-auto uppercase"
            bg="bg-black"
            color="text-white"
            px="px-6"
            py="py-3.5"
            font-weight="font-normal"
            :class="disableSubscribe && 'select-none opacity-50 cursor-default'"
            :aria-label="$t('Newsletter_Popup_Subscribe_Button_Aria_Label')"
            :disabled="disableSubscribe"
            @click="handleSubscribe"
            >
            {{ $t('Newsletter_Popup_Subscribe_Button')}}
          </Button>
        </form>
      </div>
    </div>
  </SlideInTransitionTop>
</template>

<script>
import Vue2Image from '@made-people/vue2-image'
import { mapState, mapGetters } from 'vuex'
import CloseIcon from '~/static/images/cross.svg'
import newsletter  from '~/mixins/newsletter'
import SlideInTransitionTop from '~/components/transitions/SlideInTransitionTop'


export default {
  name: 'NewsletterPopup',
  components: {
    Vue2Image,
    CloseIcon,
    SlideInTransitionTop
  },
  mixins: [newsletter],
  props: {
    headline: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    klaviyoList: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      default: () => ({}),
    },
    phoneNumberEnabled: {
      type: Boolean,
      default: false,
    },
    newsletterPopupEnabled: {
      type: Boolean,
      default: false,
    },
    iconColor:{
      type: String,
      default: 'white',
    }
  },
  data() {
    return {
      email: null,
      phone: null,
      loading: false,
      animated: false
    }
  },
  computed: {
    ...mapState({
      newsletterPopupShown: (state) =>
        state.ui['newsletter-popup'].newsletterPopupShown
    }),
    ...mapGetters({
      settings: 'storyblok/settings',
    }),
    disableSubscribe() {
      return this.loading || !this.verifiedMail || (this.phone && !this.verifiedPhone)
    }
  },
  mounted() {
    this.$store.dispatch('ui/newsletter-popup/showNewsletterPopup', 90, { root: true })
    setTimeout(() => {
      this.animated = true
    }, this.settings.newsletterPopupDelay * 1000)
  },
  methods: {
    close() {
      this.$store.dispatch('ui/newsletter-popup/hideNewsletterPopup', null, { root: true })
      this.$cookies.set('newsletter-popup', 1, {
        path: '/',
        maxAge: 2592000
      })
    },
    handleSubscribe() {
      const payload = { email: this.email, phone: this.phone, list: this.klaviyoList }
      this.loading = true

      this.subscribe(payload)
      .then(this.close)
      .catch((error) => {
        console.log('Error when subscribing newsletter', error)
      })
      .finally(() => {
        this.loading = false;
      })
    }
  },
}
</script>
