import { elevateTouchpoint } from '../utils/elevate'
export default {
  name: 'ElevateMixin',
  methods: {
    async getElevateTicket(productKey) {
      const data = {
        productGroup: {
          include: true,
        },
      }
      const params = {
        productKey,
        touchpoint: elevateTouchpoint(),
      }
      try {
        const elevateClient = await this.$elevateApi()
        const productResponse = await elevateClient.request({
          url: 'storefront/v3/queries/product-page',
          method: 'POST',
          data,
          params,
        })

        const ticket =
          productResponse?.data?.productGroup?.products?.[0]?.ticket
        return ticket
      } catch (e) {
        console.error(
          `Elevate error: An error occured when getting ticket info for product with key: ${productKey}.`,
          e
        )
        return undefined
      }
    },
    async clickNotification(ticketInput, productId) {
      const ticket = ticketInput ?? (await this.getElevateTicket(productId))
      if (ticket) {
        const data = {
          ticket,
        }
        try {
          const elevateClient = await this.$elevateApi()
          await elevateClient.request({
            url: 'storefront/v3/notifications/click',
            method: 'POST',
            data,
          })
        } catch (e) {
          console.error(
            `Elevate error: An error occured when adding click notifaction for ticket: ${ticket}.`,
            e
          )
        }
      }
    },
    async addToCartNotification(ticketInput, productId) {
      const ticket = ticketInput ?? (await this.getElevateTicket(productId))

      if (ticket) {
        const data = {
          ticket,
        }
        try {
          const elevateClient = await this.$elevateApi()
          await elevateClient.request({
            url: 'storefront/v3/notifications/add-to-cart',
            method: 'POST',
            data,
          })
        } catch (e) {
          console.error(
            `Elevate error: An error occured when adding add-to-cart notifaction for ticket: ${ticket}.`,
            e
          )
        }
      }
    },
    async addFavoriteNotification(productKey) {
      const data = {
        productKey,
      }
      try {
        const elevateClient = await this.$elevateApi()
        await elevateClient.request({
          url: 'storefront/v3/notifications/add-favorite',
          method: 'POST',
          data,
        })
      } catch (e) {
        console.error(
          `Elevate error: An error occured when adding add favorite notifaction to product with product key: ${productKey}.`,
          e
        )
      }
    },
    async removeFavoriteNotification(productKey) {
      const data = {
        productKey,
      }
      try {
        const elevateClient = await this.$elevateApi()
        await elevateClient.request({
          url: 'storefront/v3/notifications/remove-favorite',
          method: 'POST',
          data,
        })
      } catch (e) {
        console.error(
          `Elevate error: An error occured when adding remove favorite notifaction to product with product key: ${productKey}.`,
          e
        )
      }
    },
  },
}
