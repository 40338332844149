<template>
  <div
    class="flex items-center gap-3 overflow-x-scroll scrollbar-hide lg:gap-4"
  >
    <ProductCardSwatch
      v-for="(swatch, swatchIndex) in colorSwatchesSorted.slice(0, 3)"
      :key="'swatch-' + swatchIndex"
      :url="swatch.url"
      :active="swatchIndex === 0"
      :swatch="swatch.swatch"
    />
    <span v-if="colorSwatches.length > 3" class="ml-1 text-xs">
      +{{ colorSwatches.length - 3 }}
    </span>
  </div>
</template>
<script>
import ProductCardSwatch from './ProductCardSwatch.vue'

export default {
  name: 'ProductCardSwatches',
  components: { ProductCardSwatch },
  props: {
    colorSwatches: {
      type: Array,
      required: true,
    },
    productName: {
      type: String,
      required: true,
    },
  },
  computed: {
    colorSwatchesSorted: ({ colorSwatches, productName }) => {
      const activeProducts = colorSwatches.filter(
        ({ name }) => name === productName
      )
      return [...new Set([...activeProducts, ...colorSwatches])]
    },
  },
}
</script>
