<template>
  <img
    :src="'/flags/market/' + countryCodeLower + '.svg'"
    :alt="countryName"
    loading="lazy"
    height="10"
    width="16"
    class="w-4"
  />
</template>

<script>
import ISOCountries from '@made-people/centra-storyblok-nuxt-shared/lib/iso-countries'
import { mapGetters } from 'vuex'

export default {
  name: 'CurrentCountryFlag',
  computed: {
    ...mapGetters({
      countryCode: 'frontend/currentCountryCode',
    }),
    countryName: ({ countryCode }) => ISOCountries[countryCode.toUpperCase()],
    countryCodeLower: ({ countryCode }) => countryCode.toLowerCase(),
  },
}
</script>
