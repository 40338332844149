<template>
  <button
    class="
      flex
      items-center
      justify-center
      appearance-none
      cursor-pointer
      lg:hover:opacity-50
    "
    :class="[bg, color, px, py, textSize, fontWeight, border]"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <slot />
  </button>
</template>
<script>
export default {
  name: 'Button',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    bg: {
      type: String,
      default: 'bg-lightGray',
    },
    color: {
      type: String,
      default: 'text-black',
    },
    px: {
      type: String,
      default: 'px-4',
    },
    py: {
      type: String,
      default: 'py-3',
    },
    textSize: {
      type: String,
      default: 'text-sm',
    },
    fontWeight: {
      type: String,
      default: 'font-semibold',
    },
    border: {
      type: String,
      default: 'border-none',
    },
  },
}
</script>
