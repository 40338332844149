<template>
  <button
    class="
      flex
      items-center
      justify-center
      w-8
      h-10
      transition-opacity
      cursor-pointer
      hover:opacity-50
    "
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>
<script>
export default {
  name: 'NavItem',
}
</script>
