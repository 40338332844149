var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex gap-4 pb-5 border-b border-solid border-lightGray",class:{ 'animate-pulse': _vm.loading }},[_c('mp-link',{staticClass:"p-2 bg-lightGray w-[70px] h-[83px] flex justify-center items-center",attrs:{"to":_vm.$u(_vm.productUrl)}},[_c('Vue2Image',{attrs:{"src":_vm.productImage,"alt":_vm.productName,"background":"f0f0f0","widths":[50, 100],"quality":60}})],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col justify-between flex-1"},[_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"flex flex-col gap-1"},[_c('mp-link',{staticClass:"text-base",attrs:{"to":_vm.$u(_vm.productUrl)}},[_vm._v("\n          "+_vm._s(_vm.productName)+"\n        ")]),_vm._v(" "),(!_vm.isBundle)?_c('span',{staticClass:"text-sm uppercase"},[_vm._v(_vm._s(_vm.productMetaData))]):_vm._e()],1),_vm._v(" "),_c('button',{staticClass:"flex items-start",class:{ 'opacity-10 select-none': _vm.loading },on:{"click":_vm.removeItem}},[_c('CrossIcon',{staticClass:"w-2 h-2"})],1)]),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-between text-sm"},[_c('div',{staticClass:"flex gap-4"},[_c('button',{staticClass:"minus group disabled:opacity-10",class:{ 'opacity-10 select-none': _vm.loading },attrs:{"aria-label":"Decrease quantity"},on:{"click":_vm.decreaseQty}},[_c('MinusIcon',{staticClass:"w-3 h-3 group-hover:fill-brandRed"})],1),_vm._v(" "),_c('span',{staticClass:"text-sm font-semibold"},[_vm._v(_vm._s(_vm.item.quantity))]),_vm._v(" "),_c('button',{staticClass:"plus group disabled:opacity-10",class:{
            'opacity-10 select-none': _vm.loading || !_vm.canAddMore,
          },attrs:{"disabled":!_vm.canAddMore || !_vm.moreItemsAvailable || _vm.isBundle,"aria-label":"Increase quantity"},on:{"click":_vm.increaseQty}},[_c('PlusIcon',{staticClass:"w-3 h-3 group-hover:fill-brandRed"})],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-end"},[(
            _vm.item.priceEachAsNumber !== _vm.item.priceEachBeforeDiscountAsNumber
          )?_c('div',{staticClass:"line-through"},[_c('span',[_vm._v(_vm._s(_vm.item.priceEachBeforeDiscount))])]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"text-sm font-semibold",class:{
            'text-brandRed':
              _vm.item.priceEachAsNumber !== _vm.item.priceEachBeforeDiscountAsNumber,
          }},[_vm._v(_vm._s(_vm.item.priceEach))])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }