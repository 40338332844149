<template>
  <Dialog
    position="left"
    :open="filterIsOpen"
    content-max-width="max-w-sm lg:max-w-md bg-white"
    px="px-0 lg:px-0"
    py="pt-6 lg:pt-6"
    @close="$store.dispatch('ui/filter/hideFilterModal')"
  >
    <DialogHeader slot="header" class="flex justify-between px-6 mb-6 lg:px-10">
      <h3 class="text-xl font-semibold tracking-wider">{{ $t('Filter') }}</h3>
      <Button
        class="text-sm tracking-wider underline underline-offset-4"
        font-weight="font-normal"
        px="px-0"
        py="py-0"
        bg="bg-white"
        @click="$store.dispatch('ui/filter/hideFilterModal')"
        >{{ $t('Global_Close') }}</Button
      >
    </DialogHeader>
    <DialogContent
      slot="content"
      class="
        flex flex-col
        bg-white
        justify-between
        h-[calc(100%-48px)]
        relative
      "
    >
      <MainFilterMenu
        v-if="!filterSubmenuIsOpen"
        :filters="filters"
        :search-request-id="searchRequestId"
      />
      <SubFilterMenu
        v-else
        :selected-filter="selectedFilter"
        :search-request-id="searchRequestId"
      />
      <div class="flex gap-4 mx-6 py-6 bg-white">
        <Button
          class="flex-grow tracking-widest uppercase"
          bg="bg-transparent"
          border="border border-black border-solid"
          @click="
            $store.dispatch('elevate/resetAllFilters', { searchRequestId })
          "
          >{{ $t('Filter_Reset') }}</Button
        >
        <Button
          class="flex-grow tracking-widest uppercase"
          bg="bg-black"
          color="text-white"
          @click="$store.dispatch('ui/filter/hideFilterModal')"
          >{{ $t('Filter_Apply') }}</Button
        >
      </div>
    </DialogContent>
  </Dialog>
</template>

<script>
import Button from '../button/Button.vue'
import DialogContent from '../dialog/DialogContent.vue'
import MainFilterMenu from './MainFilterMenu.vue'
import SubFilterMenu from './SubFilterMenu.vue'

export default {
  name: 'ElevateFilterModal',
  components: {
    MainFilterMenu,
    SubFilterMenu,
    DialogContent,
    Button,
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    searchRequestId: {
      type: String,
      required: true,
    },
  },
  computed: {
    filterIsOpen: ({ $store }) => $store.state.ui.filter.filterModalOpen,
    filterSubmenuIsOpen: ({ $store }) =>
      $store.state.ui.filter.filterSubmenuOpen,
    filterSubmenuId: ({ $store }) => $store.state.ui.filter.filterSubmenuId,
    selectedFilter: ({ filters, filterSubmenuId }) =>
      filters.find((filter) => filter.id === filterSubmenuId),
  },
}
</script>
