export default {
  state () {
    return {
      accordion: ''
    }
  },
  mutations: {
    setOpenAccordion (state, { accordion }) {
      state.accordion = accordion
    },
    unsetOpenAccordion (state) {
      state.accordion = ''
    }
  },
  actions: {
    setOpenAccordion ({ commit }, payload) {
      commit('setOpenAccordion', payload)
    },
    unsetOpenAccordion ({ commit }) {
      commit('unsetOpenAccordion')
    }
  }
}
