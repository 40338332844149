<template>
  <div
    v-if="breadcrumbs.length > 0"
    class="flex"
    :class="{
      'justify-center pb-4 lg:pb-6': onPDP,
      'my-4 lg:mt-6 px-6 lg:mb-6 lg:px-10': !onPDP,
    }"
  >
    <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="flex">
      <li
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="index"
        itemprop="itemListElement"
        itemscope
        itemtype="https://schema.org/ListItem"
      >
        <mp-link
          :to="$u(breadcrumb.slug)"
          itemprop="item"
          class="
            text-darkGray
            whitespace-nowrap
            max-w-[200px]
            block
            overflow-scroll
            scrollbar-hide
            lg:max-w-none
          "
        >
          <span itemprop="name">{{
            `${index !== 0 ? '&nbsp;/' : ''} ${breadcrumb.label}`
          }}</span>
        </mp-link>
        <meta itemprop="position" :content="index + 1" />
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    onPDP: ({ $store }) =>
      $store.state.routes.currentRoute?.component?.component === 'product',
  },
}
</script>
