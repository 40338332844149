var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-0 left-0 z-30 w-full transition-colors",class:[
    _vm.headerTextColor,
    _vm.headerBG,
    _vm.headerDisplay,
    {
      '-translate-y-full duration-500 transition-transform':
        _vm.hideScroll && !_vm.searchOpen,
      'translate-y-none duration-500  transition-transform': !_vm.hideScroll,
    },
  ],style:({
    position: _vm.position,
  }),attrs:{"header":""}},[_c('div',{staticClass:"flex items-center justify-between px-6 py-3 lg:py-4 lg:px-10 main-header"},[_c('div',{staticClass:"flex items-center flex-1 gap-1"},[_c('ToggleMenu',{staticClass:"menu",class:[_vm.headerFillColor]}),_vm._v(" "),(!_vm.alwaysShowSearchbar)?_c('ToggleSearch',{staticClass:"flex lg:hidden",class:[_vm.headerFillColor],attrs:{"inputid":"mobileSiteSearch"}}):_vm._e(),_vm._v(" "),_c('DesktopMenu',{attrs:{"menu":_vm.desktopMenu}})],1),_vm._v(" "),_c('mp-link',{staticClass:"z-50 flex justify-center flex-1 cursor-pointer group",attrs:{"to":_vm.$u(''),"title":"EQPE"}},[_c('LogoIcon',{staticClass:"h-6 transition-colors lg:group-hover:fill-brandRed",class:[_vm.headerFillColor]})],1),_vm._v(" "),_c('div',{staticClass:"z-50 flex items-center justify-end flex-1 gap-4 lg:gap-6"},[(!_vm.alwaysShowSearchbar)?_c('ToggleSearch',{staticClass:"hidden lg:flex",class:[_vm.headerFillColor],attrs:{"inputid":"desktopSiteSearch"}}):_vm._e(),_vm._v(" "),_c('ToggleWishlist',{staticClass:"wishlist",class:[_vm.headerFillColor],attrs:{"fill":_vm.headerFillColor}}),_vm._v(" "),_c('ToggleCart',{attrs:{"cart-qty":_vm.cartQty,"device":"mobile","fill":_vm.headerFillColor}})],1)],1),_vm._v(" "),(_vm.searchOpen || _vm.alwaysShowSearchbar)?_c('SearchInput',{attrs:{"hide-close":_vm.alwaysShowSearchbar,"inputid":"mobileSiteSearch"}}):_vm._e(),_vm._v(" "),(
      _vm.desktopSubmenu &&
      _vm.desktopSubmenu.content &&
      _vm.desktopSubmenu.content.length
    )?_c('FadeInTransition',[(_vm.desktopSubmenu.showSubmenu)?_c('DesktopSubMenu',{attrs:{"content":_vm.desktopSubmenu.content}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }